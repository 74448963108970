import { useRef, useState } from 'react';

import { IconDotsVertical } from '@tabler/icons-react';

import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';

import { PayrollPeriodSummary } from '@octopus/api';

import { useSendPayslips } from '../../../modules/components/payslips/SendPayslips';
import { useDownloadPayslips } from '../../../modules/pdf/useDownloadPayslips';
import { hasSentAllPayslips } from '../../../utils/statusIndexUtils';

export function PeriodActionsMenu(props: { row: PayrollPeriodSummary }) {
  const { row } = props;
  const { SendPayslipsComponent, sendPayslips, sendPayslipsProps } =
    useSendPayslips({
      organizationId: row.organizationId,
      companyId: row.companyId,
    });
  const { downloadAllPayslip } = useDownloadPayslips();
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);

  const handleDownload = () => {
    return downloadAllPayslip({
      organizationId: row.organizationId,
      companyId: row.companyId,
      periodId: row.period,
      payrollType: row.type,
    });
  };

  const handleSendPayslip = () => {
    return sendPayslips({
      periodId: row.period,
      payrollType: row.type,
    });
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <IconDotsVertical
          style={{
            width: '24px',
            height: '24px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="filters-popover"
      >
        <MenuList>
          {!hasSentAllPayslips(row.totalizers) && (
            <MenuItem onClick={handleSendPayslip}>
              <Typography variant="body2" my={0.5} mx={1}>
                Enviar holerites agora
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={handleDownload}>
            <Typography variant="body2" my={0.5} mx={1}>
              Baixar Extrato
            </Typography>
          </MenuItem>
        </MenuList>
      </Popover>

      <SendPayslipsComponent {...sendPayslipsProps} />
    </Box>
  );
}
