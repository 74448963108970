import {
  ContractSummary,
  LegalEntitySummary,
  PayrollConfigurationTimesheetConfig,
  PayrollInputsPayload,
} from '@octopus/api';

import { PayrollEmployeeData } from '../../types';

export enum TimesheetStepErrorCode {
  PAYROLL_INPUT_ID_NOT_SUPPORTED = 'PAYROLL_INPUT_ID_NOT_SUPPORTED',
  MISSING_FIELD = 'MISSING_FIELD',
  MISMATCH_VALUE = 'MISMATCH_VALUE',
  CONTRACT_NOT_FOUND = 'CONTRACT_NOT_FOUND',
  PERIOD_NOT_MATCH = 'PERIOD_NOT_MATCH',
  LEGAL_ENTITY_NOT_MATCH = 'LEGAL_ENTITY_NOT_MATCH',
  MISSING_HEADER = 'MISSING_HEADER',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NO_DATA = 'NO_DATA',
}

export enum TimesheetStepErrorType {
  CONFIGURATION = 'CONFIGURATION',
  VALIDATION = 'VALIDATION',
  MATCHING = 'MATCHING',
}

export type TimesheetStepErrorBase = {
  type: TimesheetStepErrorType;
  code: TimesheetStepErrorCode;
  line: number;
  columns: string[];
  value: readonly string[];
  expected: readonly string[];
};

export type TimesheetStepError = TimesheetStepErrorBase;
export type TimesheetStepWarning = TimesheetStepErrorBase & {
  timesheet?: TimesheetRecord;
};

export type TimesheetColumn = {
  value: string;
  column: string;
  line: number;
  name: string;
};

export type TimesheetLine = {
  [key: string]: TimesheetColumn;
};

export type TimesheetRecord = {
  cnpj: TimesheetColumn;
  cpf: TimesheetColumn;
  month: TimesheetColumn;
  year: TimesheetColumn;
  code?: TimesheetColumn;
  type: TimesheetColumn;
  value: TimesheetColumn;
};

export type TimesheetEntry = {
  contract: ContractSummary;
  timesheet: TimesheetRecord;
  employee: PayrollEmployeeData;
};

type TimesheetFileColumnDefinition = {
  name: string;
  type: string;
  mapping: keyof TimesheetRecord;
  validation?: {
    required?: boolean;
    pattern?: string;
    placeholder?: string;
    transform?: (value: string) => string;
  };
};

export type TimesheetFileDefinition = {
  format: string;
  delimiter: string;
  stringDelimiter: string;
  type: PayrollConfigurationTimesheetConfig['type'];
  columns: TimesheetFileColumnDefinition[];
};

export type TimesheetMapping = {
  timesheetsByEncryptedCpf: {
    [encryptedCpf: string]: TimesheetRecord[];
  };
  employeesByContractId: {
    [contractId: string]: PayrollEmployeeData;
  };
  contractsByEncryptedCpf: {
    [encryptedCpf: string]: ContractSummary;
  };
  legalEntitiesByCnpj: {
    [cnpj: string]: LegalEntitySummary;
  };
  payrollInputsByInputId: PayrollInputsPayload;
};

export type TimesheetResult = {
  warnings: TimesheetStepWarning[];
  entries: TimesheetEntry[];
};
