import type { MaskitoOptions } from '@maskito/core';

import { PixKeyType, pixKeyTypes } from '../../payrolls/parser';

const phoneOptions = {
  mask: [
    '(',
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
} as MaskitoOptions;

const cpfOptions = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
} as MaskitoOptions;

const cnpjOptions = {
  mask: [
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '.',
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
} as MaskitoOptions;

const cepOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
} as MaskitoOptions;

const bankAgencyOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/, '-', /\w/],
} as MaskitoOptions;

const bankAccountOptions = {
  mask: ({ value }) => {
    if (value.length <= 5) {
      return [/\d/, /\d/, /\d/, /\d/, /\d/];
    }
    let digitsMask = Array.from(value.split('-')[0]).map(() => /\d/);
    if (!digitsMask.length) {
      return [/\d/];
    }
    if (digitsMask.length > 11) {
      digitsMask = digitsMask.slice(0, 11);
    }
    return [...digitsMask, '-', /[\dX]/];
  },
} as MaskitoOptions;

const randomPixKeyOptions = {
  mask: [
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    '-',
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    '-',
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    '-',
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    '-',
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
    /[0-9a-f]/,
  ],
} as MaskitoOptions;

export function getPixKeyMask(type: PixKeyType): MaskitoOptions {
  switch (type) {
    case pixKeyTypes.cpf:
      return MaskitoOptionsBR.cpf;
    case pixKeyTypes.cnpj:
      return MaskitoOptionsBR.cnpj;
    case pixKeyTypes.celular:
      return MaskitoOptionsBR.phone;
    case pixKeyTypes.aleatoria:
      return MaskitoOptionsBR.randomPixKey;
    default:
      return {
        mask: /.?/,
      };
  }
}

const insurancePolicyOptions = {
  mask: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

const cnaeOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, '/', /\d/, /\d/],
} as MaskitoOptions;

const cboOptions = {
  mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
} as MaskitoOptions;

export const MaskitoOptionsBR = {
  phone: phoneOptions,
  cpf: cpfOptions,
  cnpj: cnpjOptions,
  cep: cepOptions,
  bankAgency: bankAgencyOptions,
  bankAccount: bankAccountOptions,
  randomPixKey: randomPixKeyOptions,
  insurancePolicy: insurancePolicyOptions,
  cnae: cnaeOptions,
  cbo: cboOptions,
};
