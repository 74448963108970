import { ReactElement, Ref, useRef, useState } from 'react';

import { useMaskito } from '@maskito/react';
import { GridApi } from 'ag-grid-community';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  Divider,
  Popper,
  TextField,
  Typography,
} from '@mui/material';

import { PayrollInputsPayloadTypes, PayrollInputsTags } from '@octopus/api';
import { formatDecimal, formatMoney } from '@octopus/formatters';

import { ColumnInfo, PayrollEmployeeData } from './types';
import {
  getInputPropsForPayloadType,
  getPlaceholderForPayloadType,
  getRegexForPayloadType,
  parseHours,
  parseValueForPayloadType,
} from './utils';

export type ColumnInfoPopoverProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  info: ColumnInfo | null;
  data: PayrollEmployeeData[];
  api: GridApi<unknown> | null;
  handleClose: () => void;
  setAll: (colId: string, value: string | null) => void;
};

export function ColumnInfoPopper({
  open,
  anchorEl,
  info,
  data,
  api,
  handleClose,
  setAll,
}: ColumnInfoPopoverProps) {
  if (anchorEl === null || info === null || api === null) {
    return null;
  }
  const payloadTotal = calculatePayloadTotal(
    info.colId,
    info.payloadType,
    data,
  );
  const employeeCount = data.filter(
    (payroll) => payroll.inputs[info.colId],
  ).length;
  return (
    <Popper
      id="ColumnInfoPopover"
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [-8, 8],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 16,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top-start', 'right-start'],
          },
        },
      ]}
      sx={{
        zIndex: 1500,
      }}
      popperOptions={{
        strategy: 'fixed',
      }}
    >
      <Box
        minWidth="352px"
        display="flex"
        flexDirection="column"
        bgcolor="background.paper"
        border="1px solid rgba(0, 0, 0, 0.1)"
        borderRadius={1}
        overflow="hidden"
      >
        <ColumnInfoHeader
          label={info.label}
          tag={info.tag}
          payloadTotal={payloadTotal}
          employeeCount={employeeCount}
        />
        <Box
          display="flex"
          flexDirection="column"
          gap={0.25}
          px={1.5}
          pt={1}
          pb={1.5}
        >
          <OrderByMenuItem
            sortByColumn={(direction) => {
              api.applyColumnState({
                state: [{ colId: info.colId, sort: direction }],
                defaultState: { sort: null },
              });
              handleClose();
            }}
          />
          <ApplyValueToAllMenuItem
            payloadType={info.payloadType}
            applyToAll={(value: string) => {
              setAll(
                info.colId,
                parseValueForPayloadType(value, info.payloadType),
              );
              handleClose();
            }}
            cancel={() => handleClose()}
          />
          <SelectColumnMenuItem
            selectColumn={() => {
              api.clearRangeSelection();
              api.setFocusedCell(
                api.getRenderedNodes()[0].rowIndex,
                info.colId,
                null,
              );
              api.addCellRange({
                rowStartIndex: 0,
                rowEndIndex: api.getDisplayedRowCount(),
                columns: [info.colId],
              });
            }}
            handleClose={handleClose}
          />
        </Box>
      </Box>
    </Popper>
  );
}

type ColumnInfoHeaderProps = {
  label: string;
  tag: PayrollInputsTags;
  payloadTotal?: string;
  employeeCount: number;
};

function ColumnInfoHeader({
  label,
  tag,
  payloadTotal,
  employeeCount,
}: ColumnInfoHeaderProps) {
  return (
    <Box
      py={2}
      px={3}
      display="flex"
      flexDirection="column"
      bgcolor="background.default"
      gap={1}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Typography variant="body2" fontWeight="700">
          {label}
        </Typography>
        <PayrollTagBadge tag={tag} />
      </Box>
      <Box py={0.5}>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={0.5}
        minHeight="36px"
        justifyContent="center"
      >
        {payloadTotal && (
          <Box display="flex" gap={0.5}>
            <Typography variant="caption" fontWeight="500">
              Total na folha:
            </Typography>
            <Typography variant="caption" fontWeight="700">
              {payloadTotal}
            </Typography>
          </Box>
        )}
        <Box display="flex" gap={0.5}>
          <Typography variant="caption" fontWeight="500">
            Colaboradores:
          </Typography>
          <Typography variant="caption" fontWeight="700">
            {employeeCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

type OrderByMenuItemProps = {
  sortByColumn: (direction: 'asc' | 'desc') => void;
};

export function OrderByMenuItem({ sortByColumn }: OrderByMenuItemProps) {
  const showRef = useRef<boolean>(false);
  const itemRef = useRef<HTMLElement>(undefined);

  const [open, setOpen] = useState<boolean>(false);

  const closeExtraMenu = () => {
    showRef.current = false;
    setTimeout(() => {
      if (!showRef.current) {
        setOpen(false);
      }
    }, 300);
  };

  return (
    <MenuItem
      onMouseEnter={() => {
        showRef.current = true;
        setTimeout(() => {
          if (showRef.current) {
            setOpen(true);
          }
        }, 300);
      }}
      onMouseLeave={() => {
        closeExtraMenu();
      }}
      itemRef={itemRef}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" fontWeight="500">
          Ordenar por
        </Typography>
        <KeyboardArrowRightIcon sx={{ width: '16px', height: '16px' }} />
        <Popper
          id="selectOrder"
          open={open}
          anchorEl={itemRef.current}
          placement="right-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: ({ placement }: { placement: string }) => {
                  if (placement === 'right-start') {
                    return [-8, 16];
                  }
                  return [-8, 6];
                },
              },
            },
          ]}
        >
          <Box
            minWidth="284px"
            p={1}
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius={1}
            overflow="hidden"
            onMouseEnter={() => {
              showRef.current = true;
            }}
            onMouseLeave={() => {
              closeExtraMenu();
            }}
          >
            <MenuItem onClick={() => sortByColumn('asc')}>
              <Box display="flex" alignItems="center" gap={1.5}>
                <ArrowDownwardIcon sx={{ width: '16px', height: '16px' }} />
                <Typography variant="body2" fontWeight="500">
                  Menor para maior valor
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem onClick={() => sortByColumn('desc')}>
              <Box display="flex" alignItems="center" gap={1.5}>
                <ArrowDownwardIcon sx={{ width: '16px', height: '16px' }} />
                <Typography variant="body2" fontWeight="500">
                  Maior para menor valor
                </Typography>
              </Box>
            </MenuItem>
          </Box>
        </Popper>
      </Box>
    </MenuItem>
  );
}

type ApplyValueToAllMenuItem = {
  payloadType: PayrollInputsPayloadTypes;
  applyToAll: (value: string) => void;
  cancel: () => void;
};

export function ApplyValueToAllMenuItem({
  payloadType,
  applyToAll,
  cancel,
}: ApplyValueToAllMenuItem) {
  const showRef = useRef<boolean>(false);
  const itemRef = useRef<HTMLElement>(undefined);

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const maskito = useMaskito({
    options: {
      mask: getRegexForPayloadType(payloadType),
    },
  });

  const closeExtraMenu = () => {
    showRef.current = false;
    setTimeout(() => {
      if (!showRef.current) {
        setOpen(false);
      }
    }, 300);
  };

  return (
    <MenuItem
      onMouseEnter={() => {
        showRef.current = true;
        setTimeout(() => {
          if (showRef.current) {
            setOpen(true);
          }
        }, 300);
      }}
      onMouseLeave={() => {
        closeExtraMenu();
      }}
      itemRef={itemRef}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Typography variant="body2" fontWeight="500">
          Aplicar um mesmo valor para todos colaboradores
        </Typography>
        <KeyboardArrowRightIcon sx={{ width: '16px', height: '16px' }} />
        <Popper
          id="applyValueToAll"
          open={open}
          anchorEl={itemRef.current}
          placement="right-start"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: ({ placement }: { placement: string }) => {
                  if (placement === 'right-start') {
                    return [-12, 16];
                  }
                  return [-12, 6];
                },
              },
            },
          ]}
          sx={{
            zIndex: 1500,
          }}
        >
          <Box
            minWidth="284px"
            p={1.5}
            display="flex"
            flexDirection="column"
            bgcolor="background.paper"
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius={1}
            overflow="hidden"
            onMouseEnter={() => {
              showRef.current = true;
            }}
            onMouseLeave={() => {
              closeExtraMenu();
            }}
            gap={1}
          >
            <TextField
              value={value}
              onInput={(e) => setValue((e.target as HTMLInputElement).value)}
              placeholder={getPlaceholderForPayloadType(payloadType)}
              InputProps={getInputPropsForPayloadType(payloadType)}
              ref={maskito}
            />
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Button
                color="secondary"
                variant="contained"
                sx={{ py: 1 }}
                onClick={() => cancel()}
              >
                Cancelar
              </Button>
              <Button
                color="primary"
                sx={{ py: 1 }}
                onClick={() => applyToAll(value)}
              >
                Aplicar
              </Button>
            </Box>
          </Box>
        </Popper>
      </Box>
    </MenuItem>
  );
}

type SelectColumnMenuItemProps = {
  selectColumn: () => void;
  handleClose: () => void;
};

function SelectColumnMenuItem({
  selectColumn,
  handleClose,
}: SelectColumnMenuItemProps) {
  return (
    <MenuItem
      onClick={() => {
        handleClose();
        selectColumn();
      }}
    >
      <Typography variant="body2" fontWeight="500">
        Selecionar todas as células
      </Typography>
    </MenuItem>
  );
}

type PayrollTagBadgeProps = {
  tag: PayrollInputsTags;
};

function PayrollTagBadge({ tag }: PayrollTagBadgeProps) {
  const { bgColor, fontColor, label } = (() => {
    switch (tag) {
      case 'earnings':
        return {
          bgColor: 'strokes.success',
          fontColor: 'success.dark',
          label: 'Provento',
        };
      case 'deductions':
        return {
          bgColor: 'strokes.error',
          fontColor: 'error.dark',
          label: 'Dedução',
        };
      case 'informative':
        return {
          bgColor: 'strokes.info',
          fontColor: 'info.dark',
          label: 'Informativa',
        };
      case 'events':
        return {
          bgColor: 'strokes.warning',
          fontColor: 'warning.dark',
          label: 'Evento',
        };
      default:
        return {
          bgColor: 'transparent',
          fontColor: 'text.secondary',
          label: '',
        };
    }
  })();
  return (
    <Box px={1} py={0.5} borderRadius={0.5} bgcolor={bgColor}>
      <Typography variant="caption" fontWeight="500" color={fontColor}>
        {label}
      </Typography>
    </Box>
  );
}

function calculatePayloadTotal(
  colId: string,
  payloadType: PayrollInputsPayloadTypes,
  data: PayrollEmployeeData[],
) {
  switch (payloadType) {
    case 'currency':
      return formatMoney(
        data.reduce(
          (acc, payroll) => acc + parseFloat(payroll.inputs[colId] ?? '0'),
          0,
        ),
      );
    case 'number':
      return formatDecimal(
        data.reduce(
          (acc, payroll) => acc + parseFloat(payroll.inputs[colId] ?? '0'),
          0,
        ),
      );
    case 'hours': {
      const totalMinutes = data.reduce(
        (acc, payroll) => acc + parseHours(payroll.inputs[colId]),
        0,
      );
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours}h ${minutes.toString().padStart(2, '0')}m`;
    }
    default:
      return undefined;
  }
}

type MenuItemProps = {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  itemRef?: Ref<unknown>;
  children: ReactElement;
};

function MenuItem({
  onClick,
  onMouseEnter,
  onMouseLeave,
  itemRef,
  children,
}: MenuItemProps) {
  return (
    <Box
      px={1.5}
      py={1}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      bgcolor="background.paper"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        '&:hover': {
          bgcolor: 'background.default',
        },
        '&:active': {
          bgcolor: 'strokes.heavy',
        },
        cursor: 'pointer',
      }}
      ref={itemRef}
    >
      {children}
    </Box>
  );
}
