import { useQuery } from '@tanstack/react-query';

import { Drawer, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import {
  OrganizationEntry,
  fetchSearchAllUsers,
  useGetOrganizationEntry,
} from '@octopus/api';
import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../utils';

import { UsersTableComponents } from './components/table';
import { UserDetails, useUserDetailsControls } from './UserDetails';

export type UsersTableProps = {
  organizationId: string;
};
export function UsersTable({ organizationId }: UsersTableProps) {
  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: { organizationId },
    },
    {
      enabled: !!organizationId,
    },
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {organizationQuery.isLoading || !organizationQuery.data ? (
        <Skeleton variant="rounded" height={300} width="100%" />
      ) : (
        <UsersDataGrid organization={organizationQuery.data} />
      )}
    </>
  );
}

function UsersDataGrid({ organization }: { organization: OrganizationEntry }) {
  const organizationId = organization.organizationId;

  const dataGridProps = useDataGrid({
    filters: UsersTableComponents.searchFilters,
    pagination: {
      rowsPerPageOptions: [50, 100],
      initialRowsPerPageOptionIndex: 1,
    },
  });

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  const usersQuery = useQuery({
    queryKey: [organizationId, searchInput, paginationProps],
    queryFn: ({ signal }) => {
      return fetchSearchAllUsers(
        {
          pathParams: {
            organizationId: organizationId ?? '',
          },
          body: searchInput,
        },
        signal,
      ).then(({ data, ...response }) => ({
        ...response,
        data: data.filter(({ memberships }) => memberships.length > 0),
      }));
    },
    enabled: !!organizationId,
  });

  const {
    selectedUser,
    deselectUser,
    selectUser,
    selectNextUser,
    selectPreviousUser,
    afterUpdate,
  } = useUserDetailsControls(usersQuery, usersQuery.refetch);

  const columns = UsersTableComponents.getColumns(
    organization,
    usersQuery.refetch,
  );

  return (
    <>
      <Box
        display="flex"
        sx={{
          width: '100%',
          pt: 1,
          mt: 5,
        }}
      >
        <DataGridToolbar
          filters={UsersTableComponents.searchFilters}
          searchProps={searchProps}
          filteringProps={filteringProps}
          sx={{ width: '100%', pb: 0 }}
        />
      </Box>
      <DataFetching
        fetchResult={usersQuery}
        Loading={() => (
          <Box display="flex" flexDirection="column" gap="8px">
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        )}
        Data={({ data }) => {
          const response = data;
          return (
            <Box mt={2} pb={6}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.userId}
                  rows={response.data}
                  columns={columns}
                  onRowClick={({ row }) => selectUser(row)}
                />
              ) : null}
            </Box>
          );
        }}
      />

      <Drawer
        anchor="right"
        open={!!selectedUser}
        onClose={deselectUser}
        elevation={2}
      >
        {selectedUser && (
          <UserDetails
            organization={organization}
            user={selectedUser.user}
            controls={{
              close: deselectUser,
              hasNext: selectedUser.hasNext,
              hasPrevious: selectedUser.hasPrevious,
              onNext: selectNextUser,
              onPrevious: selectPreviousUser,
              afterUpdate: afterUpdate,
            }}
          />
        )}
      </Drawer>
    </>
  );
}
