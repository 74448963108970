import { Arrayable } from 'type-fest';

import {
  BRRubricMetadados,
  PayrollRecurringEventBrMediasCustomizadasIncideNaMedia,
} from '@octopus/api';

import { PayrollElement, PayrollRecord } from '../../core';

import { recordTypes } from './constants';

const tiposDeRubrica = {
  provento: '1',
  desconto: '2',
  informativa: '3',
  informativaDedutora: '4',
} as const;

type TipoDeRubrica = (typeof tiposDeRubrica)[keyof typeof tiposDeRubrica];

const incidencias = {
  inss: {
    // Não é base de cálculo
    naoEBaseDeCalculo: '00',

    // Não é base de cálculo em função de acordos internacionais de previdência social
    naoEBaseDeCalculoAcordosInternacionais: '01',

    // Base de cálculo das contribuições sociais - Salário de contribuição:
    // Mensal
    baseDeCalculoMensal: '11',

    // 13º salário
    baseDeCalculo13Salario: '12',

    // Exclusiva do empregador - Mensal
    baseExclusivaEmpregadorMensal: '13',

    // Exclusiva do empregador - 13° salário
    baseExclusivaEmpregador13Salario: '14',

    // Exclusiva do segurado - Mensal
    baseExclusivaSeguradoMensal: '15',

    // Exclusiva do segurado - 13° salário
    baseExclusivaSegurado13Salario: '16',

    // Salário-maternidade mensal, pago pelo empregador
    salarioMaternidadeMensalEmpregador: '21',

    // Salário-maternidade 13º salário, pago pelo empregador
    salarioMaternidade13SalarioEmpregador: '22',

    // Salário-maternidade mensal, pago pelo INSS
    salarioMaternidadeMensalINSS: '25',

    // Salário-maternidade 13° salário, pago pelo INSS
    salarioMaternidade13SalarioINSS: '26',

    // Contribuição descontada do segurado sobre salário de contribuição:
    // Mensal
    contribuicaoDescontadaSeguradoMensal: '31',

    // 13º salário
    contribuicaoDescontadaSegurado13Salario: '32',

    // SEST
    contribuicaoDescontadaSeguradoSEST: '34',

    // SENAT
    contribuicaoDescontadaSeguradoSENAT: '35',

    // Outros:
    // Salário-família
    salarioFamilia: '51',
  },
  irrf: {
    // Verba transitada pela folha de pagamento de natureza diversa de rendimento ou retenção/isenção/dedução de IR
    verbaTransitadaFolhaPagamento: '9',

    // Rendimento tributável (base de cálculo do IR) - Remuneração mensal
    remuneracaoMensal: '11',

    // Rendimento tributável (base de cálculo do IR) - 13º salário
    decimoTerceiro: '12',

    // Rendimento tributável (base de cálculo do IR) - Férias
    ferias: '13',

    // Rendimento tributável (base de cálculo do IR) - PLR
    PLR: '14',

    // Retenção do IRRF efetuada sobre:
    // Retenção - Remuneração mensal
    retencaoRemuneracaoMensal: '31',

    // Retenção - 13º salário
    retencao13Salario: '32',

    // Retenção - Férias
    retencaoFerias: '33',

    // Retenção - PLR
    retencaoPLR: '34',

    // Dedução do rendimento tributável do IRRF:
    // Dedução - Previdência Social Oficial - PSO - Remuneração mensal
    deducaoPSORemuneracaoMensal: '41',

    // Dedução - PSO - 13º salário
    deducaoPSO13Salario: '42',

    // Dedução - PSO - Férias
    deducaoPSOFerias: '43',

    // Dedução - Previdência complementar - Salário mensal
    deducaoPrevidenciaComplementarSalarioMensal: '46',

    // Dedução - Previdência complementar - 13º salário
    deducaoPrevidenciaComplementar13Salario: '47',

    // Dedução - Previdência complementar - Férias
    deducaoPrevidenciaComplementarFerias: '48',

    // Dedução - Pensão alimentícia - Remuneração mensal
    deducaoPensaoAlimenticiaRemuneracaoMensal: '51',

    // Dedução - Pensão alimentícia - 13º salário
    deducaoPensaoAlimenticia13Salario: '52',

    // Dedução - Pensão alimentícia - Férias
    deducaoPensaoAlimenticiaFerias: '53',

    // Dedução - Pensão alimentícia - PLR
    deducaoPensaoAlimenticiaPLR: '54',

    // Dedução - Fundo de Aposentadoria Programada Individual - FAPI - Remuneração mensal
    deducaoFAPIRemuneracaoMensal: '61',

    // Dedução - FAPI - 13º salário
    deducaoFAPI13Salario: '62',

    // Dedução - Fundação de previdência complementar do servidor público - Remuneração mensal
    deducaoFundacaoPrevidenciaComplementarRemuneracaoMensal: '63',

    // Dedução - Fundação de previdência complementar do servidor público - 13º salário
    deducaoFundacaoPrevidenciaComplementar13Salario: '64',

    // Dedução - Fundação de previdência complementar do servidor público - Férias
    deducaoFundacaoPrevidenciaComplementarFerias: '65',

    // Dedução - FAPI - Férias
    deducaoFAPIFerias: '66',

    // Dedução - Plano privado coletivo de assistência à saúde
    deducaoPlanoPrivadoColetivoAssistenciaSaude: '67',

    /* Rendimento não tributável ou isento do IRRF: */

    // Parcela isenta 65 anos - Remuneração mensal
    parcelaIsenta65AnosRemuneracaoMensal: '70',

    // Parcela isenta 65 anos - 13º salário
    parcelaIsenta65Anos13Salario: '71',

    // Diárias
    diarias: '72',

    // Ajuda de custo
    ajudaDeCusto: '73',

    // Indenização e rescisão de contrato, inclusive a título de PDV e acidentes de trabalho
    indenizacaoRescisaoContrato: '74',

    // Abono pecuniário
    abonoPecuniario: '75',

    // Rendimento de beneficiário com moléstia grave ou acidente em serviço - Remuneração mensal
    rendimentoMolestiaGraveAcidenteServicoRemuneracaoMensal: '76',

    // Rendimento de beneficiário com moléstia grave ou acidente em serviço - 13º salário
    rendimentoMolestiaGraveAcidenteServico13Salario: '77',

    // Auxílio moradia
    auxilioMoradia: '700',

    // Parte não tributável do valor de serviço de transporte de passageiros ou cargas
    valorServicoTransportePassageirosCargas: '701',

    // Outras isenções
    outrasIsencoes: '79',
  },
  fgts: {
    // Não é base de cálculo do FGTS
    naoEBaseDeCalculo: '00',

    // Base de cálculo do FGTS mensal
    baseDeCalculoMensal: '11',

    // Base de cálculo do FGTS 13° salário
    baseDeCalculo13Salario: '12',

    // Base de cálculo do FGTS aviso prévio indenizado
    baseDeCalculoAvisoPrevioIndenizado: '21',
  },
} as const;

type Rubrica = {
  codigo: string;
  nome: string;
  natureza: string;

  tipo: TipoDeRubrica;
  codIncCP: (typeof incidencias)['inss'][keyof (typeof incidencias)['inss']];
  codIncIRRF: (typeof incidencias)['irrf'][keyof (typeof incidencias)['irrf']];
  codIncFGTS: (typeof incidencias)['fgts'][keyof (typeof incidencias)['fgts']];

  descricao?: string;
  nota?: string;
  metadados?: BRRubricMetadados;
  mediaCustomizada?: PayrollRecurringEventBrMediasCustomizadasIncideNaMedia;

  /**
   * Padrão true para proventos, false para descontos.
   */
  incideNoCustoTotal?: boolean;
};

type Rubricas = Record<Rubrica['codigo'], Rubrica>;

type MatchRubricas = {
  codigo?: Arrayable<Rubrica['codigo']>;
  natureza?: Arrayable<Rubrica['natureza']>;
  tipo?: Arrayable<Rubrica['tipo']>;
  codIncCP?: Arrayable<Rubrica['codIncCP']>;
  codIncIRRF?: Arrayable<Rubrica['codIncIRRF']>;
  codIncFGTS?: Arrayable<Rubrica['codIncFGTS']>;
  incideNoCustoTotal?: boolean;
};

type IdDaTabelaDeRubricas = string;
type ElementIdParaRubrica =
  `rubrica.${IdDaTabelaDeRubricas}.${Rubrica['codigo']}`;

type TabelasDeRubricasRecord = PayrollRecord & {
  type: typeof recordTypes.tabelasDeRubricas;
  payload: {
    tabelas: Record<IdDaTabelaDeRubricas, Rubricas>;
    mapDeElementsPadroes?: Record<PayrollElement['id'], ElementIdParaRubrica>;
  };
};

const makeElementIdParaRubrica = (
  tabelaId: IdDaTabelaDeRubricas,
  rubricaCodigo: Rubrica['codigo'],
): ElementIdParaRubrica => `rubrica.${tabelaId}.${rubricaCodigo}`;

const isElementIdParaRubrica = (str: string): str is ElementIdParaRubrica =>
  str.startsWith('rubrica.') && !str.includes('@');

const breakElementIdParaRubrica = (
  str: ElementIdParaRubrica,
): {
  idDaTabela: IdDaTabelaDeRubricas;
  codigoDaRubrica: Rubrica['codigo'];
} => {
  const [, idDaTabela, codigoDaRubrica] = str.split('.') as [
    string,
    string,
    string,
  ];
  return { idDaTabela, codigoDaRubrica };
};

export type {
  ElementIdParaRubrica,
  IdDaTabelaDeRubricas,
  MatchRubricas,
  Rubrica,
  Rubricas,
  TabelasDeRubricasRecord,
  TipoDeRubrica,
};

export {
  breakElementIdParaRubrica,
  incidencias,
  isElementIdParaRubrica,
  makeElementIdParaRubrica,
  tiposDeRubrica,
};
