import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';
import { padStart } from 'lodash';

import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  ContractBRPjEntry,
  PjPaymentAttachmentAllowedContentTypes,
  PjPaymentAttachmentEntry,
  PjPaymentRequestEntry,
  fetchDeleteContractPaymentRequest,
  fetchDeleteContractPaymentRequestAttachment,
  fetchGetContractAllPaymentRequestsAttachment,
  fetchGetContractPaymentRequest,
  fetchGetContractPaymentRequestAttachment,
  fetchPostContractPaymentRequestsAttachment,
  useGetContract,
} from '@octopus/api';
import { formatDateTimeBR, formatPeriodDate } from '@octopus/formatters';
import {
  attachmentStatuses,
  paymentRequestStatuses,
} from '@octopus/pj-payment-types';
import { Tag } from '@octopus/ui/design-system';

import { BackButton } from '../../../../modules/components/BackButton';
import { uploadFile } from '../../../../modules/components/file/upload';
import {
  CancelPaymentRequestDialog,
  ErrorDialog,
  LoadingDialog,
} from '../../../../modules/components/pj/dialogs';
import {
  AttachmentInfo,
  LucroPresumidoAmounts,
  SimplesNacionalAmounts,
  StateInformation,
  poolOpenSearch,
} from '../../../../modules/components/pj/pjComponents';
import { DataFetching } from '../../../../modules/dataFetching';
import { FileComponent } from '../../../payment-requests/[payment-request]/fileComponents';

export function ContractorPaymentRequestPage({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  return (
    <ContractorPaymentRequestPageContent
      contractId={contractId}
      organizationId={organizationId}
      companyId={companyId}
    />
  );
}

function ContractorPaymentRequestPageContent({
  organizationId,
  companyId,
  contractId,
}: {
  organizationId: string;
  companyId: string;
  contractId: string;
}) {
  const { paymentRequestId } = useParams<{
    paymentRequestId: string;
  }>();

  const [file, setFile] = useState(undefined as File);
  const [attachment, setAttachment] = useState(
    undefined as PjPaymentAttachmentEntry,
  );
  const [error, setError] = useState(false);

  const [mutationCounter, setMutationCounter] = useState(0);

  const queryResult = useQuery({
    queryKey: [
      'getPaymentRequestContractor',
      organizationId,
      companyId,
      paymentRequestId,
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const paymentRequest = await fetchGetContractPaymentRequest({
        pathParams: {
          organizationId,
          companyId,
          contractId,
          paymentRequestId,
        },
      });

      console.log('paymentRequest version:', paymentRequest.version);

      const attachments = await fetchGetContractAllPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          contractId,
          paymentRequestId,
        },
      });

      if (!attachments || attachments.length === 0) {
        return { paymentRequest, attachment: undefined };
      }

      const uploadedAttachment = attachments
        .filter(
          (attachment) => attachment.status === attachmentStatuses.uploaded,
        )
        .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
        .at(-1);

      if (!uploadedAttachment) {
        return { paymentRequest, attachment: undefined };
      }

      const attachment = await fetchGetContractPaymentRequestAttachment({
        pathParams: {
          paymentRequestId,
          organizationId,
          companyId,
          contractId,
          attachmentId: uploadedAttachment.id,
        },
      });

      const fileBlob = await fetch(attachment.downloadUrl).then((response) =>
        response.blob(),
      );

      const file = new File([fileBlob], attachment.fileName, {
        type: attachment.contentType,
      });

      setFile(file);
      setAttachment(attachment);
      return { paymentRequest, attachment };
    },
  });

  const uploadAttachmentMutation = useMutation(async (file: File) => {
    try {
      const attachmentEntry = await fetchPostContractPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          contractId,
          paymentRequestId: paymentRequestId,
        },
        body: {
          type: 'invoice',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry.uploadUrl, file);
      setFile(file);
      setAttachment(attachmentEntry);
      setMutationCounter((prev) => prev + 1);
    } catch (e) {
      setFile(undefined);
      setAttachment(undefined);
      setError(true);
    }
  });

  const deleteAttachmentMutation = useMutation(async () => {
    try {
      await fetchDeleteContractPaymentRequestAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId,
          contractId,
          attachmentId: attachment.id,
        },
      });

      setFile(undefined);
      setAttachment(undefined);
      setMutationCounter((prev) => prev + 1);
    } catch (e) {
      setFile(undefined);
      setAttachment(undefined);
      setError(true);
    }
  });

  useEffect(() => {
    queryResult.refetch();
  }, [queryResult.refetch, mutationCounter]);

  return (
    <>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            display: 'none',
          },
        })}
      >
        <BackButton />
      </Box>
      <DataFetching
        fetchResult={queryResult}
        Data={({ data }) => {
          return (
            <Box
              display={'flex'}
              width={'100%'}
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  height: '100vh',
                },
              })}
            >
              <FileComponent
                file={file}
                showDeleteButton={false}
                isLoading={
                  uploadAttachmentMutation.isLoading ||
                  deleteAttachmentMutation.isLoading ||
                  queryResult.isFetching
                }
                setFile={(file) => {
                  uploadAttachmentMutation.mutate(file);
                }}
              />
              <ViewPaymentRequestComponent
                entry={data.paymentRequest}
                attachment={attachment}
                uploadAttachment={uploadAttachmentMutation.mutateAsync}
                deleteAttachment={deleteAttachmentMutation.mutateAsync}
                fileOperationLoading={
                  uploadAttachmentMutation.isLoading ||
                  deleteAttachmentMutation.isLoading ||
                  queryResult.isFetching
                }
              />
            </Box>
          );
        }}
        Loading={() => (
          <Box
            display={'flex'}
            width={'100vw'}
            height={'100vh'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
        )}
      />
      {error && <ErrorDialog open={error} setOpen={setError} />}
    </>
  );
}

function ViewPaymentRequestComponent({
  entry,
  attachment,
  deleteAttachment,
  uploadAttachment,
  fileOperationLoading,
}: {
  entry: PjPaymentRequestEntry;
  attachment: PjPaymentAttachmentEntry;
  deleteAttachment: () => Promise<void>;
  uploadAttachment: (file: File) => Promise<void>;
  fileOperationLoading: boolean;
}) {
  const contractQuery = useGetContract(
    {
      pathParams: {
        organizationId: entry.organizationId ?? '',
        contractId: entry.contractId ?? '',
      },
    },
    {
      enabled: !!entry.organizationId && !!entry.contractId,
      refetchOnWindowFocus: false,
    },
  );

  const getAmountsComponent = () => {
    if (contractQuery.isLoading) {
      return <Skeleton variant={'rounded'} width={'100%'} height={200} />;
    }

    const components = {
      simples: <SimplesNacionalAmounts entry={entry} />,
      lucroPresumido: <LucroPresumidoAmounts entry={entry} />,
    };

    return components[
      (contractQuery.data.br as ContractBRPjEntry).empresa
        .enquadramentoTributario
    ];
  };

  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display={'flex'}
      boxSizing={'border-box'}
      overflow={'auto'}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          width: '100%',
          px: 2.5,
          mt: -1,
          mb: 12,
          display: 'flex',
          flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
          width: '50%',
          px: 7,
          pt: 5,
          maxHeight: '950px',
        },
      })}
    >
      <Box
        display={'flex'}
        pt={2}
        gap={1.5}
        flexDirection={'column'}
        boxSizing={'border-box'}
        width={'100%'}
      >
        <Box
          pb={1}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'h3'} fontSize={'20px'} fontWeight={700}>
            Solicitação de pagamento
          </Typography>
          <Box
            display={'flex'}
            justifyContent={'end'}
            alignItems={'start'}
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
          >
            <IconButton
              onClick={() => {
                navigate('/contractor/payment-requests');
              }}
            >
              <CloseRoundedIcon
                sx={{
                  width: '32px',
                  height: '32px',
                  color: '#25252D',
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box py={1.5} display={'flex'} flexDirection={'column'} gap={2}>
          <Box
            display={'flex'}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: 1.5,
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            })}
          >
            <Typography
              variant={'caption'}
              fontSize={'12px'}
              color={'text.secondary'}
              noWrap={true}
              lineHeight={'16px'}
            >
              Enviada em {formatDateTimeBR(entry.createdOn)}
            </Typography>
            {entry.status === paymentRequestStatuses.created && (
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <Tag color={'info'}>
                  <Typography
                    color={'info.main'}
                    variant={'body2'}
                    lineHeight={'20px'}
                  >
                    Aguardando aprovação
                  </Typography>
                </Tag>
                {!isSmall && <ActionMenu entry={entry} />}
              </Box>
            )}
          </Box>
          <StateInformation entry={entry} />
        </Box>
        <Field
          label={'Número da nota'}
          value={padStart(entry.invoice?.number?.toString() ?? '0', 10, '0')}
        />
        <Field label={'Competência'} value={formatPeriodDate(entry.period)} />
        <Field
          label={'Descrição'}
          value={entry.description?.length > 0 ? entry.description : '---'}
        ></Field>
        <Box py={2} display={'flex'} flexDirection={'column'}>
          <AttachmentInfo
            attachment={attachment}
            deleteAttachment={deleteAttachment}
            uploadAttachment={uploadAttachment}
            paymentRequestStatus={entry.status}
            isLoading={fileOperationLoading}
          />
          {getAmountsComponent()}
        </Box>
      </Box>
      {entry.status === 'created' && (
        <Box
          sx={(theme) => {
            return {
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            };
          }}
        >
          <CancelButton entry={entry} />
        </Box>
      )}
    </Box>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <Box
      py={2}
      display={'flex'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'caption'} fontWeight={700}>
          {label}
        </Typography>
        <Typography
          variant={'body2'}
          fontWeight={500}
          fontSize={'14px'}
          color={'text.secondary'}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function CancelButton({ entry }: { entry: PjPaymentRequestEntry }) {
  const { organizationId, companyId, id, version, contractId, invoice } = entry;
  const [openDialog, setOpenDialog] = useState(false);

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const cancelPaymentRequestMutation = useMutation(async (file: File) => {
    try {
      setLoading(true);
      await fetchDeleteContractPaymentRequest({
        pathParams: {
          organizationId,
          companyId,
          contractId,
          paymentRequestId: id,
        },
        body: {
          version: version,
        },
      });

      if (!file) {
        return;
      }

      const attachmentEntry = await fetchPostContractPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId: id,
          contractId,
        },
        body: {
          type: 'invoiceCancel',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry.uploadUrl, file);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    } finally {
      if (!error) {
        await poolOpenSearch({
          organizationId,
          companyId,
          contractId,
          paymentRequestId: id,
          filters: {
            status: [paymentRequestStatuses.deleted],
          },
        });

        navigate('/contractor/payment-requests');
      }
    }
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      {loading && (
        <LoadingDialog
          open={true}
          message={'Realizando ação, aguarde alguns segundos...'}
        />
      )}
      {error && (
        <ErrorDialog
          open={error}
          setOpen={() => {
            setError(false);
          }}
        />
      )}

      {openDialog && (
        <CancelPaymentRequestDialog
          open={openDialog}
          setOpen={setOpenDialog}
          action={async (file: File) => {
            await cancelPaymentRequestMutation.mutateAsync(file);
          }}
          hasInvoice={invoice && invoice.fileUploaded}
        />
      )}

      <Box display={'flex'} flexDirection={'row'} width={'100%'} p={1} gap={2}>
        <Button
          variant={'outlined'}
          color={'error'}
          fullWidth
          sx={{
            px: 1.5,
            py: 1,
          }}
          onClick={() => setOpenDialog(true)}
        >
          <Typography
            variant={'body2'}
            color={'error'}
            fontWeight={700}
            lineHeight={'24px'}
          >
            Cancelar solicitação
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

function ActionMenu({ entry }: { entry: PjPaymentRequestEntry }) {
  const { organizationId, companyId, id, version, contractId, invoice } = entry;

  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const cancelPaymentRequestMutation = useMutation(async (file: File) => {
    try {
      setLoading(true);
      await fetchDeleteContractPaymentRequest({
        pathParams: {
          organizationId,
          companyId,
          contractId,
          paymentRequestId: id,
        },
        body: {
          version: version,
        },
      });

      if (!file) {
        return;
      }

      const attachmentEntry = await fetchPostContractPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId: id,
          contractId,
        },
        body: {
          type: 'invoiceCancel',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry.uploadUrl, file);
    } catch (e) {
      console.log(e);
      setError(true);
      setLoading(false);
    } finally {
      if (!error) {
        await poolOpenSearch({
          organizationId,
          companyId,
          contractId,
          paymentRequestId: id,
          filters: {
            status: [paymentRequestStatuses.deleted],
          },
        });

        navigate('/contractor/payment-requests');
      }
    }
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      {loading && (
        <LoadingDialog
          open={true}
          message={'Realizando ação, aguarde alguns segundos...'}
        />
      )}
      {error && (
        <ErrorDialog
          open={error}
          setOpen={() => {
            setError(false);
          }}
        />
      )}

      {openDialog && (
        <CancelPaymentRequestDialog
          open={openDialog}
          setOpen={setOpenDialog}
          action={async (file: File) => {
            await cancelPaymentRequestMutation.mutateAsync(file);
          }}
          hasInvoice={invoice && invoice.fileUploaded}
        />
      )}

      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        data-testid="cancelar-solicitacao-popover"
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'172px'}
          height={'36px'}
          p={0.5}
        >
          <Button
            variant={'text'}
            onClick={() => setOpenDialog(true)}
            fullWidth
            sx={{
              pl: 1.5,
              pr: 4,
            }}
          >
            <Box width={'100%'}>
              <Typography fontSize={'14px'}>Cancelar solicitação</Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
