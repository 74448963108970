import {
  AdmissionDraftContractBRCltEntry,
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  PixKeyType,
  parsePixKey,
} from '../../../../../../modules/payrolls/parser';
import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  const { pagamento } =
    admissionDraftEntry.br as AdmissionDraftContractBRCltEntry;
  return {
    tipoPix: pagamento?.tipoPix || parsePixKey(pagamento?.chavePix),
    chavePix: pagamento?.chavePix,
    codigoBanco: pagamento?.codigoBanco,
    agencia: pagamento?.agencia,
    conta: pagamento?.conta,
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.dados_bancarios: {
      const nomeBanco = formState.codigoBanco
        ? banksList[formState.codigoBanco]
        : null;

      const [tipoPix, chavePix] =
        Object.entries(formState.tipoPix ?? {})?.[0] || [];
      const hasChavePix = tipoPix && tipoPix !== 'chave_pix_none';

      body.br = {
        pagamento: {
          ...(hasChavePix && { tipoPix: tipoPix as PixKeyType }),
          ...(hasChavePix && chavePix && { chavePix: chavePix as string }),
          ...(formState.agencia && { agencia: formState.agencia }),
          ...(formState.codigoBanco && { codigoBanco: formState.codigoBanco }),
          ...(formState.conta && { conta: formState.conta }),
          ...(nomeBanco && { nomeBanco }),
        },
      };
      break;
    }
    default:
      return null;
  }

  return body;
};

export const cltMapper = { getUpdateInputFromFormState, getFormStateFromEntry };
