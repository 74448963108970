import { Link } from 'react-router-dom';

import { IconReportAnalytics } from '@tabler/icons-react';

import {
  BeachAccessOutlined,
  ChevronRightOutlined,
  MonetizationOnOutlined,
  NoteOutlined,
  PeopleAltOutlined,
  PersonRemoveOutlined,
  RequestQuoteOutlined,
} from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

import {
  ReportsDefinitionsSummary,
  useGetReportDefinitions,
} from '@octopus/api';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { DataFetching } from '../../modules/dataFetching';

type IReport = ReportsDefinitionsSummary;
type IReportsCategoriesName = keyof typeof REPORTS_CATEGORIES_NAMES;

const REPORTS_CATEGORIES_NAMES = {
  PEOPLE: 'PEOPLE',
  PAYROLL: 'PAYROLL',
  VACATIONS: 'VACATIONS',
  TERMINATIONS: 'TERMINATIONS',
  PAYMENTS: 'PAYMENTS',
  ESOCIAL: 'ESOCIAL',
  CONTRACTOR: 'CONTRACTOR',
} as const;

const REPORTS_CATEGORIES = {
  [REPORTS_CATEGORIES_NAMES.PEOPLE]: {
    name: 'Pessoas',
    Icon: PeopleAltOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.PAYROLL]: {
    name: 'Folha de Pagamento',
    Icon: RequestQuoteOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.VACATIONS]: {
    name: 'Férias',
    Icon: BeachAccessOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.TERMINATIONS]: {
    name: 'Rescisões',
    Icon: PersonRemoveOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.CONTRACTOR]: {
    name: 'Contratos com prestadores de serviço',
    Icon: MonetizationOnOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.PAYMENTS]: {
    name: 'Pagamentos',
    Icon: MonetizationOnOutlined,
  },
  [REPORTS_CATEGORIES_NAMES.ESOCIAL]: {
    name: 'eSocial',
    Icon: () => <NoteOutlined sx={{ transform: 'rotateY(180deg)' }} />,
  },
};

const getReportHref = (report: IReport) =>
  `/reports/${encodeURIComponent(report.id)}`;

export function ReportsPage({ organizationId }: { organizationId: string }) {
  const getReportDefinitionsResult = useGetReportDefinitions({
    pathParams: {
      organizationId,
    },
  });

  const _reports: ReportsDefinitionsSummary[] =
    (getReportDefinitionsResult.data &&
      getReportDefinitionsResult.data?.['definitions']) ||
    [];

  const reportsByCategory = _reports.reduce(
    (reportsByCategory, report) => {
      reportsByCategory[report.category] =
        reportsByCategory[report.category] || [];
      reportsByCategory[report.category].push(report);
      return reportsByCategory;
    },
    {} as Record<IReportsCategoriesName, IReport[]>,
  );

  const categories = Object.keys(
    REPORTS_CATEGORIES,
  ) as IReportsCategoriesName[];

  return (
    <PageContainer>
      <PageTitle
        dataTestId="reports-header"
        title="Relatórios"
        icon={IconReportAnalytics}
      />

      <DataFetching
        fetchResult={getReportDefinitionsResult}
        Loading={() => <Skeleton sx={{ width: '100%', height: '10em' }} />}
        Data={() =>
          categories
            .filter((category) => reportsByCategory[category])
            .map((category) => (
              <Box
                component="section"
                sx={{
                  paddingBlock: 3,
                }}
              >
                <Typography
                  component="h2"
                  variant="h3"
                  sx={{
                    paddingBottom: 1.5,
                  }}
                >
                  {REPORTS_CATEGORIES[category].name}
                </Typography>
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    padding: 0,
                  }}
                >
                  {reportsByCategory[category].map((report) => (
                    <Box component="li">
                      <Box
                        data-testid={`report-definition-${report.id}`}
                        component={Link}
                        to={getReportHref(report)}
                        sx={{
                          paddingBlock: 2,
                          paddingInlineStart: 3,
                          display: 'flex',
                          borderBlockEndWidth: '1px',
                          borderBlockEndStyle: 'solid',
                          borderColor: (theme) => theme.palette.strokes.light,
                          '&:hover': {
                            cursor: 'pointer',
                          },
                          textDecoration: 'none',
                        }}
                      >
                        <Box
                          component={REPORTS_CATEGORIES[category].Icon}
                          sx={{
                            alignSelf: 'center',
                            width: '1.5rem',
                            fill: (theme) => theme.palette.text.secondary,
                          }}
                        />
                        <Box
                          sx={{
                            marginInline: 3,
                            flex: '1 1 auto',
                          }}
                        >
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{
                              fontWeight: 'bold',
                              display: 'block',
                              lineHeight: '142.857%',
                            }}
                          >
                            {report.name}
                          </Typography>
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{
                              color: 'palette.text.secondary',
                              display: 'block',
                              lineHeight: '133.33%',
                            }}
                          >
                            {report.description}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            flex: '0 0 auto',
                            justifySelf: 'flex-end',
                            alignSelf: 'center',
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              display: 'inline-flex',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            Gerar
                            <ChevronRightOutlined
                              sx={{
                                width: '16px',
                                height: '16px',
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))
        }
      />
    </PageContainer>
  );
}
