import { useRef, useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from '@mui/material';

import {
  GetAllPayrollPayslipPdfPathParams,
  PayrollPayslipEntry,
  PayrollStatus,
} from '@octopus/api';
import { payrollTypes } from '@octopus/payroll-types';

import { useDownloadPayslips } from '../../pdf/useDownloadPayslips';
import { TrashIcon } from '../autonomos/icon';

export function PayslipActionsMenu({
  payslip,
  payroll,
  payrollStatus,
  cancelAction,
}: {
  payslip?: PayrollPayslipEntry;
  payroll?: GetAllPayrollPayslipPdfPathParams;
  payrollStatus?: PayrollStatus;
  cancelAction?: () => void;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  const { downloadAllPayslip, downloadSinglePayslip } = useDownloadPayslips();

  const downloadPayroll = {
    download: () => downloadAllPayslip(payroll),
    label: (
      <Box display="flex" alignItems="center" my={1} mr={1}>
        <FileDownloadOutlinedIcon
          sx={(theme) => ({
            width: '20px',
            height: '20px',
            marginRight: '12px',
            color: theme.palette.strokes.heavy,
          })}
        />
        <Typography variant="body2">
          {'Baixar Holerites (consolidado)'}
        </Typography>
      </Box>
    ),
  };
  const downloadPayslip = {
    download: () => downloadSinglePayslip(payslip),
    label: (
      <Box display="flex" alignItems="center" mr={1}>
        <FileDownloadOutlinedIcon
          sx={(theme) => ({
            width: '20px',
            height: '20px',
            marginRight: '8px',
            color: theme.palette.strokes.heavy,
          })}
        />
        <Typography variant="body2" mr={1}>
          {payslip?.type === payrollTypes.rpa
            ? 'Baixar recibo'
            : 'Baixar Holerite'}
        </Typography>
      </Box>
    ),
  };

  const cancelPayment = {
    label: (
      <MenuItem onClick={() => cancelAction && cancelAction()}>
        <Box
          component={TrashIcon()}
          sx={{
            p: 0,
            pr: 1,
            height: '20px',
            width: '20px',
            color: '#BC1A41',
          }}
        />
        <Typography variant="body2" color="#BC1A41">
          {'Cancelar Pagamento'}
        </Typography>
      </MenuItem>
    ),
  };

  const { download, label } = payslip?.payrollId
    ? downloadPayslip
    : downloadPayroll;

  const shouldShowCancelPayment =
    payslip?.type === payrollTypes.rpa && payrollStatus === 'approved';

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        p={0.5}
        sx={(theme) => ({
          '&:hover': {
            backgroundColor: theme.palette.strokes.light,
          },
          '&:active': {
            backgroundColor: theme.palette.strokes.heavy,
          },
          cursor: 'pointer',
          borderRadius: '8px',
          border: '1px solid #EDEDED',
          width: '24px',
          height: '24px',
        })}
      >
        <MoreVertIcon
          sx={{
            color: 'text.secondary',
            width: '24px',
            height: '24px',
          }}
        />
      </Box>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        {shouldShowCancelPayment ? (
          <MenuList>
            <MenuItem onClick={download}>{label}</MenuItem>
            <Divider sx={{ m: 1 }} />
            {cancelPayment.label}
          </MenuList>
        ) : (
          <MenuList>
            <MenuItem onClick={download}>{label}</MenuItem>
          </MenuList>
        )}
      </Popover>
    </Box>
  );
}
