import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconUsersGroup } from '@tabler/icons-react';

import { Box } from '@mui/material';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';

import { PeopleCount } from './PeopleCount';
import { PeopleTable } from './PeopleTable';

export type PeopleProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function People({ organizationId, companyId }: PeopleProps) {
  const navigate = useNavigate();
  const navigateToContract = (contractId: string) => {
    navigate(`${contractId}`);
  };

  const [showTerminated, setShowTerminated] = useState(false);

  return (
    <PageContainer>
      <PageTitle
        dataTestId="people-header"
        title="Pessoas"
        icon={IconUsersGroup}
      />

      <PeopleCount organizationId={organizationId} companyId={companyId} />

      <Box py={3.2}>
        <PeopleTable
          organizationId={organizationId}
          companyId={companyId}
          onContractClick={navigateToContract}
          setShowTerminated={setShowTerminated}
          showTerminated={showTerminated}
        />
      </Box>
    </PageContainer>
  );
}
