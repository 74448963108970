import React, { ChangeEvent, useState } from 'react';

import { useMaskito } from '@maskito/react';
import { IconExternalLink } from '@tabler/icons-react';
import { identity, pickBy } from 'lodash';

import {
  Alert,
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { CompanyList, JobTitleInput } from '@octopus/api';
import { CBOs } from '@octopus/esocial/mapper';
import { formatCBO } from '@octopus/formatters';

import { MaskitoOptionsBR } from '../../form/Field/MaskitoOptions';
import { ErrorAlert } from '../ErrorAlert';

const CONTRACT_TYPES_OPTIONS = [
  { value: 'br:clt', label: 'Colaborador' },
  { value: 'br:pj', label: 'Prestador de serviço' },
] as const;

export type JobTitleFormProps = {
  companies: CompanyList | undefined;
  form: JobTitleInput;
  setForm: (setter: (form: JobTitleInput) => JobTitleInput) => void;
  failure: string | undefined;
  errors: Record<string, string>;
  clearError: (key: string) => void;
  disabled: boolean;
};

export function JobTitleForm({
  companies,
  form,
  setForm,
  failure,
  errors,
  clearError,
  disabled,
}: JobTitleFormProps) {
  const {
    code,
    name,
    contractTypes,
    enabledForCompanies,
    occupationCode,
    description,
    br,
  } = form;
  const {
    cargoDeConfianca,
    cboDoCargoDeConfianca,
    descricaoDoCargoDeConfianca,
  } = br ?? {};

  const numberMask = useMaskito({
    options: {
      mask: /\d/,
    },
  });
  return (
    <>
      {failure && (
        <Box pb={2}>
          <ErrorAlert message={failure} />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap={4} pb={10}>
        <Box display="flex" flexDirection="row" gap={2.5}>
          <Box display="flex" flexDirection="column" gap={1} flex="1 1 0">
            <Typography variant="caption">Código*</Typography>
            <TextField
              variant="outlined"
              placeholder="0000"
              value={code}
              onInput={(event: ChangeEvent<HTMLInputElement>) => {
                clearError('code');
                setForm((current) => ({
                  ...current,
                  code:
                    event.target.value !== ''
                      ? parseInt(event.target.value)
                      : undefined,
                }));
              }}
              fullWidth
              error={errors.code !== undefined}
              helperText={errors.code}
              disabled={disabled}
              ref={numberMask}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={1} flex="3 1 0">
            <Typography variant="caption">Nome*</Typography>
            <TextField
              variant="outlined"
              placeholder="Ex.: Analista de desenvolvimento Jr"
              value={name}
              onChange={(e) => {
                clearError('name');
                setForm((current) => ({
                  ...current,
                  name: e.target.value,
                }));
              }}
              fullWidth
              error={errors.name !== undefined}
              helperText={errors.name}
              disabled={disabled}
            />
          </Box>
        </Box>
        {companies?.total > 1 && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="caption">
              Quais empresas podem usar este cargo?
            </Typography>
            <Select
              multiple
              value={enabledForCompanies}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                if (value.length === 0) {
                  return;
                }
                setForm((current) => ({
                  ...current,
                  enabledForCompanies: value as string[],
                }));
              }}
              renderValue={(selected) => {
                if (selected.length === companies.total) {
                  return 'Todas';
                }
                return companies.data
                  .filter(({ companyId }) => selected.includes(companyId))
                  .map(({ name }) => name)
                  .join(', ');
              }}
              disabled={disabled}
            >
              {companies.data.map(({ companyId, name }) => (
                <MenuItem key={companyId} value={companyId}>
                  <Checkbox checked={enabledForCompanies.includes(companyId)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption">
            Considerar cargo para qual atuação?
          </Typography>
          <Select
            multiple
            value={contractTypes}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              if (value.length === 0) {
                return;
              }
              setForm((current) => ({
                ...current,
                contractTypes: value as JobTitleInput['contractTypes'],
              }));
            }}
            renderValue={(selected) => {
              if (selected.length === 2 || !selected) {
                return 'Todas';
              }
              return CONTRACT_TYPES_OPTIONS.find(({ value }) =>
                selected.includes(value),
              ).label;
            }}
            disabled={disabled}
          >
            {CONTRACT_TYPES_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={contractTypes.includes(value)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        {contractTypes.includes('br:clt') && (
          <SelectCBO
            cbo={occupationCode}
            setCbo={(code) => {
              clearError('occupationCode');
              setForm((current) => ({
                ...current,
                occupationCode: code,
              }));
            }}
            error={errors.occupationCode !== undefined}
            helperText={errors.occupationCode}
            disabled={disabled}
          />
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="caption">Descrição</Typography>
          <TextField
            variant="outlined"
            placeholder="Descreva o cargo"
            value={description}
            onChange={(e) => {
              clearError('description');
              setForm((current) => ({
                ...current,
                description: e.target.value,
              }));
            }}
            fullWidth
            multiline
            rows={3}
            error={errors.description !== undefined}
            helperText={errors.description}
            disabled={disabled}
          />
        </Box>
        {contractTypes.includes('br:clt') && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={cargoDeConfianca}
                onChange={(event) =>
                  setForm((current) => ({
                    ...current,
                    br: {
                      ...current.br,
                      cargoDeConfianca: event.target.checked,
                    },
                  }))
                }
                disabled={disabled}
              />
              <Typography variant="body1">Cargo de confiança</Typography>
            </Box>
            {cargoDeConfianca && (
              <Box
                borderLeft="6px solid"
                borderColor="strokes.light"
                ml={1}
                px={2}
                py={1}
                display="flex"
                flexDirection="column"
                gap={4}
              >
                <Alert severity="info">
                  <Typography variant="body2" fontWeight="400">
                    Cargos de confiança podem ter CBOs diferentes, pois podem
                    assumir funções diversas. Por exemplo, um gerente de
                    operações pode atuar como gerente de suporte, necessitando
                    um{' '}
                    <Typography
                      variant="body2"
                      fontWeight="700"
                      component="span"
                    >
                      CBO Função
                    </Typography>{' '}
                    adequado a sua atual responsabilidade.
                  </Typography>
                </Alert>
                <SelectCBO
                  cbo={cboDoCargoDeConfianca}
                  setCbo={(code) => {
                    clearError('br/cboDoCargoDeConfianca');
                    setForm((current) => ({
                      ...current,
                      br: {
                        ...current.br,
                        cboDoCargoDeConfianca: code,
                      },
                    }));
                  }}
                  error={errors['br/cboDoCargoDeConfianca'] !== undefined}
                  helperText={errors['br/cboDoCargoDeConfianca']}
                  disabled={disabled}
                />
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography variant="caption">Descrição*</Typography>
                  <TextField
                    variant="outlined"
                    placeholder="Descreva a função"
                    value={descricaoDoCargoDeConfianca}
                    onChange={(e) => {
                      clearError('br/descricaoDoCargoDeConfianca');
                      setForm((current) => ({
                        ...current,
                        br: {
                          ...current.br,
                          descricaoDoCargoDeConfianca: e.target.value,
                        },
                      }));
                    }}
                    fullWidth
                    multiline
                    rows={3}
                    error={
                      errors['br/descricaoDoCargoDeConfianca'] !== undefined
                    }
                    helperText={errors['br/descricaoDoCargoDeConfianca']}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}

function SelectCBO({
  cbo,
  setCbo,
  disabled = false,
  error,
  helperText,
}: {
  cbo: string;
  setCbo: (cbo: string) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}) {
  const [cboName, setCboName] = useState<string>(CBOs.getByCode(cbo) ?? '');
  const cboMask = useMaskito({
    options: MaskitoOptionsBR.cbo,
  });
  return (
    <Box display="flex" flexDirection="row" gap={2.5}>
      <Box display="flex" flexDirection="column" gap={1} flex="1 1 0">
        <Box display="flex" justifyContent="space-between" gap={1}>
          <Typography variant="caption">CBO*</Typography>
          <Box
            display="flex"
            alignItems="center"
            gap={0.75}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(
                'http://www.mtecbo.gov.br/cbosite/pages/pesquisas/BuscaPorTitulo.jsf',
                '_blank',
                'noopener noreferrer',
              );
            }}
          >
            <Typography variant="caption" color="primary">
              MTE
            </Typography>
            <IconExternalLink size={14} className="primary" />
          </Box>
        </Box>
        <TextField
          variant="outlined"
          placeholder="0000-00"
          value={formatCBO(cbo)}
          onInput={(event: ChangeEvent<HTMLInputElement>) => {
            const code = event.target.value?.replace(/\D/g, '');
            setCbo(code);
            if (code.length === 6) {
              setCboName(CBOs.getByCode(code));
            } else {
              setCboName('');
            }
          }}
          fullWidth
          ref={cboMask}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1} flex="3 1 0">
        <Typography variant="caption">Título do CBO</Typography>
        <TextField
          variant="outlined"
          placeholder="Ex.: TRABALHADORES AGROPECUÁRIOS EM GERAL"
          value={cboName}
          disabled
          fullWidth
        />
      </Box>
    </Box>
  );
}

export function sanitizeJobTitleInput(
  input: JobTitleInput,
  companies: CompanyList | undefined,
): JobTitleInput {
  const result = pickBy(input, identity) as JobTitleInput;
  result.name = result.name.trim();
  if (!result.contractTypes?.includes('br:clt')) {
    delete result.br;
  }
  if (result.enabledForCompanies?.length === companies.total) {
    delete result.enabledForCompanies;
  }
  if (result.br?.cargoDeConfianca === false) {
    delete result.br.cboDoCargoDeConfianca;
    delete result.br.descricaoDoCargoDeConfianca;
  }
  return result;
}
