import { AppError, translate } from '@octopus/i18n';

export function translateAPIErrors(error?: AppError): string[] {
  const errors =
    error?.stack?.details &&
    Object.entries(error?.stack?.details)?.map(
      // todo: show field ([field, error]) => `[${field}]: ${ErrorCodes.pt[error.errorCode]}`,
      ([_field, detail]) => {
        const translation = detail.errorCode
          ? translate(detail.errorCode, 'pt')
          : null;
        return translation ?? detail.message;
      },
    );

  return errors;
}

/**
 * Returns API form errors mapped by field.
 *
 * Nested fields path will be written as `<parent>/<child>/[index]`.
 */
export function mapApiErrors(error?: AppError): Record<string, string> {
  return (
    (error?.stack?.errors &&
      error.stack.errors.reduce(
        (errors, error) => {
          error.issues.forEach((issue) => {
            const key = issue.path.join('/');
            errors[key] =
              translate(issue.message, 'pt') ??
              issue.message ??
              'Valor inválido';
          });
          return errors;
        },
        {} as Record<string, string>,
      )) ??
    {}
  );
}
