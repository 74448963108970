import { Box, Container, Toolbar } from '@mui/material';

export const PageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ backgroundColor: 'background.paper', height: '100%' }}>
      <Toolbar />
      <Container>{children}</Container>
    </Box>
  );
};
