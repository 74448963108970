
import { PayrollElement, SerializedPayrollElement } from '../../core/core';
import { elementsIdsPublicos } from '../elements';

import { recordTypes } from './constants';
import {
  Rubrica,
  TabelasDeRubricasRecord, breakElementIdParaRubrica,
  isElementIdParaRubrica, makeElementIdParaRubrica,
} from './rubricas';
import { idsDasTabelasPadroes, rubricasESocial, rubricasProprietarias } from './tabelaPadraoDeRubricas';

type RubricaParaESocial = {
  codigoDaRubrica: string;
  idDaTabela: string;
  valor: string;
  periodoAnterior?: string;
};

const { finais, informativas } = elementsIdsPublicos;
const elementsToRubricasMap: Record<
  string,
  ReturnType<typeof makeElementIdParaRubrica>
> = {
  [finais.comissoes]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1020.codigo,
  ),
  [finais.plr]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial2030.codigo,
  ),

  [finais.salario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1000.codigo,
  ),
  [finais.licencaRemunerada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1000a.codigo,
  ),
  [finais.atrasos]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5050.codigo,
  ),
  [finais.faltasJustificadas]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1050.codigo,
  ),
  [finais.horasFaltasJustificadas]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1050b.codigo,
  ),
  [finais.diasAfastadosSemRemuneracao.desconto]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao0000a.codigo,
  ),
  [finais.diasAfastadosSemRemuneracao.provento]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao0000b.codigo,
  ),
  [finais.faltasJustificadasAntesDeAfastamentoPrevidenciario]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.proprietaria,
      rubricasProprietarias.padrao1050a.codigo,
    ),
  [informativas.salarioBaseAfastamentoPrevidenciario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1740.codigo,
  ),
  [informativas.salarioBaseAfastamentoPrevidenciario13o]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial1745.codigo,
    ),

  [finais.salarioMaternidade.pagoPeloEmpregador]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1700.codigo,
  ),
  [finais.salarioMaternidade.empresaCidada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao4050a.codigo,
  ),

  [finais.horasExtras]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1100.codigo,
  ),
  [finais.horasExtrasNoturnas]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1101.codigo,
  ),
  [finais.horasInterjornada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1150.codigo,
  ),
  [finais.horasIntrajornada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1140.codigo,
  ),
  [finais.horasSobreaviso]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1030.codigo,
  ),
  [finais.horasExtrasBancoDeHoras]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1120.codigo,
  ),

  [finais.dsr.sobreComissoes]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1213.codigo,
  ),
  [finais.dsr.sobreAdicionalNoturno]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1211.codigo,
  ),
  [finais.dsr.sobreHorasExtras]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1210.codigo,
  ),
  [finais.dsr.sobreHorasInterjornada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1002a.codigo,
  ),
  [finais.dsr.sobreHorasIntrajornada]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1002b.codigo,
  ),

  [finais.adicionalNoturno]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1130.codigo,
  ),

  [finais.adicionalDeInsalubridade]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1300.codigo,
  ),

  [finais.adicionalPorTempoDeServico]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1320.codigo,
  ),

  [finais.prolabore.socios]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial2130.codigo,
  ),
  [finais.prolabore.diretoresNaoEmpregados]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial2131.codigo,
  ),

  [finais.estagio.bolsa]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial2100.codigo,
  ),
  [finais.estagio.recessoRemunerado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1350a.codigo,
  ),
  [finais.rescisao.estagio.recessoRemunerado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao1350a.codigo,
  ),
  [finais.estagio.faltasEAtrasos]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5080.codigo,
  ),

  [finais.ferias.dias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1900.codigo,
  ),
  [finais.ferias.tercoConstitucional]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1901.codigo,
  ),
  [finais.ferias.abonoPecuniario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1930.codigo,
  ),
  [finais.ferias.tercoSobreAbono]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1940.codigo,
  ),
  [finais.ferias.dobro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1950.codigo,
  ),
  [finais.ferias.tiradasNoMes]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1910.codigo,
  ),
  [finais.ferias.tercoSobreTiradasNoMes]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1920.codigo,
  ),
  [finais.ferias.valorJaPago]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5030.codigo,
  ),
  [finais.ferias.provisaoDeInss]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5182.codigo,
  ),
  [finais.ferias.ressarcimentoDeProvisaoDeInss]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1984.codigo,
  ),

  [finais.decimoTerceiro.parcelas.primeira]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1800.codigo,
  ),
  [finais.decimoTerceiro.parcelas.segunda]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1810.codigo,
  ),
  [finais.decimoTerceiro.parcelas.ajustePositivo]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1820.codigo,
  ),
  [finais.decimoTerceiro.parcelas.ajusteNegativo]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5048.codigo,
  ),
  [finais.decimoTerceiro.parcelas.descontoDoAdiantamento]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial5040.codigo,
    ),
  [finais.salarioMaternidade.decimoTerceiro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1710.codigo,
  ),
  [finais.salarioMaternidade.decimoTerceiroEmpresaCidada]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.proprietaria,
      rubricasProprietarias.padrao4051a.codigo,
    ),

  [finais.irrf.remuneracaoMensal]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5190.codigo,
  ),
  [finais.irrf.ferias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5191.codigo,
  ),
  [finais.irrf.decimoTerceiro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5192.codigo,
  ),
  [finais.irrf.plr]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5193.codigo,
  ),

  [informativas.irrfDeducaoDependentes.mensal]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial9500.codigo,
  ),
  [informativas.irrfDeducaoDependentes.ferias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial9502.codigo,
  ),
  [informativas.irrfDeducaoDependentes.decimoTerceiro]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial9501.codigo,
    ),

  [informativas.irrfDeducaoInss.mensal]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial9510.codigo,
  ),
  [informativas.irrfDeducaoInss.ferias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial9511.codigo,
  ),

  [finais.inss.mensal]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5180.codigo,
  ),
  [finais.inss.decimoTerceiro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5181.codigo,
  ),

  [finais.insuficienciaDeSaldo]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1840.codigo,
  ),
  [finais.descontoDeSaldoDevedor]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao2930a.codigo,
  ),

  [finais.rescisao.saldoDeSalario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3000.codigo,
  ),
  [finais.rescisao.avisoPrevioIndenizado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3030.codigo,
  ),
  [finais.rescisao.avisoPrevioDescontado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5000.codigo,
  ),
  [finais.rescisao.ferias.proporcionais]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3050.codigo,
  ),
  [finais.rescisao.ferias.sobreAvisoPrevioIndenizado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3065.codigo,
  ),
  [finais.rescisao.ferias.vencidas]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3060.codigo,
  ),
  [finais.rescisao.ferias.vencidasEmDobro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3040.codigo,
  ),
  [finais.rescisao.ferias.tercoConstitucional]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3070.codigo,
  ),

  [finais.rescisao.indenizacaoDataBaseSindicato]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3080.codigo,
  ),
  [finais.rescisao.decimoTerceiro.proporcional]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3020.codigo,
  ),
  [finais.rescisao.decimoTerceiro.sobreAvisoPrevioIndenizado]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial3010.codigo,
    ),
  [finais.rescisao.decimoTerceiro.adiantado]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5040.codigo,
  ),
  [finais.rescisao.indenizacaoArtigo479]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3100.codigo,
  ),

  [finais.rescisao.indenizacaoArtigo480]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5010.codigo,
  ),
  [finais.rescisao.multaAtrasoPagamentoArt477]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3110.codigo,
  ),

  [finais.adiantamentoSalarial.comIncidenciaDeIR]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1040.codigo,
  ),
  [finais.adiantamentoSalarial.semIncidenciaDeIR]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao5501a.codigo,
  ),
  [finais.adiantamentoSalarial.descontoComIncidenciaDeIR]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial5020.codigo,
    ),
  [finais.adiantamentoSalarial.descontoSemIncidenciaDeIR]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasESocial.eSocial5098.codigo,
    ),

  [finais.pensaoAlimenticia.mensal]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5110.codigo,
  ),
  [finais.pensaoAlimenticia.decimoTerceiro]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5111.codigo,
  ),
  [finais.pensaoAlimenticia.ferias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5112.codigo,
  ),
  [finais.pensaoAlimenticia.plr]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5113.codigo,
  ),

  [finais.faltasInjustificadas.sobreSalario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5060.codigo,
  ),
  [finais.faltasInjustificadas.sobreDSR]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial5070.codigo,
  ),
  [finais.descontoDeBancoDeHoras]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao9208a.codigo,
  ),
  [finais.rpa.valorServico]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial1098.codigo,
  ),
  [finais.rpa.iss]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.proprietaria,
    rubricasProprietarias.padrao9222a.codigo,
  ),
  [finais.diferencaRetroativa.salario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3500.codigo,
  ),
  [finais.diferencaRetroativa.decimoTerceiroSalario]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3501.codigo,
  ),
  [finais.diferencaRetroativa.ferias]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasESocial.eSocial3502.codigo,
  ),
  [finais.diferencaRetroativa.salarioMaternidade]: makeElementIdParaRubrica(
    idsDasTabelasPadroes.eSocial,
    rubricasProprietarias.padrao4050b.codigo,
  ),
  [finais.diferencaRetroativa.decimoTerceiroMaternidade]:
    makeElementIdParaRubrica(
      idsDasTabelasPadroes.eSocial,
      rubricasProprietarias.padrao4051b.codigo,
    ),
};

type ElementsToRubricasInput = {
  elements: Record<
    string,
    Pick<
      (SerializedPayrollElement | PayrollElement) & {
        periodoAnterior?: string;
      },
      'id' | 'amount' | 'name'
    >
  >;
};

type ElementsToRubricasOutput<ExtraInfo extends boolean = false> =
  ExtraInfo extends true
    ? RubricaParaESocial & { extra: { name: string; elementId: string } }
    : RubricaParaESocial;

function elementsToRubricas<ExtraInfo extends boolean = false>(
  { elements }: ElementsToRubricasInput,
  { extraInfo }: { extraInfo?: ExtraInfo } = {},
): ElementsToRubricasOutput<ExtraInfo>[] {
  return Object.values(elements)
    .map(
      (
        element,
      ): {
        id: string;
        valor: string;
        periodoAnterior?: string;
        extra?: { name: string; elementId: string };
      } => {
        const extra = extraInfo
          ? {
              extra: {
                name: element.name,
                elementId: element.id,
              },
            }
          : {};

        let elementId = element.id;
        const [periodoAnterior] = elementId
          .split('@')
          .slice(1)
          .map((param) => param.split(':'))
          .filter(([key]) => key === 'periodoAnterior')
          .map(([, value]) => value);

        if (periodoAnterior) {
          const elementIdParts = elementId.split('@');
          if (
            elementIdParts &&
            elementIdParts.length > 1 &&
            elementIdParts[0]
          ) {
            elementId = elementIdParts[0];
          }
        }

        if (elementId in elementsToRubricasMap) {
          const codigoDaRubrica = elementsToRubricasMap[elementId]!;

          return {
            id: codigoDaRubrica,
            valor: element.amount.toString(),
            periodoAnterior,
            ...extra,
          };
        }

        return {
          id: elementId,
          valor: element.amount.toString(),
          ...extra,
        };
      },
    )
    .filter((element) => {
      // Don't send any temporal elements to eSocial
      return !element.id.includes('@');
    })
    .map((element) => {
      // formato rubrica.{idDaTabela}.{codigoDaRubrica}
      if (isElementIdParaRubrica(element.id)) {
        const { idDaTabela, codigoDaRubrica } = breakElementIdParaRubrica(
          element.id,
        );
        return {
          idDaTabela,
          codigoDaRubrica,
          valor: element.valor,
          ...(element.periodoAnterior && {
            periodoAnterior: element.periodoAnterior,
          }),
          ...(element.extra ? { extra: element.extra } : {}),
        } as ElementsToRubricasOutput<ExtraInfo>;
      }

      return undefined;
    })
    .filter(
      (element): element is ElementsToRubricasOutput<ExtraInfo> => !!element,
    );
}

const mapTipoDeRubricaToElementType: Record<
  Rubrica['tipo'],
  'earnings' | 'deductions'
> = {
  '1': 'earnings',
  '2': 'deductions',
  '3': 'earnings',
  '4': 'deductions',
};

const getElementTypeByTipoDeRubrica = (
  tipo: Rubrica['tipo'],
): 'earnings' | 'deductions' => {
  if (!(tipo in mapTipoDeRubricaToElementType) ||
    mapTipoDeRubricaToElementType[tipo] === undefined) {
    throw new Error(`TipoDeRubrica "${tipo}" not found.`);
  }

  return mapTipoDeRubricaToElementType[tipo];
};

const getElementTypeAndNameByRubrica = (
  rubricTablesRecord: TabelasDeRubricasRecord,
  inputRubricaId: string,
): { type: 'earnings' | 'deductions'; name: string } => {
  const rubricaId = isElementIdParaRubrica(inputRubricaId)
    ? inputRubricaId
    : elementsToRubricasMap[inputRubricaId];

  if (!rubricaId) {
    throw new Error(`Input rubrica/element ID "${inputRubricaId}" not found.`);
  }

  const { idDaTabela, codigoDaRubrica } = breakElementIdParaRubrica(rubricaId);

  let rubrica: Rubrica | undefined;

  // Custom rubrics
  const { tabelas } = rubricTablesRecord.payload;
  if (tabelas[idDaTabela]?.[codigoDaRubrica]) {
    rubrica = tabelas[idDaTabela][codigoDaRubrica];
  }

  // Default eSocial rubrics
  if (
    idDaTabela === idsDasTabelasPadroes.eSocial &&
    codigoDaRubrica in rubricasESocial
  ) {
    rubrica = rubricasESocial[codigoDaRubrica as keyof typeof rubricasESocial];
  }

  // Tako's proprietary rubrics
  if (
    idDaTabela === idsDasTabelasPadroes.proprietaria &&
    codigoDaRubrica in rubricasProprietarias
  ) {
    rubrica =
      rubricasProprietarias[
        codigoDaRubrica as keyof typeof rubricasProprietarias
      ];
  }

  if (!rubrica) {
    throw new Error(`Rubrica "${inputRubricaId}" not found.`);
  }

  return {
    type: getElementTypeByTipoDeRubrica(rubrica.tipo),
    name: rubrica.nome,
  };
};

const inputParaMatchElementsDeTabelas = (
  record: TabelasDeRubricasRecord | undefined,
): {
  tabelas: TabelasDeRubricasRecord['payload']['tabelas'];
  rubricasToElements: Record<string, string[]>;
} => {
  const tabelasDeRubricas = {
    type: recordTypes.tabelasDeRubricas,
    payload: {
      tabelas: {
        ...(record?.payload?.tabelas ?? {}),
        [idsDasTabelasPadroes.eSocial]: rubricasESocial,
        [idsDasTabelasPadroes.proprietaria]: rubricasProprietarias,
      },
      mapDeElementsPadroes: elementsToRubricasMap,
    },
  } satisfies TabelasDeRubricasRecord;

  const tabelas = tabelasDeRubricas.payload.tabelas;
  const rubricasToElements = invertMap(
    tabelasDeRubricas.payload.mapDeElementsPadroes,
  );

  return {
    tabelas,
    rubricasToElements,
  };
};

function invertMap(map: Record<string, string>): Record<string, string[]> {
  return (
    Object.entries(map) as [
      string,
      (typeof rubricasESocial)[keyof typeof rubricasESocial]['codigo'],
    ][]
  ).reduce(
    (acc, [key, value]) => {
      if (!acc[value]) {
        acc[value] = [key];
      } else {
        acc[value].push(key);
      }
      return acc;
    },
    {} as Record<
      (typeof rubricasESocial)[keyof typeof rubricasESocial]['codigo'],
      string[]
    >,
  );
}

export {
  elementsToRubricas,
  elementsToRubricasMap,
  inputParaMatchElementsDeTabelas,
  getElementTypeAndNameByRubrica,
  getElementTypeByTipoDeRubrica,
};
