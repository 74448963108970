import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Edit } from '@mui/icons-material';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Skeleton,
  Typography,
} from '@mui/material';

import {
  PayrollElement,
  PayrollEntry,
  useGetContract,
  useGetPayroll,
} from '@octopus/api';
import { isBRCltContract } from '@octopus/contract-types';
import { Categorias, TipoContrato } from '@octopus/esocial/mapper';
import {
  capitalize,
  formatBooleanBR,
  formatCNPJ,
  formatDateBR,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import { Tag } from '@octopus/ui/design-system';

import { ActionMenu } from '../../../modules/components/ActionMenu';
import { BackButton } from '../../../modules/components/BackButton';
import DoubleColumnListRow from '../../../modules/components/DoubleColumnListRow';
import { CalculationExplanation } from '../../../modules/components/payrolls/CalculationExplanation';
import { ElementsTable } from '../../../modules/components/payrolls/ElementsTable';
import { DataFetching } from '../../../modules/dataFetching';
import {
  formatComplementaryTerminationEventType,
  formatTerminationReason,
} from '../../../modules/formatters';
import { downloadPayslipPdf } from '../../../modules/pdf/payslips';

import { ApprovalModal } from './ApprovalModal';

export type TerminationsProps = {
  organizationId: string;
  companyId: string;
};

function ContractDetails({
  payroll,
  organizationId,
}: {
  payroll: PayrollEntry;
  organizationId: string | undefined;
}) {
  const useFetch = () =>
    useGetContract(
      {
        pathParams: {
          organizationId: organizationId ?? '',
          contractId: payroll.contractId,
        },
      },
      {
        enabled: !!organizationId,
      },
    );

  return (
    <Box>
      <Typography variant="h3" color="text.primary">
        Dados do contrato
      </Typography>

      <DataFetching
        useHook={useFetch}
        Loading={() => <Skeleton variant="rounded" height={400} width="100%" />}
        Data={({ data }) => {
          if (isBRCltContract(data)) {
            return (
              <>
                {[
                  [
                    'Tipo de contrato',
                    `${TipoContrato.getByCode(data.br.duracao.tpContr)}`,
                  ],
                  [
                    'Categoria do trabalhador',
                    `${Categorias.getByCode(data.br.trabalho.codCateg)}`,
                  ],
                  [
                    'CNPJ ou código da entidade sindical',
                    `${formatCNPJ(data.br.regime.cnpjSindCategProf)}`,
                  ],
                  [
                    'Data de admissão',
                    `${formatDateBR(payroll.workerData.admissionDate)}`,
                  ],
                ].map(([label, value]) => (
                  <>{DoubleColumnListRow(label, value)}</>
                ))}
              </>
            );
          } else {
            // TODO implementar para PJ
            return (
              <>
                {[
                  ['Tipo de contrato', `Prestador de serviço`],
                  [
                    'Data de admissão',
                    `${formatDateBR(payroll.workerData.admissionDate)}`,
                  ],
                ].map(([label, value]) => (
                  <>{DoubleColumnListRow(label, value)}</>
                ))}
              </>
            );
          }
        }}
      />
    </Box>
  );
}

function ElementsTables({ payroll }: { payroll: PayrollEntry }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [elementStack, setElementStack] = useState<PayrollElement[]>([]);

  const onClickElement = (element: PayrollElement) => {
    setElementStack((state) => [
      ...state,
      payroll.outputs?.elements[element.id],
    ]);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Box
        borderRadius={1.5}
        borderColor="strokes.light"
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <Box px={2}>
          <Typography
            variant="h3"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={0.5}
          >
            Verbas Rescisórias
          </Typography>
        </Box>

        <Box px={2}>
          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Proventos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerEarningsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />

          <Typography
            variant="h4"
            color="text.primary"
            pl={2.3}
            pt={4}
            pb={1.5}
          >
            Descontos
          </Typography>
          <ElementsTable
            payroll={payroll}
            summary="workerDeductionsTotal"
            onClickElement={onClickElement}
            selectedElementId={elementStack[elementStack.length - 1]?.id}
            selectorIconPosition="right"
          />
        </Box>

        <Box px={4} pt={3}>
          <Divider />
        </Box>

        <Box px={2} py={3} display="flex" justifyContent="space-between">
          <table
            width="100%"
            style={{
              tableLayout: 'fixed',
              borderCollapse: 'collapse',
              padding: 0,
            }}
          >
            <tbody>
              <tr>
                <td style={{ width: '16px' }} role="presentation" />
                <td style={{ width: '70%' }}>
                  <Typography variant="h4" color="text.primary">
                    Valor líquido total
                  </Typography>
                </td>
                <td style={{ width: '30%' }}>
                  <Typography variant="h4" color="text.primary">
                    {formatMoney(payroll.outputs?.netPay?.total)}
                  </Typography>
                </td>
                <td style={{ width: '16px' }} role="presentation" />
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      <Drawer
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        elevation={2}
      >
        <Box
          width="520px"
          height="100%"
          sx={{
            opacity: elementStack.length > 0 ? 1 : 0,
            transition: 'all 0.2s',
            backgroundColor: 'background.default',
            overflowY: 'overlay',
          }}
        >
          <CalculationExplanation
            element={elementStack[elementStack.length - 1]}
            onClickElement={onClickElement}
            canGoBack={elementStack.length > 1}
            goBack={() => setElementStack((state) => state.slice(0, -1))}
            onExit={() => {
              setElementStack([]);
              setIsDrawerOpen(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
}

function TerminationDetails({ payroll }: { payroll: PayrollEntry }) {
  return (
    <Box>
      <Typography variant="h3" color="text.primary">
        Dados da rescisão
      </Typography>

      {[
        [
          'Motivo do desligamento',
          `${formatTerminationReason(
            payroll.inputs?.termination?.br?.motivo as string,
          )}`,
        ],
        [
          'Data do aviso prévio',
          `${formatDateBR(payroll.inputs?.termination?.noticeDate)}`,
        ],
        [
          'Data do desligamento',
          `${formatDateBR(payroll.inputs?.termination?.terminationDate)}`,
        ],
      ].map(([label, value]) => (
        <>{DoubleColumnListRow(label, value)}</>
      ))}

      <Divider light sx={{ my: 2 }} />

      {[
        ['Valor do pagamento', `${formatMoney(payroll.outputs?.netPay.total)}`],
        [
          'Data de pagamento',
          // paymentDeadline is a fallback for payrolls calculated before the paymentDate was added to the output
          `${formatDateBR(payroll.outputs?.termination?.paymentDate || payroll.outputs?.termination?.paymentDeadline)}`,
        ],
      ].map(([label, value]) => (
        <>{DoubleColumnListRow(label, value)}</>
      ))}
    </Box>
  );
}

function ComplementaryTerminationDetails({
  payroll,
}: {
  payroll: PayrollEntry;
}) {
  const {
    paymentDate,
    br: {
      dataDoEvento,
      tipoDoEvento,
      remuneradoPeloSucessor,
      periodoDaRemuneracaoRetroativa,
      descricaoDoEvento,
    },
  } = payroll.inputs?.complementaryTermination || {};

  return (
    <Box>
      <Typography variant="h3" color="text.primary">
        Dados da rescisão
      </Typography>

      {[
        ['Valor do pagamento', `${formatMoney(payroll.outputs?.netPay.total)}`],
        ['Data de pagamento', `${formatDateBR(paymentDate)}`],
      ].map(([label, value]) => (
        <>{DoubleColumnListRow(label, value)}</>
      ))}

      <Box pt={2} />

      <Typography variant="h3" color="text.primary">
        Informações complementares
      </Typography>

      {[
        dataDoEvento
          ? ['Data da assinatura do acordo', `${formatDateBR(dataDoEvento)}`]
          : [],
        [
          'Tipo de instrumento',
          `${formatComplementaryTerminationEventType(tipoDoEvento)}`,
        ],
        [
          'Pago por empresa sucessora',
          `${formatBooleanBR(remuneradoPeloSucessor)}`,
        ],
        [
          'Período de complemento',
          `${formatPeriodDate(periodoDaRemuneracaoRetroativa)}`,
        ],
        ['Descrição', `${descricaoDoEvento}`],
      ]
        .filter(([, value]) => value)
        .map(([label, value]) => (
          <>{DoubleColumnListRow(label, value)}</>
        ))}
    </Box>
  );
}

function TerminationTerm({
  payroll,
  organizationId,
}: {
  payroll: PayrollEntry;
  organizationId: string;
}) {
  const [PREVIA_LABEL, FINAL_LABEL] =
    payroll.type === 'complementaryTermination'
      ? ['Cálculo da Rescisão Complementar', 'Termo de Rescisão Complementar']
      : ['Cálculo da Rescisão', 'Termo de Rescisão'];

  return (
    <Box pt={7} pb={14} data-testid="termination-receipt">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
          }}
        >
          <PersonRemoveOutlinedIcon
            fontSize="inherit"
            sx={{
              mr: 1,
              width: '16px',
              height: '16px',
            }}
          />
          {payroll.status === 'open' ? PREVIA_LABEL : FINAL_LABEL}
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          {payroll.status === 'open' && (
            <Tag
              left={<Edit />}
              emphasis="high"
              color="default"
              slotProps={{
                tooltip: {
                  title:
                    'A prévia é um estado temporário da folha de rescisão para a sua conferência, ela não tem impacto na folha mensal e na folha de rescisão, para que seja efetivada você precisa aprovar a prévia.',
                  placement: 'bottom-end',
                  PopperProps: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5],
                        },
                      },
                    ],
                  },
                },
              }}
            >
              Prévia
            </Tag>
          )}
          <ActionMenu
            sx={{
              '--ActionMenu-button-margin': '0px 0px 0px 8px',
            }}
            variant="button"
            menuItems={[
              payroll?.status === 'open' && {
                label: 'Excluir',
                isDisabled: true,
                onClick: () => {
                  return;
                },
              },
              {
                label: 'Baixar PDF',
                onClick: () => downloadPayslipPdf(payroll),
              },
            ]}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h1" color="text.primary">
          {payroll.workerData.name}
        </Typography>
        <Typography variant="caption" component="p" color="text.secondary">
          {capitalize(payroll.workerData.jobTitle, true, true)} |{' '}
          {formatMoney(payroll.workerData.salary)}/mês
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Admissão em {formatDateBR(payroll.workerData.admissionDate)}
        </Typography>
      </Box>

      <Divider light sx={{ my: 3 }} />

      {payroll.status !== 'open' && (
        <ContractDetails payroll={payroll} organizationId={organizationId} />
      )}
      <Box pt={2} />
      {payroll.type === 'complementaryTermination' ? (
        <ComplementaryTerminationDetails payroll={payroll} />
      ) : (
        <TerminationDetails payroll={payroll} />
      )}
      <Box pt={6} />
      <ElementsTables payroll={payroll} />
    </Box>
  );
}

function TerminationReceiptPage({
  organizationId,
  companyId,
}: TerminationsProps) {
  const { payrollId } = useParams<{
    payrollId: string;
  }>();

  const useFetch = () => {
    return useGetPayroll(
      {
        pathParams: {
          organizationId: organizationId ?? '',
          companyId: companyId ?? '',
          payrollId: payrollId ?? '',
        },
      },
      {
        enabled: !!organizationId && !!companyId && !!payrollId,
      },
    );
  };

  const [isApprovalConfirmationVisible, setIsApprovalConfirmationVisible] =
    useState(false);

  const onClickApprove = () => {
    setIsApprovalConfirmationVisible(true);
  };

  return (
    <>
      <BackButton />
      <DataFetching
        useHook={useFetch}
        Loading={() => (
          <Container
            maxWidth={false}
            sx={{
              maxWidth: '800px',
            }}
          >
            <Box pt={12}>
              <Typography variant="h3" color="text.primary">
                <Skeleton width={200} />
              </Typography>
              <Typography variant="h1" color="text.primary">
                <Skeleton width="100%" />
              </Typography>
              <Skeleton width={200} />
              <Skeleton width={100} />
              <Divider sx={{ my: 2 }} />
              <Skeleton variant="rounded" height={300} />
              <Divider sx={{ my: 2 }} />
              <Skeleton variant="rounded" height={300} />
            </Box>
          </Container>
        )}
        Data={({ data }) => (
          <>
            <Container
              maxWidth={false}
              sx={{
                maxWidth: '800px',
              }}
            >
              {data && (
                <TerminationTerm
                  payroll={data}
                  organizationId={organizationId}
                />
              )}
            </Container>
            {data?.status === 'open' && (
              <>
                <Box
                  py={1}
                  px={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    background: 'rgba(247, 247, 248, 0.8)',
                    backdropFilter: 'blur(4px)',
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    left: 0,
                  }}
                >
                  <Button
                    onClick={onClickApprove}
                    size="large"
                    color="primaryAlt"
                  >
                    Aprovar rescisão
                  </Button>
                </Box>

                <ApprovalModal
                  open={isApprovalConfirmationVisible}
                  setOpen={setIsApprovalConfirmationVisible}
                  organizationId={organizationId}
                  companyId={companyId}
                  payrollId={payrollId || ''}
                />
              </>
            )}
          </>
        )}
      />
    </>
  );
}

export default TerminationReceiptPage;
