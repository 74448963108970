import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton } from '@mui/material';

import {
  NotificationConfiguration,
  fetchListVacationsNotificationsConfiguration,
} from '@octopus/api';
import { DataGrid, GridColDef, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../../modules/dataFetching';

export type VacationsResourceType = 'schedules' | 'accrual-periods';
export const VacationsResourceType = {
  schedules: 'schedules',
  accrualPeriods: 'accrual-periods',
} as const;

export default function VacationsNotificationsTable({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const navigateTo = useNavigate();
  const queryParams = {
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
    cacheTime: 0,
    staleTime: 1,
  };

  const dataGridProps = useDataGrid({});
  const { sortingProps, paginationProps } = dataGridProps;

  const listVacationsNotifications = () =>
    fetchListVacationsNotificationsConfiguration({
      pathParams: {
        organizationId,
      },
    });

  const listVacationsNotificationsQuery = useQuery({
    queryKey: ['fetchGetVacationsNotifications', organizationId],
    queryFn: listVacationsNotifications,
    ...queryParams,
  });

  return (
    <DataFetching
      fetchResult={listVacationsNotificationsQuery}
      Loading={() => {
        return (
          <Box display="flex" flexDirection="column" gap="8px" pt={1}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        );
      }}
      Data={({ data }: { data: NotificationConfiguration[] }) => {
        return (
          <Box mt={2}>
            {data ? (
              <DataGrid
                paginationProps={paginationProps}
                sortingProps={sortingProps}
                totalRowCount={data.length || 0}
                getRowId={(row) => row.ruleId}
                onRowClick={({ row }) => {
                  navigateTo(
                    `/vacations/notifications/${row.resourceType}/${row.ruleId}`,
                  );
                }}
                rows={data}
                columns={columns}
                emptyMessage={'Nenhuma configuração de notificação encontrada.'}
              />
            ) : null}
          </Box>
        );
      }}
    />
  );
}

const NameColumn: GridColDef = {
  field: 'name',
  headerName: 'Nome',
  valueGetter: ({ row }) => {
    return row.name;
  },
  renderCell: ({ value }) => {
    return value;
  },
};

const DescriptionColumn: GridColDef = {
  field: 'description',
  headerName: 'Descrição',
  valueGetter: ({ row }) => {
    return row.description;
  },
  renderCell: ({ value }) => {
    return value;
  },
};

const columns = [NameColumn, DescriptionColumn];
