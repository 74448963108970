const JobTitleErrorCodes = {
  JBT00001:
    'CBO precisa ser preenchido se o cargo pode ser usado por contratos CLT',
  JBT00002:
    'CBO do cargo de confiança precisa ser preenchido se cargo de confiança é selecionado',
  JBT00003:
    '"cboDoCargoDeConfianca" e "descricaoDoCargoDeConfianca" não devem ser preenchidos se "cargoDeConfianca" não é selecionado',
  JBT00004: 'CBO inválido',
  JBT00005: 'Nome já está sendo usado por outro cargo',
  JBT00006: 'Não é possivel remover CBO do cargo.',
} as const;

export default JobTitleErrorCodes;
