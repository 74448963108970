import * as React from 'react';
import {
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AddCircleOutlineOutlined, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';

// eslint-disable-next-line @nx/enforce-module-boundaries
import '@octopus/ui/design-system';

import FilterIcon from './filters/filterIcon';

export type NumberRangeFilter = {
  min?: number;
  max?: number;
};
export type StringRangeFilter = {
  min?: string;
  max?: string;
};
export type RangeFilter = NumberRangeFilter | StringRangeFilter;

export const isNumberRangeFilter = (
  filter: RangeFilter,
): filter is NumberRangeFilter => {
  return (
    (!filter.min || typeof filter.min === 'number') &&
    (!filter.min || typeof filter.min === 'number')
  );
};

export const isStringRangeFilter = (
  filter: RangeFilter,
): filter is StringRangeFilter => {
  return (
    (!filter.min || typeof filter.min === 'string') &&
    (!filter.min || typeof filter.min === 'string')
  );
};

export type FilterState = {
  elementFilters: Record<string, string[]>;
  rangeFilters: Record<string, RangeFilter>;
  toggleFilters: Record<string, boolean>;
  customFilters: Record<string, unknown>;
  nestedFilters: Record<string, string[]>;
};

export type FilterOption = {
  id: string;
  label: string;
  icon?: React.ReactNode;
  filterFn: (value: FilterState, row: GridValidRowModel) => boolean;
  component: React.FC<{
    filterState: FilterState;
    changeFilterState: (value: Partial<FilterState>) => void;
    closePopover: () => void;
  }>;
  preview: (filterState: FilterState) => React.ReactNode;
  getInvertSelectionLabel?: (invertSelection: boolean) => string;
  canInvertSelection: boolean;
  elements?: string[];
};

export type FilterOptions = Array<FilterOption>;

export type FilteringProps = {
  showFilterFeatures: boolean;
  activeFilters: Set<string>;

  invertSelection: Record<string, boolean>;
  toggleInvertSelection: (filterId: string) => void;
  getAllElements: () => Record<string, string[]>;

  enableFilter: (filterId: string) => void;

  enableFiltersPopoverAnchorEl: HTMLButtonElement | null;
  enableFiltersPopoverOpen: boolean;
  openEnableFiltersPopover: () => void;
  closeEnableFiltersPopover: () => void;

  fullAddFilterButtonRef: RefObject<HTMLButtonElement>;
  previewsAddFilterButtonRef: RefObject<HTMLButtonElement>;

  filterPopoverOpen: boolean;
  setFilterPopoverOpen: (val: boolean) => void;
  filterPreviewsRefs: MutableRefObject<Record<string, HTMLElement | null>>;

  filtersState: FilterState;
  removeFilter: (filterOpt: FilterOption) => void;

  clearFilters: () => void;

  changeFilterState: (newState: Partial<FilterState>) => void;
  changeFilterId: string;
  changeFilter: (filterId: string) => void;
  changeFilterPopoverOpen: boolean;
  changeFilterPopoverAnchorEl: HTMLElement | null;
  closeChangeFilterPopover: () => void;
};

export function useFiltering({
  filters,
}: {
  filters: FilterOptions;
}): FilteringProps {
  const [activeFilters, setActiveFilters] = useState<Set<FilterOption['id']>>(
    new Set(),
  );

  const [invertSelection, setInvertSelection] = useState<
    Record<string, boolean>
  >({});

  const filterPreviewsRefs = useRef<Record<string, HTMLElement | null>>({});
  const [enableFiltersPopoverOpen, setEnableFiltersPopoverOpen] =
    useState(false);
  const openEnableFiltersPopover = () => setEnableFiltersPopoverOpen(true);
  const closeEnableFiltersPopover = () => setEnableFiltersPopoverOpen(false);
  const [filterPopoverOpen, setFilterPopoverOpen] = useState(false);
  const [filtersState, setFiltersState] = useState<FilterState>({
    elementFilters: {},
    rangeFilters: {},
    toggleFilters: {},
    customFilters: {},
    nestedFilters: {},
  });
  useEffect(() => {
    if (activeFilters.size === 0) {
      setFiltersState({
        elementFilters: {},
        rangeFilters: {},
        toggleFilters: {},
        customFilters: {},
        nestedFilters: {},
      });
    }
  }, [activeFilters]);

  const removeFilter = (filter: FilterOption) => {
    setActiveFilters((prev) => {
      const newSet = new Set(prev);
      newSet.delete(filter.id);
      return newSet;
    });
    setInvertSelection((prev) => {
      const { [filter.id]: _, ...newMap } = prev;
      return newMap;
    });
  };

  const toggleInvertSelection = (filterId: string) => {
    const shouldInvert = invertSelection[filterId] || false;
    setInvertSelection((prev) => ({ ...prev, [filterId]: !shouldInvert }));
  };

  const getAllElements = () => {
    const allElements: Record<string, string[]> = {};
    Array.from(activeFilters).forEach((filterId) => {
      const filter = filters.find((filter) => filter.id === filterId);
      allElements[filterId] = filter?.elements || [];
    });
    return allElements;
  };

  const changeFilterState = (newFilterState: Partial<FilterState>) =>
    setFiltersState((oldFilterState) => ({
      elementFilters: {
        ...oldFilterState.elementFilters,
        ...newFilterState.elementFilters,
      },
      rangeFilters: {
        ...oldFilterState.rangeFilters,
        ...newFilterState.rangeFilters,
      },
      toggleFilters: {
        ...oldFilterState.toggleFilters,
        ...newFilterState.toggleFilters,
      },
      customFilters: {
        ...oldFilterState.customFilters,
        ...newFilterState.customFilters,
      },
      nestedFilters: {
        ...oldFilterState.nestedFilters,
        ...newFilterState.nestedFilters,
      },
    }));

  const [changeFilterId, setChangeFilterId] = useState<string>('');
  const changeFilter = (filterId: string) => {
    setChangeFilterId(filterId);
  };
  const changeFilterPopoverOpen = Boolean(changeFilterId);
  const closeChangeFilterPopover = () => setChangeFilterId('');
  const changeFilterPopoverAnchorEl =
    filterPreviewsRefs.current[changeFilterId]!;

  const enableFilter = (filterId: FilterOption['id']) => {
    setActiveFilters((prev) => new Set(prev).add(filterId));
    setFilterPopoverOpen(true);
    setEnableFiltersPopoverOpen(false);
    setTimeout(() => {
      // this needs to be set after the Filter Preview is on screen
      // Without this, this setState call gets batched with the others
      setChangeFilterId(filterId);
    }, 0);
  };

  const fullAddFilterButtonRef = useRef<HTMLButtonElement>(null);
  const previewsAddFilterButtonRef = useRef<HTMLButtonElement>(null);

  const enableFiltersPopoverAnchorEl = activeFilters.size
    ? previewsAddFilterButtonRef.current
    : fullAddFilterButtonRef.current;

  const clearFilters = () => {
    setActiveFilters(new Set());
  };

  return {
    showFilterFeatures: !!filters.length,
    activeFilters,
    enableFilter,

    enableFiltersPopoverAnchorEl,
    enableFiltersPopoverOpen,
    openEnableFiltersPopover,
    closeEnableFiltersPopover,

    fullAddFilterButtonRef,
    previewsAddFilterButtonRef,

    filterPopoverOpen,
    setFilterPopoverOpen,
    filterPreviewsRefs,

    filtersState,
    removeFilter,

    clearFilters,

    changeFilterState,
    changeFilterId,
    changeFilter,
    changeFilterPopoverOpen,
    changeFilterPopoverAnchorEl,
    closeChangeFilterPopover,

    invertSelection,
    toggleInvertSelection,
    getAllElements,
  };
}

export function AddOrClearFiltersButton({
  filteringProps,
}: {
  filteringProps: Pick<
    ReturnType<typeof useFiltering>,
    | 'activeFilters'
    | 'clearFilters'
    | 'openEnableFiltersPopover'
    | 'fullAddFilterButtonRef'
    | 'showFilterFeatures'
  >;
}) {
  const {
    activeFilters,
    clearFilters,
    openEnableFiltersPopover,
    fullAddFilterButtonRef,
    showFilterFeatures,
  } = filteringProps;

  if (!showFilterFeatures) {
    return null;
  }

  return (
    <Button
      color="secondary"
      startIcon={activeFilters.size ? <Close /> : <FilterIcon />}
      fullWidth
      sx={{
        height: '40px',
      }}
      size="small"
      onClick={activeFilters.size ? clearFilters : openEnableFiltersPopover}
      ref={fullAddFilterButtonRef}
      data-testid="filters-button"
    >
      {activeFilters.size ? 'Limpar filtros' : 'Filtrar'}
    </Button>
  );
}

export function EnableNewFilterPopover({
  filteringProps,
  filters,
}: {
  filteringProps: Pick<
    ReturnType<typeof useFiltering>,
    | 'enableFiltersPopoverAnchorEl'
    | 'enableFiltersPopoverOpen'
    | 'closeEnableFiltersPopover'
    | 'enableFilter'
  >;
  filters: FilterOptions;
}) {
  const {
    enableFiltersPopoverAnchorEl,
    enableFiltersPopoverOpen,
    closeEnableFiltersPopover,
    enableFilter,
  } = filteringProps;

  return (
    <Popover
      open={enableFiltersPopoverOpen}
      anchorEl={enableFiltersPopoverAnchorEl}
      onClose={closeEnableFiltersPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      elevation={1}
      sx={{
        mt: 1,
      }}
      data-testid="filters-popover"
    >
      <Typography variant="body2" fontWeight="bold" mt={2} mx={2}>
        Filtrar por
      </Typography>
      <List sx={{ width: '273px', pt: 1, px: 1 }}>
        {filters.map((filter) => (
          <ListItemButton
            key={filter.id}
            onClick={() => enableFilter(filter.id)}
            sx={{
              my: 1,
              px: 2,
              pt: 0.75,
              pb: 0.875,
              borderRadius: 1,
            }}
            data-testid="filters-option"
          >
            {filter.icon && (
              <ListItemIcon sx={{ minWidth: '', pr: 1 }}>
                {filter.icon}
              </ListItemIcon>
            )}
            <ListItemText
              sx={{
                m: 0,
              }}
              primary={<Typography variant="body2">{filter.label}</Typography>}
            />
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
}

export function FilterPreview({
  filter,
  filteringProps,
}: {
  filter: FilterOption;
  filteringProps: Pick<
    ReturnType<typeof useFiltering>,
    | 'removeFilter'
    | 'filterPreviewsRefs'
    | 'changeFilter'
    | 'filtersState'
    | 'invertSelection'
    | 'toggleInvertSelection'
    | 'getAllElements'
  >;
}) {
  const {
    removeFilter,
    filterPreviewsRefs,
    changeFilter,
    filtersState,
    invertSelection,
    toggleInvertSelection,
  } = filteringProps;

  return (
    <Box
      key={filter.id}
      borderRadius="6px"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.strokes.default}`,
        transition: 'all 0.2s',
        ':hover': {
          backgroundColor: theme.palette.background.default,
          cursor: 'pointer',
        },
      })}
      display="flex"
      alignItems="center"
      ref={(el: HTMLElement | null) =>
        (filterPreviewsRefs.current[filter.id] = el)
      }
      data-testid="filter-preview"
    >
      <Box py={1}>
        <Box
          pl={1.5}
          display="flex"
          sx={(theme) => ({
            borderRight: `1px solid ${theme.palette.strokes.default}`,
          })}
        >
          <Typography
            color="text.secondary"
            variant="body2"
            onClick={() => changeFilter(filter.id)}
          >
            {filter.label}
          </Typography>
          {filter.canInvertSelection && filter.getInvertSelectionLabel && (
            <Typography
              color="text.primary"
              variant="body2"
              sx={{ pl: 1 }}
              onClick={() => toggleInvertSelection(filter.id)}
            >
              {filter.getInvertSelectionLabel(
                invertSelection[filter.id] || false,
              )}
            </Typography>
          )}
          <Typography
            color="info.main"
            variant="body2"
            sx={{ px: 1 }}
            onClick={() => changeFilter(filter.id)}
          >
            {filter.preview(filtersState)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        <IconButton
          color="inherit"
          sx={{ p: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            removeFilter(filter);
          }}
          data-testid="filter-preview-close"
        >
          <Close sx={{ fontSize: '16px' }} />
        </IconButton>
      </Box>
    </Box>
  );
}

export function EnabledFilters({
  filteringProps,
  filters,
}: {
  filteringProps: Pick<
    ReturnType<typeof useFiltering>,
    | 'activeFilters'
    | 'previewsAddFilterButtonRef'
    | 'openEnableFiltersPopover'
    // for FilterPreview:
    | 'removeFilter'
    | 'filterPreviewsRefs'
    | 'changeFilter'
    | 'filtersState'
    | 'toggleInvertSelection'
    | 'invertSelection'
    | 'getAllElements'
    // for ChangeFilterPopover
    | 'changeFilterPopoverAnchorEl'
    | 'changeFilterPopoverOpen'
    | 'closeChangeFilterPopover'
    | 'changeFilterId'
    | 'changeFilterState'
  >;
  filters: FilterOptions;
}) {
  const {
    activeFilters,
    previewsAddFilterButtonRef,
    openEnableFiltersPopover,
  } = filteringProps;

  if (!activeFilters.size) {
    return null;
  }

  return (
    <Box
      mt={1}
      p={1.5}
      sx={(theme) => ({
        border: `1px solid ${theme.palette.strokes.default}`,
        backgroundColor: '#F7F7F8',
      })}
      borderRadius="10px"
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        {Array.from(activeFilters).map((filterId) => {
          const filter = filters.find((filter) => filter.id === filterId);

          if (!filter) {
            return null;
          }

          return (
            <FilterPreview
              key={filter.label}
              filteringProps={filteringProps}
              filter={filter}
            />
          );
        })}

        <IconButton
          ref={previewsAddFilterButtonRef}
          onClick={openEnableFiltersPopover}
        >
          <AddCircleOutlineOutlined />
        </IconButton>
      </Box>
      <ChangeFilterPopover filters={filters} filteringProps={filteringProps} />
    </Box>
  );
}

function ChangeFilterPopover({
  filteringProps,
  filters,
}: {
  filteringProps: Pick<
    ReturnType<typeof useFiltering>,
    | 'changeFilterPopoverAnchorEl'
    | 'changeFilterPopoverOpen'
    | 'closeChangeFilterPopover'
    | 'filtersState'
    | 'changeFilterId'
    | 'changeFilterState'
  >;
  filters: FilterOptions;
}) {
  const {
    changeFilterPopoverAnchorEl,
    changeFilterPopoverOpen,
    closeChangeFilterPopover,
    filtersState,
    changeFilterId,
    changeFilterState,
  } = filteringProps;

  return (
    <Popover
      id="change-filter-popover"
      open={changeFilterPopoverOpen}
      anchorEl={changeFilterPopoverAnchorEl}
      onClose={closeChangeFilterPopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      elevation={1}
      sx={{
        mt: 1,
      }}
      data-testid="filter-popover"
    >
      <Box p={1.5}>
        {filters
          .find((filter) => filter.id === changeFilterId)
          ?.component({
            filterState: filtersState,
            changeFilterState: changeFilterState,
            closePopover: closeChangeFilterPopover,
          })}
      </Box>
    </Popover>
  );
}
