import { useRef, useState } from 'react';

import { IconUserCheck, IconUserX, IconWorld } from '@tabler/icons-react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumnHeaderParams } from '@mui/x-data-grid';

import { OrganizationEntry, UserSummary } from '@octopus/api';
import {
  FilterOptions,
  GridColDef,
  GridValueGetterParams,
  makeDateRangeFilter,
  makeElementListFilter,
} from '@octopus/ui/data-grid';

import { ChangeUserEmailDialog } from './email';
import { UpdateUserPermissionDialog } from './permissions';
import { usePopoverMenu } from './PopoverMenu';
import { UserTextTooltip } from './tooltip';
import { UserManagementUtils } from './utils';

type Membership = UserSummary['memberships'][number];
type Contract = UserSummary['contracts'][number];

const renderColumnHeader = (params: GridColumnHeaderParams) => {
  return <Box>{params.field}</Box>;
};
function ColumnValue({ children }: { children: React.ReactNode }) {
  return <Box sx={{ py: 1 }}>{children}</Box>;
}

const getColumns = (
  organization: OrganizationEntry,
  refetch: () => void,
): GridColDef[] => [
  // name of the user
  {
    field: 'name',
    headerName: 'Usuário(a)',
    renderHeader: renderColumnHeader,
    flex: 1,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return <ColumnValue>{params.row.name}</ColumnValue>;
    },
  },
  // user flags as icons:
  // - whether or not it has active contracts
  // - whether or not it is ssoOnly
  {
    field: 'contracts.active',
    headerName: 'Configurações',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      const contracts: Contract[] = params.row.contracts;
      // active == at least one contract for the user in this organization is active
      const hasContract = contracts.length > 0;
      const active = contracts.some((contract) => contract.active);

      const contractComponent = !hasContract ? (
        <UserTextTooltip title="Sem vínculo" placement="top">
          <IconUserX color={'#BABABF'} className="medium" />
        </UserTextTooltip>
      ) : (
        <UserTextTooltip
          title={`Vínculo ${active ? 'ativo' : 'inativo'}`}
          placement="top"
        >
          <IconUserCheck
            color={active ? '#0058DB' : '#BABABF'}
            className="medium"
          />
        </UserTextTooltip>
      );

      const orgHasSsoDomain = organization.settings?.domains?.some(
        (domain) => domain.sso,
      );
      const userIsSso = !!params.row.ssoOnly;

      const ssoComponent = !orgHasSsoDomain ? null : (
        <UserTextTooltip
          title={`${userIsSso ? 'Acessa' : 'Não acessa'} via domnínio corporativo`}
          placement="top"
        >
          <IconWorld
            color={userIsSso ? '#0058DB' : '#BABABF'}
            className="medium"
          />
        </UserTextTooltip>
      );

      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          gap={1.5}
          sx={{ py: 1, width: '50%' }}
        >
          {contractComponent}
          {ssoComponent}
        </Box>
      );
    },
  },
  // latestLoginDate formatted (with tooltip)
  {
    field: 'latestLoginDate',
    headerName: 'Último acesso',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      const { label, value } = UserManagementUtils.formatDatetime(
        params.row.latestLoginDate,
      );
      if (label === 'Nunca') {
        return (
          <ColumnValue>
            <Typography
              variant="body2"
              sx={(theme) => ({
                color: theme.palette.strokes.heavy,
              })}
            >
              {label}
            </Typography>{' '}
          </ColumnValue>
        );
      }
      if (label === 'Hoje') {
        return <ColumnValue>{label}</ColumnValue>;
      }
      return (
        <ColumnValue>
          <UserTextTooltip title={value} placement="top">
            <Box>{label}</Box>
          </UserTextTooltip>
        </ColumnValue>
      );
    },
  },
  // user's permission
  // & action menu:
  // - change permission
  // - send invite to change to sso email
  {
    field: 'memberships.type',
    headerName: 'Permissões',
    flex: 1,
    sortable: false,
    renderHeader: renderColumnHeader,
    valueGetter: (params: GridValueGetterParams) => {
      // only a single membership per organization
      const memberships: Membership[] = params.row.memberships;
      const [membership] = memberships;

      return (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            {membership
              ? UserManagementUtils.formatMembershipType(membership.type)
              : '-'}
          </Box>
          <ActionsMenu
            organization={organization}
            user={params.row as UserSummary}
            afterUpdate={refetch}
          />
        </Box>
      );
    },
  },
];

function ActionsMenu({
  organization,
  user,
  afterUpdate,
}: {
  organization: OrganizationEntry;
  user: UserSummary;
  afterUpdate: () => void;
}) {
  const [permissionDialogOpen, setPermissionDialogOpen] =
    useState<boolean>(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
  const openPermissionsDialog = () => {
    setPermissionDialogOpen(true);
    setEmailDialogOpen(false);
  };
  const openEmailDialog = () => {
    setPermissionDialogOpen(false);
    setEmailDialogOpen(true);
  };

  const [currentMembership] = user.memberships;
  const [newMembership, anchorX] =
    currentMembership.type === 'owner'
      ? [{ label: 'padrão', value: 'internal' as const }, -250]
      : [{ label: 'administrador', value: 'owner' as const }, -290];

  const ssoDomains = UserManagementUtils.getSSODomains(organization);

  const popoverMenuAnchor = useRef(null);
  const popoverMenu = usePopoverMenu<'email' | 'membership'>({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: anchorX,
    },
    anchorEl: popoverMenuAnchor.current,
    options: [
      {
        label: `Mudar permissões para usuário ${newMembership.label}`,
        value: 'membership' as const,
      },
      ...(ssoDomains.length > 0 && !user.ssoOnly
        ? [
            {
              label: 'Solicitar troca para email corporativo',
              value: 'email' as const,
            },
          ]
        : []),
    ],
    onSelect: ({ value }) => {
      if (value === 'membership') {
        openPermissionsDialog();
        return;
      }
      if (value === 'email') {
        openEmailDialog();
        return;
      }
    },
  });

  const afterActionDone = () => {
    popoverMenu.close();
    afterUpdate();
  };

  return (
    <>
      <IconButton
        size="small"
        sx={{ borderRadius: 1 }}
        ref={popoverMenuAnchor}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          popoverMenu.toggleOpen();
        }}
      >
        <MoreVertIcon />
      </IconButton>

      {popoverMenu.MenuComponent}

      {/*  must have the conditional `&&` otherwise it fetches memberships from all table entries  */}
      {permissionDialogOpen && (
        <UpdateUserPermissionDialog
          isOpen={permissionDialogOpen}
          close={() => setPermissionDialogOpen(false)}
          organizationId={organization.organizationId}
          membershipId={currentMembership.membershipId}
          selectedMembership={newMembership}
          afterUpdate={afterActionDone}
        />
      )}

      {emailDialogOpen && (
        <ChangeUserEmailDialog
          organizationId={organization.organizationId}
          ssoDomains={ssoDomains}
          user={user}
          isOpen={emailDialogOpen}
          onClose={() => setEmailDialogOpen(false)}
          afterUpdate={afterActionDone}
        />
      )}
    </>
  );
}

const searchFilters: FilterOptions = [
  makeElementListFilter({
    label: 'Situação do Vínculo',
    propertyToFilter: 'contracts.active',
    elements: ['true', 'false', '__EMPTY__'],
    labels: {
      true: 'Ativo',
      false: 'Inativo',
      __EMPTY__: 'Sem vínculo',
    },
    isNested: true,
  }),
  makeElementListFilter({
    label: 'Permissões',
    propertyToFilter: 'memberships.type',
    elements: ['internal', 'owner'],
    labels: {
      internal: 'Padrão',
      owner: 'Administrador',
    },
    isNested: true,
  }),
  makeDateRangeFilter({
    label: 'Último acesso',
    propertyToFilter: 'latestLoginDate',
  }),
  makeElementListFilter({
    label: 'Acessa via domínio corporativo',
    propertyToFilter: 'ssoOnly',
    elements: ['true', 'false'],
    labels: {
      true: 'Usa domínio corporativo',
      false: 'Não acessa via domínio corporativo',
    },
  }),
];

export const UsersTableComponents = {
  searchFilters,
  getColumns,
};
