import { IconCalendarTime } from '@tabler/icons-react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';

import {
  formatDateTimeBR,
  formatInteger,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import { payrollStatusIndex, payslipStatuses } from '@octopus/payroll-types';

import { formatPayrollType } from '../../../modules/formatters';
import { usePopover } from '../../../modules/hooks/usePopover';
import { countPayrollStatus } from '../../../utils/statusIndexUtils';
import { PeriodGroupsConfigType, TypedPeriodSummary } from '../types';

import { PeriodActionsMenu } from './PeriodActionsColumn';
import { ReceiptColumn } from './ReceiptColumn';

export const commonColumns: GridColDef<TypedPeriodSummary>[] = [
  {
    field: 'period',
    headerName: 'Competência',
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPeriodDate(params.row.period);
    },
  },
  {
    field: 'type',
    headerName: 'Tipo de folha',
    renderHeader: () => <Box whiteSpace="nowrap">Tipo de folha</Box>,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return formatPayrollType(params.row.type);
    },
  },
  {
    field: 'counts',
    headerName: 'Colaboradores',
    valueGetter: (params) => {
      const payrollsCount = {
        open: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) => statusIndex <= payrollStatusIndex.open,
        ),
        approved: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) =>
            statusIndex > payrollStatusIndex.open &&
            statusIndex <= payrollStatusIndex.approved,
        ),
        closed: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) =>
            statusIndex > payrollStatusIndex.approved &&
            statusIndex < payrollStatusIndex.archived,
        ),
        archived: countPayrollStatus(
          params.row.totalizers,
          (statusIndex) => statusIndex === payrollStatusIndex.archived,
        ),
      }[params.row.status];

      return formatInteger(payrollsCount, {
        minimumIntegerDigits: 2,
      });
    },
  },
  {
    field: 'netPay',
    headerName: 'Valor líquido',
    sortable: false,
    valueGetter: (params) =>
      formatMoney(params.row.totalizers.totals.netPay) || '--',
  },
  {
    field: 'totalCost',
    headerName: 'Custo total',
    sortable: false,
    valueGetter: (params) =>
      formatMoney(params.row.totalizers.totals.totalCost) || '--',
  },
];

export function getColumnsForStatus(state: PeriodGroupsConfigType) {
  switch (state) {
    case 'open':
      return [
        ...commonColumns,
        {
          field: 'approvalDate',
          headerName: 'Aprovar até',
          valueGetter: (params: GridValueGetterParams) => {
            if (params?.row?.paymentDate) {
              return formatDateTimeBR(params.row.paymentDate);
            }

            return '';
          },
        },
        {
          field: 'reviewCall',
          headerName: '',
          renderCell: () => {
            return (
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body2"
                  color="info.main"
                  px={2}
                  fontWeight={700}
                >
                  {'Revisar'}
                </Typography>
              </Box>
            );
          },
        },
      ];
    case 'approved':
    case 'closed':
      return [
        ...commonColumns,
        {
          field: 'receipts',
          headerName: 'Recibos',
          renderCell: (
            params: GridRenderCellParams<
              string | undefined,
              TypedPeriodSummary
            >,
          ) => {
            return <ReceiptColumn row={params.row} />;
          },
        },
        {
          field: 'sendUntil',
          headerName: 'Enviar até',
          renderHeader: () => <Box whiteSpace="nowrap">Enviar até</Box>,
          valueGetter: (params: GridValueGetterParams) => {
            const [Popover, handlePopoverOpen, handlePopoverClose] =
              // eslint-disable-next-line react-hooks/rules-of-hooks
              usePopover();
            const { row } = params;
            const hasAlreadySentAll =
              row.totalizers?.counts?.payslips?.byStatus?.[
                payslipStatuses.sent
              ] > 0;

            if (hasAlreadySentAll) {
              return '';
            }

            if (row.payslipScheduledSendAt) {
              return (
                <Box
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <Typography variant="body2" color="text.placeholder">
                    {formatDateTimeBR(row.payslipScheduledSendAt)}
                  </Typography>
                  <IconCalendarTime size={16} className="disabled" />
                  <Popover>{'Programado para esta data'}</Popover>
                </Box>
              );
            }

            return formatDateTimeBR(row.paymentDate);
          },
        },
        {
          field: 'actions',
          headerName: '',
          renderCell: (params: GridRenderCellParams) => (
            <PeriodActionsMenu row={params.row} />
          ),
        },
      ];
    default:
      return commonColumns;
  }
}
