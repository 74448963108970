import {
  ChronoUnit,
  DateTimeFormatter,
  DayOfWeek,
  LocalDate,
  YearMonth,
  ZoneId,
  ZonedDateTime,
} from '@js-joda/core';
import '@js-joda/timezone';
import Holidays from 'date-holidays';
import HolidaysClass from 'date-holidays';

import { DateYYYYMM, DateYYYYMMDD } from '@octopus/api';
import { MunicipiosByEstado } from '@octopus/esocial/mapper';

import { Holyday, holidaysByMunicipio } from './holidays';

type YYYYMMDDString = string;
const dateYYYYMMDDRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateYYYYMMRegex = /^\d{4}-\d{2}$/;
export const dateIsoRegex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?([+-]\d{2}:\d{2}|Z)?$/;
const brDateFormat = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

export type DateParts = {
  year: string;
  month: string;
  day?: string;
};

export type DateTimeParts = DateParts & {
  hour: string;
  minute: string;
  second: string;
  locale?: string;
  timezone?: string;
};

export function strToDate(date: string | undefined): Date | undefined {
  if (!date) {
    return undefined;
  }

  const epochMillis = strToEpochMillis(date);
  if (!epochMillis) {
    return undefined;
  }

  return new Date(epochMillis);
}

export function strToEpochMillis(date: string | undefined): number | undefined {
  if (!date) {
    return undefined;
  }
  const parsed = Date.parse(date);
  if (Number.isNaN(parsed)) {
    throw new Error(`${date} is not a valid date`);
  }
  return parsed;
}

export function millisToSeconds(
  millis: number | undefined,
): number | undefined {
  if (!millis) {
    return undefined;
  }
  return Math.floor(millis / 1000);
}

export function secondsToMillis(seconds: number): number | undefined {
  if (!seconds) {
    return undefined;
  }
  return seconds * 1000;
}

export function strToEpochSeconds(
  date: string | undefined,
): number | undefined {
  const millis = strToEpochMillis(date);
  return millisToSeconds(millis);
}

export function epochMillisToStr(
  epochMillis: number | undefined,
): string | undefined {
  if (!epochMillis) {
    return undefined;
  }
  return new Date(epochMillis).toISOString();
}

export function epochSecondsToDate(
  epochSeconds: number | undefined,
): Date | undefined {
  if (!epochSeconds) {
    return undefined;
  }
  return new Date(epochSeconds * 1000);
}

export function epochSecondsToStr(
  epochSeconds: number | undefined,
): string | undefined {
  const converted = epochSecondsToDate(epochSeconds);
  if (!converted) {
    return undefined;
  }
  return converted.toISOString();
}

export function nowInEpochSeconds(): number {
  return millisToSeconds(Date.now())!;
}

export function nowInStr(): string {
  return ZonedDateTime.now(ZoneId.of('America/Sao_Paulo')).format(
    DateTimeFormatter.ISO_OFFSET_DATE_TIME,
  );
}

export function toZonedTimeAtStartOfDay(date: string): ZonedDateTime {
  return LocalDate.parse(date)
    .atStartOfDay()
    .atZone(ZoneId.of('America/Sao_Paulo'))
    .withFixedOffsetZone();
}

export function zonedNow(): ZonedDateTime {
  return ZonedDateTime.now(ZoneId.of('America/Sao_Paulo'));
}

export function todayInStr(): string {
  return LocalDate.now(ZoneId.of('America/Sao_Paulo')).toString();
}

export function epochSecondsToStrDate(
  epochSeconds: number | undefined,
): string | undefined {
  return epochSeconds
    ? epochSecondsToStr(epochSeconds)?.split('T')[0]
    : undefined;
}

export function addYearsOnYYYYMMDD(date: string, years: number) {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`${date} is not a valid YYYY-MM-DD date`);
  }

  const { year, month, day } = breakDate(date);
  return `${Number(year) + years}-${month}-${day}`;
}

export function addDaysOnYYYYMMDD(date: string, days: number) {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`${date} is not a valid YYYY-MM-DD date`);
  }

  const localDate = LocalDate.parse(date);
  const newDate = localDate.plusDays(days);

  const paddedMonth = padDatePart(newDate.monthValue().toString());
  const paddedDay = padDatePart(newDate.dayOfMonth().toString());

  return `${newDate.year()}-${paddedMonth}-${paddedDay}`;
}

export function subtractDaysOnYYYYMMDD(date: string, days: number) {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`${date} is not a valid YYYY-MM-DD date`);
  }

  const localDate = LocalDate.parse(date);
  const newDate = localDate.minusDays(days);

  const paddedMonth = padDatePart(newDate.monthValue().toString());
  const paddedDay = padDatePart(newDate.dayOfMonth().toString());

  return `${newDate.year()}-${paddedMonth}-${paddedDay}`;
}

export function subtractYearsOnYYYYMMDD(date: string, years: number) {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`${date} is not a valid YYYY-MM-DD date`);
  }

  const { year, month, day } = breakDate(date);
  return `${Number(year) - years}-${month}-${day}`;
}

/**
 * Breaks a date in YYYY-MM-DD format into it's parts
 * @param date
 * @returns a type with the date parts, accessible by date.year, date.month and date.day
 */
export function breakDate(date: string): DateParts {
  if (date.split('-').length === 2) {
    const yearMonthDate = YearMonth.parse(date);
    return {
      year: yearMonthDate.year().toString(),
      month: padDatePart(yearMonthDate.month().value().toString()),
    };
  }

  const localDate = LocalDate.parse(date);
  const month = padDatePart(localDate.month().value().toString());

  return {
    year: localDate.year().toString(),
    month: month.padStart(2, '0'),
    day: padDatePart(localDate.dayOfMonth().toString()),
  };
}

function padDatePart(month: string): string {
  return month.padStart(2, '0');
}

/**
 * Breaks a date in ISO string into it's parts
 * @param date the date in format ISO string
 * @param props optional props to override the default locale and timezone
 * @returns a type with the date parts, accessible by date.year, date.month and so on
 */
export function breakISODate(
  date: string,
  props?: { locale?: string; timezone?: string },
): DateTimeParts {
  const locale = props?.locale || 'pt-br';
  const timezone = props?.timezone || 'America/Sao_Paulo';

  if (!dateIsoRegex.test(date)) {
    throw new Error(`${date} is not a valid ISO date`);
  }

  const intDate = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timezone,
  });

  try {
    const parsedDate = new Date(Date.parse(date));
    const formattedDate = intDate.formatToParts(parsedDate).reduce(
      (acc, { type, value }) => {
        acc[type] = value;
        return acc;
      },
      {} as Record<Intl.DateTimeFormatPartTypes, string>,
    );

    return {
      year: formattedDate.year,
      month: formattedDate.month,
      day: formattedDate.day,
      hour: formattedDate.hour,
      minute: formattedDate.minute,
      second: formattedDate.second,
      locale,
      timezone,
    };
  } catch (e) {
    throw new Error(`${date} is not a valid ISO date`);
  }
}

export function convertISODateToYYYYMMDD(date: string) {
  if (dateYYYYMMDDRegex.test(date)) {
    return date;
  }

  const { year, month, day } = breakISODate(date, {
    timezone: 'UTC',
  });
  return `${year}-${month}-${day}`;
}

export function convertISODateToYYYYMM(date: string) {
  const { year, month } = breakISODate(date, {
    timezone: 'UTC',
  });
  return `${year}-${month}`;
}

export function strToLocalDate(
  isoStr: string | undefined,
): LocalDate | undefined {
  if (!isoStr) {
    return undefined;
  }
  return LocalDate.parse(isoStr, DateTimeFormatter.ISO_ZONED_DATE_TIME);
}

export function convertYYYYMMDDToYYYYMM(date: string): DateYYYYMM {
  if (dateYYYYMMRegex.test(date)) {
    return date;
  }

  if (dateYYYYMMDDRegex.test(date)) {
    return date.split('-').splice(0, 2).join('-');
  }

  throw new Error(
    `Invalid date format, ${date} can't be converted to YYYY-MM.`,
  );
}

export function convertDDMMYYYYToYYYYMMDD(date: string): DateYYYYMMDD {
  if (dateYYYYMMDDRegex.test(date)) {
    return date;
  }

  if (brDateFormat.test(date)) {
    return date.split('/').reverse().join('-');
  }

  throw new Error(
    `Invalid date format, ${date} can't be converted to YYYY-MM-DD.`,
  );
}

export function fromCellToDate(cell: unknown, cellName: string): string {
  try {
    return convertDDMMYYYYToYYYYMMDD(cell as string);
  } catch (error) {
    throw new Error(`Invalid date format: ${cell} in cell ${cellName}`);
  }
}

export function isYearMonth(date: string): boolean {
  return dateYYYYMMRegex.test(date);
}

export function convertISODateToDDMMYYYY(date: string) {
  if (dateYYYYMMDDRegex.test(date)) {
    return date.split('-').reverse().join('/');
  }
  const { year, month, day } = breakISODate(date, {
    timezone: 'UTC',
  });
  return `${day}/${month}/${year}`;
}

export function getNumberOfDaysBetweenDates(
  startDate: YYYYMMDDString,
  endDate: YYYYMMDDString,
): number {
  const start = LocalDate.parse(startDate);
  const end = LocalDate.parse(endDate);
  return start.until(end, ChronoUnit.DAYS);
}

export function getMinDate(date1: string, date2: string): string {
  const localDate1 = LocalDate.parse(date1);
  const localDate2 = LocalDate.parse(date2);
  return localDate1.isBefore(localDate2) ? date1 : date2;
}

export function getMaxDate(date1: string, date2: string): string {
  const localDate1 = LocalDate.parse(date1);
  const localDate2 = LocalDate.parse(date2);
  return localDate1.isAfter(localDate2) ? date1 : date2;
}

export function isYearMonthInTheFuture(yearMonth: string) {
  return YearMonth.parse(yearMonth)
    .atEndOfMonth()
    .isAfter(YearMonth.now().atEndOfMonth());
}

export function convertYYYYMMDDToISODatetime(date: string): string {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`Can't convert ${date} to ISO datetime`);
  }

  return new Date(date).toISOString();
}

export function convertDateTimeToDate(date: string) {
  return date.split('T')[0];
}

const HOLIDAYS = new Holidays('BR', {
  types: ['public', 'bank', 'optional'],
});

export function findNearestBankingDate(
  limitPaymentDate: LocalDate,
  location?: { state: string; city: string },
): LocalDate {
  let paymentDate = limitPaymentDate;
  while (!isBankingDay(paymentDate, location)) {
    paymentDate = paymentDate.minusDays(1);
  }

  return paymentDate;
}

export function subtractBankingDaysInYYYYMMDD(
  date: string,
  days: number,
): string {
  if (!dateYYYYMMDDRegex.test(date)) {
    throw new Error(`${date} is not a valid YYYY-MM-DD date`);
  }

  let daysSubtracted = 0;
  const localDate = LocalDate.parse(date);
  let newDate = localDate;

  while (daysSubtracted < days) {
    newDate = newDate.minusDays(1);
    if (isBankingDay(newDate)) {
      daysSubtracted += 1;
    }
  }

  const paddedMonth = padDatePart(newDate.monthValue().toString());
  const paddedDay = padDatePart(newDate.dayOfMonth().toString());

  return `${newDate.year()}-${paddedMonth}-${paddedDay}`;
}

export function isBankingDay(
  date: LocalDate,
  location?: { state: string; city: string },
): boolean {
  const dayOfWeek = date.dayOfWeek();
  if (dayOfWeek === DayOfWeek.SATURDAY || dayOfWeek === DayOfWeek.SUNDAY) {
    return false;
  }

  if (location) {
    return !isHolidayChecker(location.state, location.city)(date.toString());
  }

  // Even on days when banks operate on a part-time schedule
  // we still consider them as banking days.
  const dateTime = date.atTime(13, 0).toString();
  return !HOLIDAYS.isHoliday(dateTime);
}

export function isHolidayChecker(state: string, city: string) {
  const hd: HolidaysClass = new Holidays('BR', state, city, {
    types: ['public', 'bank', 'optional'],
  });

  const municipiosByEstadoElement = MunicipiosByEstado[state.toUpperCase()];
  const cityCode = municipiosByEstadoElement?.findByName(city) ?? city;

  const stateHollidays = holidaysByMunicipio?.[state.toUpperCase()] || {};
  const cityHollidays = cityCode && stateHollidays?.[cityCode];

  if (cityHollidays && cityHollidays?.holidays) {
    cityHollidays.holidays?.forEach((holiday: Holyday) => {
      hd.setHoliday(holiday.date, 'Feriado Municipal');
    });
  }

  return function (date: string): boolean {
    const holidaysInDay = hd.isHoliday(new Date(`${date} 00:00:00 GMT-0300`));
    return (
      holidaysInDay &&
      holidaysInDay.some((holiday) => holiday.type === 'public')
    );
  };
}
