import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import {
  ContractList,
  useGetOrganizationEntry,
  useSearchAllContracts,
} from '@octopus/api';

import { BackButton } from '../../modules/components/BackButton';
import { PageAlert } from '../../modules/components/PageAlert';
import { QueryResult } from '../../modules/types';

import { JobTitlesContent } from './JobTitlesContent';
import { OrganizationSidePanel } from './OrganizationSidePanel';
import { PageContent, pageContents } from './types';

export type OrganizationsProps = {
  organizationId: string;
};

export default function Organizations({ organizationId }: OrganizationsProps) {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState<PageContent>(
    (hash?.replace('#', '') as PageContent) || pageContents.jobTitles,
  );

  useEffect(() => {
    navigate(`#${pageContent}`);
  }, [pageContent]);

  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: {
        organizationId: organizationId ?? '',
      },
    },
    {
      enabled: !!organizationId,
    },
  );

  const contractsQuery = useSearchAllContracts();

  useEffect(() => {
    if (organizationId) {
      contractsQuery.mutate({
        pathParams: {
          organizationId,
        },
        body: {
          pagination: {
            size: 1,
          },
          filtering: {
            elements: {
              status: [{ not: 'terminated' }],
            },
          },
        },
      });
    }
  }, [organizationId]);

  if (!organizationId) {
    return <Navigate to="/" replace />;
  }

  if (organizationQuery.isError) {
    console.error(
      `Failed to load organization: ${JSON.stringify(organizationQuery.error)}`,
    );
    return (
      <>
        <BackButton destination="/" />
        <PageAlert
          message="Erro ao carregar dados da organização"
          severity="error"
          showRetryMessage={true}
        />
      </>
    );
  }

  return (
    <Box display="flex">
      <OrganizationSidePanel
        organizationId={organizationId}
        organizationQuery={organizationQuery}
        contractsQuery={contractsQuery}
        pageContent={pageContent}
        setPageContent={setPageContent}
      />
      <Page
        organizationId={organizationId}
        contractsQuery={contractsQuery}
        pageContent={pageContent}
      />
    </Box>
  );
}

function Page({
  organizationId,
  contractsQuery,
  pageContent,
}: {
  organizationId: string;
  contractsQuery: Omit<QueryResult<ContractList>, 'refetch' | 'isFetching'>;
  pageContent: PageContent;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      width="100%"
      py={15}
      mx={10}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        minWidth="740px"
        gap={2}
      >
        <Box
          display={pageContent === pageContents.jobTitles ? 'block' : 'none'}
        >
          <JobTitlesContent
            organizationId={organizationId}
            contractsQuery={contractsQuery}
          />
        </Box>
      </Box>
    </Box>
  );
}
