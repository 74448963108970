import { ChangeEvent, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps, TextField } from '@mui/material';

export type SearchProps = {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
};

export function useSearch(): SearchProps {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('query') || '';
  const setSearchTerm = (term: string) => {
    setSearchParams({ query: term }, { replace: true });
  };
  return {
    searchTerm,
    setSearchTerm: debounce(setSearchTerm, 500),
  };
}

export type SearchBarProps = {
  placeholder?: string;
  searchProps: SearchProps;
  sx?: SxProps;
  onFocus?: () => void;
  onBlur?: () => void;
  onClose?: () => void;
  withStartAdornment?: boolean;
  hideFilters?: boolean;
};

export function SearchBar({
  placeholder = 'Pesquisar...',
  searchProps,
  sx,
  onFocus,
  onBlur,
  onClose,
  withStartAdornment = true,
  hideFilters = false,
}: SearchBarProps) {
  const { searchTerm, setSearchTerm } = searchProps;
  const [input, setInput] = useState(searchTerm);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setSearchTerm(e.target.value);
  };

  return (
    <TextField
      sx={{
        width: hideFilters ? '400px' : '300px',
        ...sx,
      }}
      placeholder={placeholder}
      InputProps={{
        startAdornment: withStartAdornment ? (
          <SearchIcon sx={{ fontSize: '16px', color: 'text.secondary' }} />
        ) : undefined,
        endAdornment: searchTerm ? (
          <CancelIcon
            data-testid="cancel-icon"
            sx={{
              fontSize: '16px',
              cursor: 'pointer',
              color: 'strokes.heavy',
            }}
            onClick={() => {
              handleChange({ target: { value: '' } } as any);
              onClose?.();
            }}
          />
        ) : undefined,
      }}
      value={input}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      data-testid="table-search"
    />
  );
}
