import { IconPaperclip } from '@tabler/icons-react';

import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

export function LeftPanel({
  selectedItem,
  setSelectedItem,
}: {
  selectedItem: number;
  setSelectedItem: (item: number) => void;
}) {
  return (
    <Box ml="340px">
      <Box
        position="fixed"
        top="0px"
        left="56px"
        display="flex"
        flexDirection="column"
        height="100vh"
        borderRight="1px solid #EDEDED"
        sx={{}}
      >
        <Box
          sx={(theme) => ({
            pt: 10.5,
            px: 5,
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
          })}
        >
          <Box display="flex" sx={{ width: '236px' }}>
            <Box gap={1}>
              <IconPaperclip width={40} height={40} />
              <Typography variant="h2">Templates</Typography>
            </Box>
          </Box>
          <Divider sx={{ pt: 3 }} />
        </Box>

        <LeftPanelMenu
          items={['Criar template', 'Gerar documento']}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Box>
    </Box>
  );
}

function LeftPanelMenu({
  items,
  selectedItem,
  setSelectedItem,
}: {
  items: readonly string[];
  selectedItem: number;
  setSelectedItem: (item: number) => void;
}) {
  return (
    <Box sx={{ px: 5, pt: 2 }}>
      {items.map((text, index) => (
        <LeftPanelMenuItem
          key={text}
          text={text}
          isSelected={selectedItem === index}
          onClick={() => setSelectedItem(index)}
        />
      ))}
    </Box>
  );
}

function LeftPanelMenuItem({
  text,
  isSelected,
  onClick,
}: {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        p: 1,
        borderRadius: 1,
        cursor: 'pointer',
        bgcolor: isSelected ? 'background.default' : 'transparent',
        '&:hover': {
          bgcolor: 'background.default',
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}
      >
        {text}
      </Typography>
    </Box>
  );
}
