import { useState } from 'react';

import { IconShieldChevron } from '@tabler/icons-react';

import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { PageContainer } from '../../modules/components/page/PageContainer';

import { UsersTable } from './UsersTable';

export type UsersManagementPageProps = {
  organizationId: string;
};

export function UsersManagementPage({
  organizationId,
}: UsersManagementPageProps) {
  return (
    <Box display="flex" width="100%">
      <LeftPanel />
      <Box width="100vw">
        <PageContainer>
          <UserTitle />
          <Divider />
          <UsersTable organizationId={organizationId} />
        </PageContainer>
      </Box>
    </Box>
  );
}

function LeftPanel() {
  return (
    <Box ml="340px">
      <Box
        position="fixed"
        top="0px"
        left="56px"
        display="flex"
        flexDirection="column"
        height="100vh"
        borderRight="1px solid #EDEDED"
      >
        {/* shield icon and title */}
        <Box
          sx={(theme) => ({
            pt: 10.5,
            px: 5,
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
          })}
        >
          <Box display="flex" sx={{ width: '236px' }}>
            <Box gap={1}>
              <IconShieldChevron width={40} height={40} />
              <Typography variant="h2">Segurança</Typography>
            </Box>
          </Box>
          <Divider sx={{ pt: 3 }} />
        </Box>

        {/* menu */}
        <LeftPanelMenu items={['Usuários' /*, 'Configurações' */]} />
      </Box>
    </Box>
  );
}

function LeftPanelMenu({ items }: { items: readonly string[] }) {
  const [selectedItem, setSelectedItem] = useState<number>(0);
  return (
    <Box sx={{ px: 5, pt: 2 }}>
      {items.map((text, index) => (
        <LeftPanelMenuItem
          key={text}
          text={text}
          isSelected={selectedItem === index}
          onClick={() => setSelectedItem(index)}
        />
      ))}
    </Box>
  );
}

function LeftPanelMenuItem({
  text,
  isSelected,
  onClick,
}: {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        p: 1,
        borderRadius: 1,
        cursor: 'pointer',
        bgcolor: isSelected ? 'background.default' : 'transparent',
        '&:hover': {
          bgcolor: 'background.default',
        },
      }}
    >
      <Typography
        variant="body2"
        sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}
      >
        {text}
      </Typography>
    </Box>
  );
}

function UserTitle() {
  return (
    <Box
      sx={{
        width: '100%',
        pt: 14,
        pb: 2,
      }}
    >
      <Typography variant="h1">Usuários</Typography>
    </Box>
  );
}
