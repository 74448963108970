import { Help } from '@mui/icons-material';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';

export type CountProps = {
  [title: string]: number;
};

export function Counts({
  props,
  allTooltip,
  allKey,
  dataTestId,
}: {
  props: CountProps;
  allTooltip?: string;
  allKey?: string;
  dataTestId?: string;
}) {
  return (
    <Box px={0.5} mt={1.5} data-testid={dataTestId}>
      <Box display="flex" gap={7}>
        {Object.entries(props).map(([key, value]) => {
          const item = <CountItem title={key} count={value} key={key} />;
          if (allTooltip && key === allKey) {
            return (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                boxSizing="border-box"
                gap={0.5}
              >
                {item}
                <Tooltip
                  title={allTooltip}
                  placement="top"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [16, -10],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <Help color="disabled" fontSize="small" />
                </Tooltip>
              </Box>
            );
          }

          return item;
        })}
      </Box>
    </Box>
  );
}

type CountItemProps = {
  title: string;
  count: number;
};

function CountItem({ title, count }: CountItemProps) {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {title}
      </Typography>
      <Typography variant="body1" fontWeight="700">
        {count}
      </Typography>
    </Box>
  );
}

export const CountsSkeleton = () => {
  return (
    <Box width={'40%'} display="flex" flexDirection="column" mt={1.5} px={0.5}>
      <Skeleton variant="rounded" width={'100%'} height={'40px'} />
    </Box>
  );
};
