const preferences = {
  subtrairFeriasDosMesesTrabalhadosParaMedias: {
    id: 'subtrairFeriasDosMesesTrabalhadosParaMedias' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  subtrairAfastamentosDosMesesTrabalhadosParaMedias: {
    id: 'subtrairAfastamentosDosMesesTrabalhadosParaMedias' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  pagarMediasNaPrimeiraParcela13o: {
    id: 'pagarMediasNaPrimeiraParcela13o' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  quantosDiasTemNoMes: {
    /**
     * Quando for necessário fazer proporcionalidade de dias,
     * considerar qual o tamanho do mês em dias?
     * - Sempre 30
     * - Usar Dias do Mês
     *
      A preferência "quantosDiasTemNoMes" possui duas funções:
      - Definir a quantidade de dias que utilizaremos para dividir o mês e
      - Definir a quantidade de dias que serão pagos em cada situação
      Além de possuir duas funções, ela também é interpretada de maneiras
      diferentes de acordo com a situação:
      Para horas/dias normais:
      - Caso a configuração esteja definida para "diasReaisDoMes", utilizamos a
        quantidade real de dias do mês para os cálculos (por exemplo: 28, 29, 30
        e 31);
      - Caso seja o mês no qual ocorreu a admissão do funcionário e a
        preferência "usarSempreDiasReaisDoMes" estiver definida para "admissao",
        também utilizaremos o cálculo de "diasReaisDoMes";
      - Caso contrário, utilizaremos o cálculo de "sempre30Dias":
        - Caso o último dia do mês tenha sido trabalhado
          OU o mês contenha férias
          OU o último dia do mês tenha sido uma falta justificada: adicionaremos
          ou removeremos 1 ou 2 dias na quantidade de dias trabalhados de acordo
          com a quantidade de dias do mês em questão (para mês de 28 dias,
          adicionamos 2 dias; para mês de 29 dias, adicionamos 1 dia; para mês
          de 31 dias, removemos 1 dia);
          - Caso essa operação resulte em um número menor que 0, ajustamos o
            valor para 0 dias (para ajustar meses de 31 dias);
        - Para todos os outros casos, utilizaremos apenas a quantidade de dias
          trabalhados.
      Para férias:
      - Os dias de férias nunca são alterados por conta dessa configuração;
      - A contagem de dias de férias é exatamente igual à quantidade de dias
        entre o início e o fim das férias.

FIXME: Tenha cuidado! Tem o "mesmo" comentário no arquivo quantosDiasTemNoMes.ts

      Para afastamentos:
      - Caso a configuração esteja definida para "diasReaisDoMes", utilizamos a
        quantidade real de dias do mês para os cálculos (por exemplo: 28, 29, 30
        e 31);
      - Caso contrário, utilizaremos o cálculo de "sempre30Dias":
        - Caso
            o último dia do mês seja um dia de afastamento
            E o evento de afastamento em questão não for uma falta justificada
          OU
            o mês tiver mais de 30 dias
            E o afastamento estiver no começo
              OU o afastamento estiver no final do mês:
          - Adicionaremos ou removeremos 1 ou 2 dias na quantidade de dias
            afastados de acordo com a quantidade de dias do mês em questão (para
            mês de 28 dias, adicionamos 2 dias; para mês de 29 dias, adicionamos
            1 dia; para mês de 31 dias, removemos 1 dia);
        - Para todos os outros casos, utilizaremos apenas a quantidade de dias
          afastados.
     */
    id: 'quantosDiasTemNoMes' as const,
    values: {
      sempre30Dias: 'sempre30Dias',
      diasReaisDoMes: 'diasReaisDoMes',
    },
    defaultValue: 'diasReaisDoMes',
  },
  usarSempreDiasReaisDoMes: {
    /**
     * Para alguns casos, é necessário utilizar sempre a quantidade real de dias
     * do mês e ignorar a configuração "quantosDiasTemNoMes".
     */
    id: 'usarSempreDiasReaisDoMes' as const,
    values: {
      nunca: 'nunca',
      admissao: 'admissao',
    },
    defaultValue: 'nunca',
  },
  proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno: {
    id: 'proporcionalidadeDeDecimoTerceiroAteDezembroParaAdmitidosNoAno' as const,
    values: {
      avos: 'avos',
      ano: 'ano',
    },
    defaultValue: 'avos',
  },
  proporcionalidadeAdiantamento: {
    id: 'proporcionalidadeAdiantamento' as const,
    values: {
      proporcional: 'proporcional',
      integral: 'integral',
    },
    defaultValue: 'proporcional',
  },
  pagarMediasEmFaltasJustificadas: {
    id: 'pagarMediasEmFaltasJustificadas' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  pagarMediasEmLicencaRemunerada: {
    id: 'pagarMediasEmLicencaRemunerada' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  descontarAjusteNegativoDe13o: {
    id: 'descontarAjusteNegativoDe13o' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  considerarMesDaRescisaoParaMedias: {
    id: 'considerarMesDaRescisaoParaMedias' as const,
    values: {
      quandoAdquirirAvo: 'quandoAdquirirAvo',
      sempre: 'sempre',
      nunca: 'nunca',
    },
    defaultValue: 'quandoAdquirirAvo',
  },

  incluirMediasNaMultaArtigo479: {
    // pago pelo empregador
    id: 'incluirMediasNaMultaArtigo479' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'sim',
  },
  incluirMediasNaMultaArtigo480: {
    // pago pelo empregado
    id: 'incluirMediasNaMultaArtigo480' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'nao',
  },
  percentualAdicionalNoturno: {
    id: 'percentualAdicionalNoturno' as const,
    values: {}, // pode ser qualquer valor
    defaultValue: '0.2',
  },
  dsrDeComissoesParaMedias: {
    id: 'dsrDeComissoesParaMedias' as const,
    values: {
      darPreferenciaParaEventoHistorico: 'darPreferenciaParaEventoHistorico',
      sempreRecalcular: 'sempreRecalcular',
    },
    defaultValue: 'sempreRecalcular',
  },
  diasDescontadosParaInsalubridade: {
    id: 'diasDescontadosParaInsalubridade' as const,
    values: {
      ferias: 'ferias',
      admissaoERescisao: 'admissaoERescisao',
      afastamentos: 'afastamentos',
    },
    defaultValue: ['ferias'] as string[],
  },
  sendPayslip: {
    id: 'sendPayslip' as const,
    values: {
      sim: 'sim',
      nao: 'nao',
    },
    defaultValue: 'nao',
  },
  daysBeforePaymentToSendPayslip: {
    id: 'daysBeforePaymentToSendPayslip' as const,
    values: {},
    defaultValue: '0',
  },
} as const;

type PreferenceKey = keyof typeof preferences;
type PreferenceValues<Key> = Key extends PreferenceKey
  ? (typeof preferences)[Key]['values'][keyof (typeof preferences)[Key]['values']]
  : never;

type PreferenceRecord = Record<string, PreferenceValues<PreferenceKey>>;

export { preferences };

export type { PreferenceKey, PreferenceValues, PreferenceRecord };
