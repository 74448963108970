import { useState } from 'react';

import { Box } from '@mui/system';

import { CreateTemplate } from './CreateTemplate';
import { GenerateDocument } from './GenerateDocument';
import { LeftPanel } from './LeftPanel';

export type Props = {
  organizationId: string;
};

export function TemplatesManagerPage({ organizationId }: Props) {
  console.log('organizationId', organizationId);
  const [selectedItem, setSelectedItem] = useState<number>(0);

  return (
    <Box display="flex" width="100%">
      <LeftPanel
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <Box sx={{ px: 10, pb: 6 }} display="flex" width="100%">
        {selectedItem === 0 && <CreateTemplate />}
        {selectedItem === 1 && (
          <GenerateDocument organizationId={organizationId} />
        )}
      </Box>
    </Box>
  );
}
