import { useParams } from 'react-router-dom';

import { IconBeach } from '@tabler/icons-react';


import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';

import VacationsTable from './VacationsTable';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
  contractId: string | undefined;
  isFFEnabled: boolean;
};

function VacationsPage({
  organizationId,
  contractId,
  isFFEnabled,
}: VacationsProps) {
  const { role } = useParams() as { role: 'manager' | 'admin' };
  const subTitle = {
    manager: 'Liderados',
    admin: 'Organização',
  }[role];

  return (
    <PageContainer>
      <PageTitle
        dataTestId="vacations-header"
        icon={IconBeach}
        title="Férias"
        subTitle={isFFEnabled ? subTitle : undefined}
      />

      <VacationsTable
        organizationId={organizationId}
        contractId={contractId}
        isFFEnabled={isFFEnabled}
      />
    </PageContainer>
  );
}

export default VacationsPage;
