import { useState } from 'react';

import {
  IconAlertTriangleFilled,
  IconChevronDown,
  IconChevronUp,
  IconCircleCheckFilled,
} from '@tabler/icons-react';
import dayjs from 'dayjs';

import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

import { ContractEntry } from '@octopus/api';
import {
  DataGrid,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ExpandableTypography } from '../../ExpandableTypography';

import { AddReportResult } from './types';

export function AddedReportsStatus({
  newManager,
  effectiveDate,
  success,
  error,
  onConfirm,
}: {
  newManager: ContractEntry;
  effectiveDate: string;
  success: AddReportResult[];
  error: AddReportResult[];
  onConfirm: () => void;
}) {
  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} mb={16}>
        {success.length > 0 ? (
          <>
            <PartialSuccessMessage />
            <SuccessfullyAddedReportsTable
              newManager={newManager}
              effectiveDate={effectiveDate}
              reports={success}
            />
          </>
        ) : (
          <FailureMessage />
        )}

        <FailedToAddReportsTable reports={error} />
      </Box>
      <Box
        display="flex"
        gap={1}
        py={1}
        px={3}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'end',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          background: 'rgba(247, 247, 247, 0.90)',
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          left: 0,
        })}
      >
        <Button color="primaryAlt" size="large" onClick={onConfirm}>
          Concluir
        </Button>
      </Box>
    </>
  );
}

function PartialSuccessMessage() {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h5" fontWeight="700">
        Atribuição parcialmente concluída
      </Typography>
      <Typography variant="body2" fontWeight="400">
        Alguns colaboradores foram atribuídos corretamente; no entanto,
        ocorreram erros com outros. Por favor, confira os detalhes abaixo.
      </Typography>
      <Divider />
    </Box>
  );
}

function FailureMessage() {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Typography variant="h5" fontWeight="700">
        Falha na atribuição
      </Typography>
      <Typography variant="body2" fontWeight="400">
        Não foi possível realizar a atribuição dos colaboradores. Por favor,
        confira os detalhes abaixo.
      </Typography>
      <Divider />
    </Box>
  );
}

function SuccessfullyAddedReportsTable({
  newManager,
  effectiveDate,
  reports,
}: {
  newManager: ContractEntry;
  effectiveDate: string;
  reports: AddReportResult[];
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { sortingProps, paginationProps } = useDataGrid({
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const date = dayjs(effectiveDate, 'YYYY-MM-DD').format('DD/MM/YYYY');

  const reportsToList = reports
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={1} alignItems="center">
          <IconCircleCheckFilled size={20} color={theme.palette.success.main} />
          <Typography variant="body1" fontWeight="700">
            Alterações efetivadas
          </Typography>
        </Box>
        <IconButton onClick={() => setExpanded((expanded) => !expanded)}>
          {expanded ? (
            <IconChevronUp size={20} />
          ) : (
            <IconChevronDown size={20} />
          )}
        </IconButton>
      </Box>
      {expanded ? (
        <DataGrid
          sortingProps={sortingProps}
          paginationProps={paginationProps}
          totalRowCount={reports.length}
          getRowId={(row) => row.reportContractId}
          rows={reportsToList}
          getRowSx={() => ({ height: '56px' })}
          columns={[
            {
              field: 'worker',
              headerName: 'Colaborador',
              sortable: false,
              valueGetter: (params: GridValueGetterParams) => {
                return params.row.reportName;
              },
              renderCell: ({ value }) => {
                return (
                  <ExpandableTypography
                    fontWeight="500"
                    sx={{ maxWidth: '200px' }}
                  >
                    {value}
                  </ExpandableTypography>
                );
              },
            },
            {
              field: 'oldManager',
              headerName: 'Gestor atual',
              sortable: false,
              valueGetter: (params: GridValueGetterParams) => {
                return params.row.oldManagerName;
              },
              renderCell: ({ value }) => {
                return value ? (
                  <ExpandableTypography
                    fontWeight="500"
                    sx={{ maxWidth: '190px' }}
                  >
                    {value}
                  </ExpandableTypography>
                ) : (
                  <ExpandableTypography fontWeight="400">
                    Sem gestor
                  </ExpandableTypography>
                );
              },
            },
            {
              field: 'newManager',
              headerName: 'Novo gestor',
              sortable: false,
              valueGetter: () => newManager.br.pessoa.nmTrab,
              renderCell: ({ value }) => {
                return (
                  <ExpandableTypography sx={{ maxWidth: '190px' }}>
                    {value}
                  </ExpandableTypography>
                );
              },
            },
            {
              field: 'effectiveDate',
              headerName: 'Início da vigência',
              sortable: false,
              valueGetter: () => date,
              renderCell: ({ value }) => {
                return <ExpandableTypography>{value}</ExpandableTypography>;
              },
            },
          ]}
        />
      ) : (
        <Divider />
      )}
    </Box>
  );
}

function FailedToAddReportsTable({ reports }: { reports: AddReportResult[] }) {
  const theme = useTheme();
  const { sortingProps, paginationProps } = useDataGrid({
    pagination: {
      rowsPerPageOptions: [5, 25, 50, 100],
    },
  });

  const reportsToList = reports
    .slice(paginationProps.page * paginationProps.rowsPerPage)
    .slice(0, paginationProps.rowsPerPage);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={1} alignItems="center">
        <IconAlertTriangleFilled size={20} color={theme.palette.error.light} />
        <Typography variant="body1" fontWeight="700">
          Alterações não concluídas
        </Typography>
      </Box>
      <Box>
        <Alert severity="error">
          Alguns colaboradores não foram migrados para a nova gestão. Veja
          abaixo os detalhes do erro. Enquanto isso, eles permanecem na gestão
          atual.
        </Alert>
      </Box>
      <Box>
        <DataGrid
          sortingProps={sortingProps}
          paginationProps={paginationProps}
          totalRowCount={reports.length}
          getRowId={(row) => row.reportContractId}
          rows={reportsToList}
          getRowSx={() => ({ minHeight: '56px', height: 'auto' })}
          columns={[
            {
              field: 'worker',
              headerName: 'Colaborador',
              sortable: false,
              valueGetter: (params: GridValueGetterParams) => {
                return params.row.reportName;
              },
              renderCell: ({ value }) => {
                return (
                  <ExpandableTypography
                    fontWeight="500"
                    sx={{ maxWidth: '210px' }}
                  >
                    {value}
                  </ExpandableTypography>
                );
              },
            },
            {
              field: 'oldManager',
              headerName: 'Gestor atual',
              sortable: false,
              valueGetter: (params: GridValueGetterParams) => {
                return params.row.oldManagerName ?? 'Sem gestor';
              },
              renderCell: ({ value }) => {
                return (
                  <ExpandableTypography
                    fontWeight="500"
                    sx={{ maxWidth: '180px' }}
                  >
                    {value}
                  </ExpandableTypography>
                );
              },
            },
            {
              field: 'newManager',
              headerName: 'Novo gestor',
              sortable: false,
              valueGetter: () => 'Inalterado',
              renderCell: ({ value }) => {
                return <ExpandableTypography>{value}</ExpandableTypography>;
              },
            },
            {
              field: 'error',
              headerName: 'Motivo da falha',
              sortable: false,
              valueGetter: (params: GridValueGetterParams) => params.row.error,
              renderCell: ({ value }) => {
                return (
                  <Typography variant="body2" maxWidth="220px">
                    {value}
                  </Typography>
                );
              },
            },
          ]}
        />
      </Box>
    </Box>
  );
}
