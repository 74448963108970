import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import {
  CompanyList,
  ContractList,
  JobTitleEntry,
  JobTitleList,
  JobTitleSummary,
  fetchGetAllJobTitles,
  useGetAllCompanies,
  useGetAllJobTitles,
} from '@octopus/api';
import {
  DataGrid,
  DataGridProps,
  DataGridToolbar,
  GridColDef,
  GridValueGetterParams,
  useDataGrid,
} from '@octopus/ui/data-grid';

import { ExpandableTypography } from '../../modules/components/ExpandableTypography';
import { CreateJobTitleDrawer } from '../../modules/components/jobTitles/CreateJobTitleDrawer';
import { JobTitleDetailsDrawer } from '../../modules/components/jobTitles/JobTitleDetailsDrawer';
import { ArrowNavigation } from '../../modules/components/Navigator';
import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTabs } from '../../modules/components/page/PageTabs';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { SnackbarType } from '../../modules/hooks/snackbarContext';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { QueryResult } from '../../modules/types';
import { pollUntil } from '../../utils';

export type JobTitlesContentProps = {
  organizationId: string;
  contractsQuery: Omit<QueryResult<ContractList>, 'refetch' | 'isFetching'>;
};

const jobTitleStatuses = {
  active: 'active',
  deactivated: 'deactivated',
} as const;

type JobTitleStatus = keyof typeof jobTitleStatuses;

export function JobTitlesContent({
  organizationId,
  contractsQuery,
}: JobTitlesContentProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const [showJobTitleCreation, setShowJobTitleCreation] = useState(false);
  const [isLoadingNewData, setIsLoadingNewData] = useState(false);

  const [selectedJobTitle, setSelectedJobTitle] = useState<
    JobTitleSummary | undefined
  >();

  const [jobTitlesStatus, setJobTitlesStatus] = useState<JobTitleStatus>(
    jobTitleStatuses.active,
  );

  const prepareQueryParams = (
    datagridProps: DataGridProps,
    active: boolean,
  ) => ({
    page: `${datagridProps.paginationProps.page}`,
    size: `${datagridProps.paginationProps.rowsPerPage}`,
    elementFilters: JSON.stringify({
      active: [`${active}`],
    }),
    sortField: datagridProps.sortingProps.field,
    sortOrder: datagridProps.sortingProps.order,
    ...(searchTerm.length > 0 && {
      query: searchTerm,
    }),
  });

  const activeJobTitlesDatagridProps = useDataGrid({
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const activeJobTitlesQuery = useGetAllJobTitles(
    {
      pathParams: {
        organizationId,
      },
      queryParams: prepareQueryParams(activeJobTitlesDatagridProps, true),
    },
    {
      enabled: !!organizationId,
    },
  );
  const inactiveJobTitlesDatagridProps = useDataGrid({
    sorting: {
      field: 'name',
      order: 'asc',
    },
  });
  const inactiveJobTitlesQuery = useGetAllJobTitles(
    {
      pathParams: {
        organizationId,
      },
      queryParams: prepareQueryParams(inactiveJobTitlesDatagridProps, false),
    },
    {
      enabled: !!organizationId,
    },
  );
  const companiesQuery = useGetAllCompanies({
    pathParams: {
      organizationId,
    },
    queryParams: {
      size: '100',
    },
  });

  const datagridProps =
    jobTitlesStatus === jobTitleStatuses.active
      ? activeJobTitlesDatagridProps
      : inactiveJobTitlesDatagridProps;
  const jobTitlesQuery =
    jobTitlesStatus === jobTitleStatuses.active
      ? activeJobTitlesQuery
      : inactiveJobTitlesQuery;
  const maxCode = (jobTitlesQuery.data as any)?.metadata?.max?.code as
    | number
    | undefined;

  const refetch = async () => {
    await Promise.all([
      activeJobTitlesQuery.refetch(),
      inactiveJobTitlesQuery.refetch(),
    ]);
  };

  return (
    <PageContainer>
      <PageTitle title="Cargos" />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        mb={2}
        mt={1}
      >
        <DataGridToolbar
          searchPlaceholder="Procurar"
          hideFilters={true}
          filters={[]}
          searchProps={{ searchTerm, setSearchTerm }}
          filteringProps={datagridProps.filteringProps}
        />
        <Button
          size="large"
          color="primaryAlt"
          onClick={() => setShowJobTitleCreation(true)}
        >
          Novo cargo
        </Button>
      </Box>
      <StatusTabs
        jobTitlesStatus={jobTitlesStatus}
        setJobTitlesStatus={setJobTitlesStatus}
        activeJobTitlesQuery={activeJobTitlesQuery}
        inactiveJobTitlesQuery={inactiveJobTitlesQuery}
      />
      <Table
        datagridProps={datagridProps}
        isLoading={jobTitlesQuery.isLoading || isLoadingNewData}
        jobTitlesQuery={jobTitlesQuery}
        contractsByJobTitle={
          contractsQuery?.data?.metadata?.buckets?.counters?.byProp?.title
        }
        onRowClick={(row) => {
          setSelectedJobTitle(row);
        }}
      />
      <Creation
        organizationId={organizationId}
        companies={companiesQuery.data}
        maxCode={maxCode}
        open={showJobTitleCreation}
        onClose={() => setShowJobTitleCreation(false)}
        setLoading={setIsLoadingNewData}
        refetch={refetch}
      />
      <Details
        organizationId={organizationId}
        companies={companiesQuery.data}
        selectedJobTitle={selectedJobTitle}
        setSelectedJobTitle={setSelectedJobTitle}
        setLoading={setIsLoadingNewData}
        jobTitles={jobTitlesQuery.data}
        refetch={refetch}
      />
    </PageContainer>
  );
}

function StatusTabs({
  jobTitlesStatus,
  setJobTitlesStatus,
  activeJobTitlesQuery,
  inactiveJobTitlesQuery,
}: {
  jobTitlesStatus: JobTitleStatus;
  setJobTitlesStatus: (status: JobTitleStatus) => void;
  activeJobTitlesQuery: QueryResult<JobTitleList>;
  inactiveJobTitlesQuery: QueryResult<JobTitleList>;
}) {
  useEffect(() => {
    if (
      inactiveJobTitlesQuery.data?.total === 0 &&
      jobTitlesStatus === jobTitleStatuses.deactivated
    ) {
      setJobTitlesStatus(jobTitleStatuses.active);
    }
  }, [inactiveJobTitlesQuery.data?.total, jobTitlesStatus]);

  const TabLabel = ({
    isSelected,
    label,
    count,
  }: {
    isSelected: boolean;
    label: string;
    count: number | undefined;
  }) => {
    const fontWeight = isSelected ? 700 : 500;
    const color = isSelected ? 'primary.main' : 'text.secondary';

    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <Typography variant="body1" fontWeight={fontWeight} color={color}>
          {label}
        </Typography>
        {count !== undefined && count > 0 && (
          <Box
            paddingY={0.25}
            paddingX={1}
            alignItems={'center'}
            borderRadius={2}
            bgcolor={'strokes.secondary'}
            height={'20px'}
          >
            <Typography variant="caption" fontWeight={fontWeight} color={color}>
              {count}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <PageTabs>
      <Tabs
        value={jobTitlesStatus}
        onChange={(_, newTab) => setJobTitlesStatus(newTab)}
        textColor="inherit"
        TabIndicatorProps={{}}
        data-testid="jobTitle-contractType-tabs"
      >
        <Tab
          key={jobTitleStatuses.active}
          value={jobTitleStatuses.active}
          icon={
            <TabLabel
              isSelected={jobTitlesStatus === jobTitleStatuses.active}
              label={'Ativos'}
              count={activeJobTitlesQuery.data?.total}
            />
          }
        />
        <Tab
          sx={{
            ...(!inactiveJobTitlesQuery.isFetching &&
              !inactiveJobTitlesQuery.data?.total && { display: 'none' }),
          }}
          key={jobTitleStatuses.deactivated}
          value={jobTitleStatuses.deactivated}
          icon={
            <TabLabel
              isSelected={jobTitlesStatus === jobTitleStatuses.deactivated}
              label={'Desativados'}
              count={inactiveJobTitlesQuery.data?.total}
            />
          }
        />
      </Tabs>
    </PageTabs>
  );
}

function Table({
  datagridProps,
  isLoading,
  jobTitlesQuery,
  contractsByJobTitle,
  onRowClick,
}: {
  datagridProps: DataGridProps;
  isLoading: boolean;
  jobTitlesQuery: QueryResult<JobTitleList>;
  contractsByJobTitle: Record<string, number> | undefined;
  onRowClick: (row: JobTitleSummary) => void;
}) {
  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" gap="8px" pt={1}>
        <Skeleton
          variant="rounded"
          height={45 + datagridProps.paginationProps.rowsPerPage * 58}
          width="100%"
        />
      </Box>
    );
  }

  const columns: GridColDef<JobTitleSummary>[] = [
    {
      field: 'code',
      headerName: 'Código',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.code;
      },
      renderCell: ({ value }) => {
        return <Typography variant="body2">{value}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Cargo',
      sortable: true,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.name;
      },
      renderCell: ({ value }) => {
        return (
          <ExpandableTypography
            expandTextOnHover
            variant="body2"
            sx={{ '--ExpandableTypography-max-width': '20em' }}
          >
            {value}
          </ExpandableTypography>
        );
      },
    },
    {
      field: 'contractTypes',
      headerName: 'Atribuição',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.contractTypes;
      },
      renderCell: ({ value }) => {
        let content;
        if (!value || (value.includes('br:clt') && value.includes('br:pj'))) {
          content = 'Todos';
        } else {
          if (value.includes('br:clt')) {
            content = 'Colaborador';
          } else {
            content = 'Prestador de serviço';
          }
        }
        return <Typography variant="body2">{content}</Typography>;
      },
    },
    {
      field: 'jobTitleCount',
      headerName: 'Contratos',
      sortable: false,
      valueGetter: (params: GridValueGetterParams<JobTitleSummary>) => {
        return params.row.jobTitleId;
      },
      renderCell: ({ value }) => {
        if (!contractsByJobTitle) {
          return <Skeleton variant="text" width="50px" />;
        }
        return (
          <Typography variant="body2">
            {contractsByJobTitle[value] ?? 0}
          </Typography>
        );
      },
    },
  ];

  return (
    <DataGrid
      getRowSx={() => ({
        height: '56px',
      })}
      sortingProps={datagridProps.sortingProps}
      paginationProps={datagridProps.paginationProps}
      totalRowCount={jobTitlesQuery.data.total || 0}
      getRowId={(row) => row.jobTitleId}
      rows={jobTitlesQuery.data.data}
      columns={columns}
      onRowClick={({ row }) => onRowClick(row)}
    />
  );
}

function Creation({
  organizationId,
  companies,
  maxCode,
  open,
  onClose,
  setLoading,
  refetch,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  maxCode: number | undefined;
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  refetch: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();

  const onSuccess = async ({ jobTitleId }: JobTitleEntry) =>
    handleUpdatesToList({
      organizationId,
      jobTitleId,
      setLoading,
      closeDrawer: onClose,
      showSnackbar,
      snackbarMessage: 'Cargo criado',
      refetch,
    });

  return (
    <CreateJobTitleDrawer
      organizationId={organizationId}
      companies={companies}
      maxCode={maxCode}
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}

export function Details({
  organizationId,
  companies,
  selectedJobTitle,
  setSelectedJobTitle,
  setLoading,
  jobTitles,
  refetch,
}: {
  organizationId: string;
  companies: CompanyList | undefined;
  selectedJobTitle: JobTitleSummary | undefined;
  setSelectedJobTitle: (jobTitle: JobTitleSummary | undefined) => void;
  setLoading: (loading: boolean) => void;
  jobTitles: JobTitleList | undefined;
  refetch: () => Promise<void>;
}) {
  const { showSnackbar } = useSnackbar();
  const [detailsNavigation, setDetailsNavigation] = useState<ArrowNavigation>({
    canGoForward: false,
    canGoBackwards: false,
    goForward: undefined,
    goBackwards: undefined,
  });

  useEffect(() => {
    if (selectedJobTitle !== undefined) {
      const currentIndex = jobTitles?.data?.findIndex(
        ({ jobTitleId }) => selectedJobTitle.jobTitleId === jobTitleId,
      );
      if (currentIndex !== undefined) {
        setDetailsNavigation({
          canGoForward: currentIndex < jobTitles?.data?.length - 1,
          canGoBackwards: currentIndex > 0,
          goForward: () =>
            setSelectedJobTitle(jobTitles?.data[currentIndex + 1]),
          goBackwards: () =>
            setSelectedJobTitle(jobTitles?.data[currentIndex - 1]),
        });
        return;
      }
    }
    setDetailsNavigation({
      canGoForward: false,
      canGoBackwards: false,
      goForward: undefined,
      goBackwards: undefined,
    });
  }, [selectedJobTitle]);

  const handleUpdates = async (
    jobTitleId: string,
    message: string,
    filters: Record<string, string[]>,
  ) =>
    handleUpdatesToList({
      organizationId,
      jobTitleId,
      setLoading,
      closeDrawer: () => setSelectedJobTitle(undefined),
      showSnackbar,
      snackbarMessage: message,
      filters,
      refetch,
    });

  const onEdit = (jobTitle: JobTitleEntry) =>
    handleUpdates(jobTitle.jobTitleId, 'Cargo atualizado', {
      version: [`${jobTitle.version}`],
    });

  const onRestore = (jobTitleId: string) =>
    handleUpdates(jobTitleId, 'Cargo reativado', {
      jobTitleId: [jobTitleId],
      active: ['true'],
    });

  const onArchive = (jobTitleId: string) =>
    handleUpdates(jobTitleId, 'Cargo desativado', {
      jobTitleId: [jobTitleId],
      active: ['false'],
    });

  return (
    <JobTitleDetailsDrawer
      organizationId={organizationId}
      companies={companies}
      jobTitleSummary={selectedJobTitle}
      navigation={detailsNavigation}
      open={selectedJobTitle !== undefined}
      onEdit={onEdit}
      onRestore={onRestore}
      onArchive={onArchive}
      onClose={() => setSelectedJobTitle(undefined)}
    />
  );
}

async function handleUpdatesToList({
  organizationId,
  jobTitleId,
  setLoading,
  closeDrawer,
  showSnackbar,
  snackbarMessage,
  filters,
  refetch,
}: {
  organizationId: string;
  jobTitleId: string;
  setLoading: (loading: boolean) => void;
  closeDrawer: () => void;
  showSnackbar: (snackbar: SnackbarType) => void;
  snackbarMessage: string;
  filters?: Record<string, string[]>;
  refetch: () => Promise<unknown>;
}) {
  setLoading(true);
  closeDrawer();
  showSnackbar({
    isOpen: true,
    variant: 'default',
    Message: snackbarMessage,
    StartAdornment: <CheckCircleIcon />,
    hasCloseAction: true,
  });
  try {
    await pollUntil({
      action: () =>
        fetchGetAllJobTitles({
          pathParams: {
            organizationId,
          },
          queryParams: {
            elementFilters: JSON.stringify({
              jobTitleId: [jobTitleId],
              ...(filters ?? {}),
            }),
          },
        }),
      assertion: (list) => list.data.length === 1,
      intervalMillis: 500,
      timeoutSeconds: 5,
    });
  } catch (_) {
    console.warn('Failed to poll updates to list');
  }
  await refetch();
  setLoading(false);
}
