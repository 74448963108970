import { Route, Routes, useNavigate } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { setAuthToken } from '@octopus/api';
import { ThemeWrapper } from '@octopus/ui/design-system';

import { AppContextProvider } from './context';
import { FFlagsProvider } from './fflags';
import { Home } from './home';
import {
  ForgotPassword,
  ForgotPasswordSent,
  Login,
  Logout,
  NewPassword,
  NewPasswordSuccess,
  Signup,
  TermsOfService,
  UserCredentials,
} from './login';
import { PrivacyPolicy } from './login/privacy-policy/page';
import { SnackbarProvider } from './snackbarProvider';

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();

  const onLoginSuccess = (token: string) => {
    setAuthToken(token);
    navigate('/');
  };

  return (
    <ThemeWrapper>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <Routes>
            <Route path="/login" element={<Login onSuccess={onLoginSuccess} />}>
              <Route path="" element={<UserCredentials />} />
              <Route path="sso" element={<UserCredentials ssoOnly={true} />} />
              <Route path="signup" element={<Signup />} />
              <Route path="terms-of-service" element={<TermsOfService />} />
              <Route path="new-password" element={<NewPassword />} />
              <Route
                path="new-password/success"
                element={<NewPasswordSuccess />}
              />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route
                path="forgot-password/sent"
                element={<ForgotPasswordSent />}
              />
              <Route path="logout" element={<Logout />} />
            </Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="*"
              element={
                <AppContextProvider>
                  <FFlagsProvider>
                    <Home />
                  </FFlagsProvider>
                </AppContextProvider>
              }
            />
          </Routes>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeWrapper>
  );
}

export default App;
