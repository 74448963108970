import React, { useState } from 'react';

import { IconUserMinus } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { ContractEntry, useTerminateContract } from '@octopus/api';
import { contractStatuses } from '@octopus/contract-types';
import { formatDateBR } from '@octopus/formatters';
import { Button } from '@octopus/ui/design-system';

import { ErrorLabel } from '../../../modules/form/Field/commons/ErrorLabel';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';

import { Utils } from './history/utils';

export function TerminateButton({
  contract,
  setOpen,
  refetchContract,
}: {
  contract: ContractEntry;
  setOpen(open: boolean): void;
  refetchContract: () => void;
}) {
  const [terminationStarted, setTerminationStarted] = useState(false);
  const [step, setStep] = useState(1);
  const [effectiveDate, setEffectiveDate] = useState<string>(undefined);
  const [dateError, setDateError] = useState('');
  const { showSnackbar } = useSnackbar();

  const handleOpen = () => {
    setTerminationStarted(true);
    setStep(1);
  };

  const handleClose = () => {
    setOpen(false);
    setTerminationStarted(false);
    setDateError('');
  };

  const handleNextStep = async () => {
    if (step === 1 && !effectiveDate) {
      setDateError('Data de desligamento é necessária para seguir.');
      return;
    }
    if (step < 2) {
      setDateError('');
      setStep(step + 1);
    } else {
      await handleConfirmFinal();
    }
  };

  const isFutureEffectiveDate = effectiveDate > dayjs().format('YYYY-MM-DD');
  const { hoveredStyle, hoverEventHandlers } = Utils.Hooks.useHoverBackground();
  const isDisabled = contract.status === contractStatuses.terminated;

  const terminateMutation = useTerminateContract({
    onSuccess: () => {
      refetchContract();
      setTerminationStarted(false);

      if (!isFutureEffectiveDate) {
        showSnackbar({
          isOpen: true,
          Message:
            contract.contractType === 'br:clt'
              ? 'Colaborador(a) desligado(a).'
              : 'Contrato inativado.',
          variant: 'default',
          autoHideDuration: 3000,
        });
      } else {
        showSnackbar({
          isOpen: true,
          Message: 'Desligamento agendado.',
          variant: 'default',
          autoHideDuration: 3000,
        });
      }
    },

    onError: () => {
      showSnackbar({
        isOpen: true,
        Message: 'Ocorreu um erro ao desligar o colaborador.',
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const handleConfirmFinal = async () => {
    await terminateMutation.mutateAsync({
      pathParams: {
        contractId: contract.contractId,
        organizationId: contract.organizationId,
      },
      body: {
        contractType: contract.contractType,
        terminationDate: effectiveDate,
      },
      headers: {
        'x-idempotency-key': `${v4()}`,
      },
    });
  };

  const firstStep = () => {
    return (
      <Box>
        <Box pb={2}>
          <Typography fontSize={24} color={'text.primary'} fontWeight={700}>
            Desligamento
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" color="text.secondary">
          <Typography fontSize={12} lineHeight={1.5} pb={1}>
            Data de desligamento
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              sx={{ width: '100%' }}
              slotProps={{
                textField: {
                  placeholder: 'Selecione',
                  error: !!dateError,
                },
              }}
              onChange={(v: string) =>
                setEffectiveDate(dayjs(v).format('YYYY-MM-DD'))
              }
            />
            <ErrorLabel errorMessage={dateError} />
          </LocalizationProvider>
        </Box>
      </Box>
    );
  };

  const secondStep = () => {
    return (
      <Box>
        <Box>
          <Typography
            fontSize={24}
            color={'text.primary'}
            fontWeight={700}
            pb={2}
          >
            Atenção
          </Typography>
          {contract.contractType === 'br:clt' ? (
            <>
              <Typography
                variant={'body2'}
                color={'error'}
                fontSize={14}
                lineHeight={1.5}
              >
                Você está mudando a situação do colaborador para{' '}
                <Typography
                  fontWeight={700}
                  color={'error'}
                  fontSize={14}
                  display="inline"
                >
                  desligado
                </Typography>
                , o que acontece em seguida:
              </Typography>
              <Box display={'flex'}>
                <ul>
                  <li>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography
                        lineHeight={'24px'}
                        fontSize={16}
                        variant={'body1'}
                      >
                        Deixe com a Tako
                      </Typography>
                      <Typography
                        variant={'caption'}
                        color={'text.secondary'}
                        fontSize={12}
                        letterSpacing={0.2}
                      >
                        O time de suporte da Tako dará sequência aos cálculos
                        rescisórios. Em breve, entraremos em contato para
                        coletar mais informações e concluir o processo de
                        desligamento.
                      </Typography>
                    </Box>
                  </li>
                  <Box pt={1}></Box>
                  <li>
                    <Box display={'flex'} flexDirection={'column'} pt={1}>
                      <Typography
                        lineHeight={'24px'}
                        fontSize={16}
                        variant={'body1'}
                      >
                        Situação muda para desligado
                      </Typography>
                      {isFutureEffectiveDate ? (
                        <>
                          <Typography
                            fontSize={12}
                            letterSpacing={0.2}
                            variant={'caption'}
                            color={'text.secondary'}
                          >
                            A situação deste colaborador vai mudar para
                            Desligado(a) na data indicada
                          </Typography>
                          <Typography
                            variant={'caption'}
                            color={'text.secondary'}
                            fontSize={12}
                            letterSpacing={0.2}
                            fontWeight={700}
                          >
                            às 12:00 horas.
                          </Typography>
                        </>
                      ) : (
                        <Typography
                          fontSize={12}
                          letterSpacing={0.2}
                          variant={'caption'}
                          color={'text.secondary'}
                        >
                          A situação deste colaborador vai mudar para
                          Desligado(a).
                        </Typography>
                      )}
                    </Box>
                  </li>
                </ul>
              </Box>
            </>
          ) : (
            <>
              <Typography color={'error'} fontSize={14} lineHeight={1.5}>
                Você está mudando a situação do prestador de serviços para{' '}
                <Typography
                  fontWeight={700}
                  color={'error'}
                  fontSize={14}
                  display="inline"
                >
                  inativo
                </Typography>
                , o que acontece em seguida:
              </Typography>
              <Box display={'flex'}>
                <ul>
                  <li>
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography
                        lineHeight={'24px'}
                        fontSize={16}
                        variant={'body1'}
                      >
                        Prestador perde acesso
                      </Typography>
                      <Typography
                        fontSize={12}
                        letterSpacing={0.2}
                        variant={'caption'}
                        color={'text.secondary'}
                      >
                        A plataforma bloqueia o acesso do prestador a
                        informações confidenciais a partir do dia{' '}
                        {formatDateBR(effectiveDate)}, mas não impede a consulta
                        de notas e recibos, disponíveis através do e-mail
                        pessoal.
                      </Typography>
                    </Box>
                  </li>
                </ul>
              </Box>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const steps: Record<number, React.ReactElement> = {
    1: firstStep(),
    2: secondStep(),
  };

  return (
    <>
      <Box
        display="flex"
        alignItems={'center'}
        gap={1.5}
        sx={(theme) => {
          const baseSx = {
            width: '139px',
            height: '36px',
            px: 2,
            boxSizing: 'border-box',
          };
          return !isDisabled
            ? {
                ...baseSx,
                ...hoveredStyle(theme),
              }
            : baseSx;
        }}
        {...hoverEventHandlers}
      >
        <IconUserMinus
          width={'24px'}
          height={'24px'}
          color={isDisabled ? '#EDEDED' : '#BC1A41'}
        />
        <Link
          component="button"
          underline="none"
          textAlign="left"
          disabled={isDisabled}
          onClick={(event) => {
            event.stopPropagation();
            handleOpen();
          }}
        >
          <Typography
            variant="body2"
            color={isDisabled ? 'strokes.light' : 'error.lowEmphasis'}
            width={'100%'}
            fontSize={16}
            lineHeight={'24px'}
          >
            Desligar
          </Typography>
        </Link>
      </Box>

      <Dialog open={terminationStarted} onClose={handleClose}>
        <Box width={600}>
          <DialogContent>{steps[step]}</DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variantSemantic="secondary"
              sx={{ width: '96px', height: '40px', px: 2, py: 1 }}
            >
              Cancelar
            </Button>
            <LoadingButton
              onClick={handleNextStep}
              variant="contained"
              color="primaryAlt"
              loading={terminateMutation.isLoading}
              sx={{ width: '96px', height: '40px', px: 2, py: 1 }}
            >
              Continuar
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
