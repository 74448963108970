export enum TemplateCategory {
  admission = 'Admissão',
  vacations = 'Férias',
  termination = 'Rescisão',
}

export type Template = {
  id: string;
  nome: string;
  categoria: TemplateCategory;
  estatico: boolean;
};
