import React from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Drawer, Tab, Tabs, Typography } from '@mui/material';

import { CompanyList, JobTitleEntry, JobTitleSummary } from '@octopus/api';

import { ArrowNavigation, Navigator } from '../../Navigator';
import { PageTabs } from '../../page/PageTabs';

import { JobTitle } from './JobTitle';

export type DetailsDrawerProps = {
  organizationId: string;
  companies: CompanyList | undefined;
  jobTitleSummary: JobTitleSummary | undefined;
  navigation: ArrowNavigation;
  open: boolean;
  onEdit: (updatedEntry: JobTitleEntry) => void;
  onArchive: (jobTitleId: string) => void;
  onRestore: (jobTitleId: string) => void;
  onClose: () => void;
};

const detailsContents = {
  jobTitle: 'jobTitle',
} as const;

type DetailsContent = keyof typeof detailsContents;

export function DetailsDrawer({
  organizationId,
  companies,
  jobTitleSummary,
  navigation,
  open,
  onEdit,
  onArchive,
  onRestore,
  onClose,
}: DetailsDrawerProps) {
  const [detailsContent, setDetailsContent] = React.useState<DetailsContent>(
    detailsContents.jobTitle,
  );

  if (jobTitleSummary === undefined) {
    return null;
  }

  const content = (() => {
    switch (detailsContent) {
      case detailsContents.jobTitle:
        return (
          <JobTitle
            organizationId={organizationId}
            jobTitleId={jobTitleSummary.jobTitleId}
            companies={companies}
            onEdit={onEdit}
            onArchive={onArchive}
            onRestore={onRestore}
          />
        );
    }
  })();

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <Box
        display="flex"
        flexDirection="column"
        pt={5}
        pb={4}
        px={7}
        height="100%"
        width="700px"
        boxSizing="border-box"
        gap={3}
        sx={{
          overflowY: 'overlay',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h6" maxWidth="440px">
            {jobTitleSummary.name}
          </Typography>
          <Box display="flex" gap={1.5}>
            <Navigator
              arrowNavigation={navigation}
              arrowsDirection="vertical"
              iconSize="medium"
            />
            <Button
              color="secondary"
              size="small"
              onClick={onClose}
              sx={[
                () => ({
                  borderColor: 'transparent',
                  borderRadius: '50%',
                  minWidth: 32,
                  minHeight: 32,
                  height: 32,
                  width: 32,
                }),
              ]}
            >
              <Close sx={{ fontSize: '24px' }} />
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" height="100%" width="100%">
          <ContentTabs
            detailsContent={detailsContent}
            setDetailsContent={setDetailsContent}
          />
          {content}
        </Box>
      </Box>
    </Drawer>
  );
}

function ContentTabs({
  detailsContent,
  setDetailsContent,
}: {
  detailsContent: DetailsContent;
  setDetailsContent: (status: DetailsContent) => void;
}) {
  const TabLabel = ({
    isSelected,
    label,
  }: {
    isSelected: boolean;
    label: string;
  }) => {
    const fontWeight = isSelected ? 500 : 500;
    const color = isSelected ? 'primary.main' : 'text.secondary';

    return (
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <Typography variant="body2" fontWeight={fontWeight} color={color}>
          {label}
        </Typography>
      </Box>
    );
  };

  return (
    <PageTabs>
      <Tabs
        value={detailsContent}
        onChange={(_, newTab) => setDetailsContent(newTab)}
        textColor="inherit"
        TabIndicatorProps={{}}
        data-testid="detail-content-tabs"
      >
        <Tab
          key={detailsContents.jobTitle}
          value={detailsContents.jobTitle}
          icon={
            <TabLabel
              isSelected={detailsContent === detailsContents.jobTitle}
              label={'Detalhes'}
            />
          }
        />
      </Tabs>
    </PageTabs>
  );
}
