import React, { useState } from 'react';

import { IconUserPlus } from '@tabler/icons-react';

import { Box } from '@mui/material';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTitle } from '../../modules/components/page/PageTitle';
import { useFFlags } from '../fflags';

import { AdmissionCount } from './AdmissionCount';
import { AdmissionTable } from './AdmissionTable';

export type AdmissionProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

export default function Admission({ organizationId }: AdmissionProps) {
  const [showAdmitted, setShowAdmitted] = useState(true);
  const { fflags } = useFFlags();
  const pjEnabled = fflags.pjAdmissionEnabled.enabled;

  return (
    <PageContainer>
      <PageTitle
        dataTestId="admission-header"
        title="Admissão"
        icon={IconUserPlus}
      />

      {pjEnabled && <AdmissionCount organizationId={organizationId} />}

      <Box py={3.2}>
        <AdmissionTable
          organizationId={organizationId}
          setShowAdmitted={setShowAdmitted}
          showAdmitted={showAdmitted}
        />
      </Box>
    </PageContainer>
  );
}
