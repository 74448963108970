import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';
import { padStart } from 'lodash';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';

import {
  ContractBRPjEntry,
  ContractEntry,
  JobTitleEntry,
  PjPaymentAttachmentAllowedContentTypes,
  PjPaymentAttachmentEntry,
  PjPaymentRequestEntry,
  fetchApprovePaymentRequest,
  fetchDeletePaymentRequestAttachment,
  fetchGetAllPaymentRequestsAttachment,
  fetchGetJobTitleEntry,
  fetchGetPaymentRequest,
  fetchGetPaymentRequestAttachment,
  fetchPostPaymentRequestsAttachment,
  fetchRejectPaymentRequest,
  fetchRemoveApprovalPaymentRequest,
  useGetContract,
} from '@octopus/api';
import {
  getContractJobTitleId,
  getWorkerId,
  isBRPjContract,
} from '@octopus/contract-types';
import {
  formatDateBR,
  formatDateTimeBR,
  formatMoney,
  formatPeriodDate,
} from '@octopus/formatters';
import {
  attachmentStatuses,
  paymentRequestStatuses,
} from '@octopus/pj-payment-types';
import { Tag } from '@octopus/ui/design-system';

import { uploadFile } from '../../../modules/components/file/upload';
import {
  ErrorDialog,
  LoadingDialog,
  RejectionDialog,
  RemoveApprovalDialog,
} from '../../../modules/components/pj/dialogs';
import {
  AttachmentInfo,
  LucroPresumidoAmounts,
  SimplesNacionalAmounts,
  StateInformation,
  poolOpenSearch,
} from '../../../modules/components/pj/pjComponents';
import UserAvatar from '../../../modules/components/UserAvatar';
import { DataFetching } from '../../../modules/dataFetching';
import { QueryResult } from '../../../modules/types';

import { FileComponent } from './fileComponents';

export function PaymentRequestPage({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const { paymentRequestId } = useParams<{
    paymentRequestId: string;
  }>();

  const [file, setFile] = useState(undefined as File);
  const [attachment, setAttachment] = useState(
    undefined as PjPaymentAttachmentEntry,
  );
  const [error, setError] = useState(false);

  const [mutationCounter, setMutationCounter] = useState(0);

  const queryResult = useQuery({
    queryKey: [
      'getPaymentRequest',
      organizationId,
      companyId,
      paymentRequestId,
    ],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const paymentRequest = await fetchGetPaymentRequest({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId,
        },
      });

      const attachments = await fetchGetAllPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId,
        },
      });

      if (!attachments || attachments.length === 0) {
        return { paymentRequest, attachment: undefined };
      }

      const uploadedAttachment = attachments
        .filter(
          (attachment) => attachment.status === attachmentStatuses.uploaded,
        )
        .sort((a, b) => a.createdOn.localeCompare(b.createdOn))
        .at(-1);

      if (!uploadedAttachment) {
        return { paymentRequest, attachment: undefined };
      }

      const attachment = await fetchGetPaymentRequestAttachment({
        pathParams: {
          paymentRequestId,
          organizationId,
          companyId,
          attachmentId: uploadedAttachment.id,
        },
      });

      const fileBlob = await fetch(attachment.downloadUrl).then((response) =>
        response.blob(),
      );

      const file = new File([fileBlob], attachment.fileName, {
        type: attachment.contentType,
      });

      setFile(file);
      setAttachment(attachment);
      return { paymentRequest, attachment };
    },
  });

  const uploadMutation = useMutation(async (file: File) => {
    try {
      const attachmentEntry = await fetchPostPaymentRequestsAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId: paymentRequestId,
        },
        body: {
          type: 'invoice',
          contentType: file.type as PjPaymentAttachmentAllowedContentTypes,
          contentLength: file.size,
          fileName: file.name,
        },
      });

      await uploadFile(attachmentEntry.uploadUrl, file);
      setFile(file);
      setAttachment(attachmentEntry);
      setMutationCounter((prev) => prev + 1);
    } catch (e) {
      setError(true);
      setFile(undefined);
      setAttachment(undefined);
    }
  });

  const deleteMutation = useMutation(async () => {
    try {
      await fetchDeletePaymentRequestAttachment({
        pathParams: {
          organizationId,
          companyId,
          paymentRequestId,
          attachmentId: attachment.id,
        },
      });

      setFile(undefined);
      setAttachment(undefined);
      setMutationCounter((prev) => prev + 1);
    } catch (e) {
      setError(true);
      setFile(undefined);
      setAttachment(undefined);
    }
  });

  useEffect(() => {
    queryResult.refetch();
  }, [queryResult.refetch, mutationCounter]);

  return (
    <>
      <DataFetching
        fetchResult={queryResult}
        Data={({ data }) => {
          return (
            <Box
              display={'flex'}
              width={'100%'}
              height={'100vh'}
              boxSizing={'border-box'}
            >
              <FileComponent
                file={file}
                showDeleteButton={false}
                setFile={(file) => {
                  uploadMutation.mutate(file);
                }}
                isLoading={
                  uploadMutation.isLoading ||
                  deleteMutation.isLoading ||
                  queryResult.isFetching
                }
              />
              <ReviewComponent
                entry={data.paymentRequest}
                attachment={attachment}
                deleteAttachment={deleteMutation.mutateAsync}
                uploadAttachment={uploadMutation.mutateAsync}
                setMutationCounter={setMutationCounter}
                isFileLoading={
                  uploadMutation.isLoading ||
                  deleteMutation.isLoading ||
                  queryResult.isFetching
                }
              />
            </Box>
          );
        }}
        Loading={() => (
          <Box
            display={'flex'}
            width={'100%'}
            height={'100vh'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
        )}
      />
      {error && <ErrorDialog open={error} setOpen={setError} />}
    </>
  );
}

function ReviewComponent({
  entry,
  attachment,
  deleteAttachment,
  uploadAttachment,
  setMutationCounter,
  isFileLoading,
}: {
  entry: PjPaymentRequestEntry;
  attachment: PjPaymentAttachmentEntry;
  deleteAttachment: () => Promise<void>;
  uploadAttachment: (file: File) => Promise<void>;
  setMutationCounter: React.Dispatch<React.SetStateAction<number>>;
  isFileLoading: boolean;
}) {
  const contractQuery = useGetContract(
    {
      pathParams: {
        organizationId: entry.organizationId ?? '',
        contractId: entry.contractId ?? '',
      },
    },
    {
      enabled: !!entry.organizationId && !!entry.contractId,
    },
  );

  const getAmountsComponent = () => {
    if (contractQuery.isLoading) {
      return <CircularProgress />;
    }

    const components = {
      simples: <SimplesNacionalAmounts entry={entry} />,
      lucroPresumido: <LucroPresumidoAmounts entry={entry} />,
    };

    return components[
      (contractQuery.data.br as ContractBRPjEntry).empresa
        .enquadramentoTributario
    ];
  };

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            width: '100%',
            px: 2.5,
            pt: 1,
            mb: 12,
          },
          [theme.breakpoints.up('sm')]: {
            width: '50%',
            pl: 7,
            pr: 4.5,
            pt: 5,
            maxHeight: '950px',
            mb: 12,
          },
        })}
        boxSizing={'border-box'}
        overflow={'auto'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          boxSizing={'border-box'}
        >
          <Header contractQuery={contractQuery} />
          <Box
            display={'flex'}
            pt={2}
            gap={1.5}
            maxHeight={'800px'}
            flexDirection={'column'}
            boxSizing={'border-box'}
          >
            <Box>
              <Typography variant={'h3'} fontSize={'20px'} fontWeight={700}>
                Solicitação de pagamento
              </Typography>
            </Box>
            <Box pt={0.5} pb={0.5}>
              <Divider />
            </Box>
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography
                  variant={'caption'}
                  fontSize={'12px'}
                  color={'text.secondary'}
                  noWrap={true}
                  lineHeight={'16px'}
                >
                  Enviada em {formatDateTimeBR(entry.createdOn)}
                </Typography>

                {entry.status === 'created' && (
                  <Tag color={'default'}>
                    <Typography color={'info.main'} variant={'body2'}>
                      Aguardando aprovação
                    </Typography>
                  </Tag>
                )}
                {entry.status === 'approved' && (
                  <ActionMenu
                    entry={entry}
                    setMutationCounter={setMutationCounter}
                  />
                )}
              </Box>
              <StateInformation entry={entry} />
            </Box>

            <Field
              label={'Número da nota'}
              value={padStart(
                entry.invoice?.number?.toString() ?? '0',
                10,
                '0',
              )}
            />
            <Field
              label={'Competência'}
              value={formatPeriodDate(entry.period)}
            />
            <Field
              label={'Descrição'}
              value={entry.description?.length > 0 ? entry.description : '---'}
            ></Field>
            <Box py={2} display={'flex'} flexDirection={'column'}>
              <AttachmentInfo
                attachment={attachment}
                deleteAttachment={deleteAttachment}
                paymentRequestStatus={entry.status}
                uploadAttachment={uploadAttachment}
                isLoading={isFileLoading}
              />
              {getAmountsComponent()}
            </Box>
          </Box>
        </Box>
      </Box>
      {entry.status === 'created' && <ActionsFooter entry={entry} />}
    </>
  );
}

function ActionsFooter({ entry }: { entry: PjPaymentRequestEntry }) {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const navigate = useNavigate();
  const useFetchApprove = async () => {
    await fetchApprovePaymentRequest({
      pathParams: {
        organizationId: entry.organizationId,
        companyId: entry.companyId,
        paymentRequestId: entry.id,
      },
      body: {
        version: entry.version,
      },
    });

    await poolOpenSearch({
      organizationId: entry.organizationId,
      paymentRequestId: entry.id,
      companyId: entry.companyId,
      contractId: entry.contractId,
      filters: {
        status: [paymentRequestStatuses.approved],
      },
    });

    navigate('/payment-requests');
  };

  const useFetchReject = async () => {
    await fetchRejectPaymentRequest({
      pathParams: {
        organizationId: entry.organizationId,
        companyId: entry.companyId,
        paymentRequestId: entry.id,
      },
      body: {
        version: entry.version,
        reason: rejectionReason,
      },
    });

    await poolOpenSearch({
      organizationId: entry.organizationId,
      paymentRequestId: entry.id,
      companyId: entry.companyId,
      contractId: entry.contractId,
      filters: {
        status: [paymentRequestStatuses.rejected],
      },
    });

    navigate('/payment-requests');
  };

  const mutateOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      if ('statusCode' in e && e.statusCode === 409) {
        setErrorMessage('A solicitação já foi aprovada ou recusada.');
      }

      console.log(e);
      setError(true);
    },
  };

  const rejectMutation = useMutation(useFetchReject, mutateOptions);
  const approveMutation = useMutation(useFetchApprove, mutateOptions);

  return (
    <Box
      aria-description={'footer'}
      display={'flex'}
      justifyContent={'flex-end'}
      bgcolor={'#F7F7F8'}
      px={3}
      py={1}
      gap={1}
      boxSizing={'border-box'}
      border={'1px solid'}
      borderColor={'strokes.light'}
      position={'absolute'}
      width={'50%'}
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          width: '65%',
        },
        [theme.breakpoints.down('md')]: {
          width: '100%',
          px: 2.5,
          pt: 1,
          pb: 8,
          right: 0,
          bottom: 0,
        },
        [theme.breakpoints.up('sm')]: {
          right: 0,
          bottom: 0,
        },
      })}
    >
      <Box display={'flex'} py={1} width={'144px'}>
        <Button
          color="secondary"
          size={'large'}
          fullWidth={true}
          endIcon={rejectMutation.isIdle && <CloseRoundedIcon />}
          disabled={!rejectMutation.isIdle || entry.status !== 'created'}
          onClick={() => {
            setOpenRejectionDialog(true);
          }}
        >
          {!rejectMutation.isIdle ? <CircularProgress /> : 'Recusar'}
        </Button>
      </Box>
      <Box display={'flex'} py={1} width={'144px'}>
        <Button
          color="primaryAlt"
          size={'large'}
          fullWidth={true}
          sx={{
            ':hover': {
              opacity: 0.8,
            },
          }}
          onClick={() => approveMutation.mutate()}
          disabled={!approveMutation.isIdle || entry.status !== 'created'}
          endIcon={approveMutation.isIdle && <CheckOutlinedIcon />}
        >
          {!approveMutation.isIdle ? <CircularProgress /> : 'Aprovar'}
        </Button>
      </Box>
      {error && (
        <ErrorDialog open={error} setOpen={setError} message={errorMessage} />
      )}
      {(!approveMutation.isIdle ||
        !rejectMutation.isIdle ||
        approveMutation.isLoading ||
        rejectMutation.isLoading) && (
        <LoadingDialog
          open={true}
          message={'Realizando ação, aguarde alguns segundos...'}
        />
      )}
      {openRejectionDialog && (
        <RejectionDialog
          open={openRejectionDialog}
          setOpen={setOpenRejectionDialog}
          setRejectionReason={setRejectionReason}
          action={async () => {
            await rejectMutation.mutateAsync();
          }}
        />
      )}
    </Box>
  );
}

function Field({ label, value }: { label: string; value: string | number }) {
  return (
    <Box
      py={2}
      display={'flex'}
      alignItems={'flex-start'}
      alignSelf={'stretch'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Typography variant={'caption'} fontWeight={700}>
          {label}
        </Typography>
        <Typography
          variant={'body2'}
          fontWeight={500}
          fontSize={'14px'}
          color={'text.secondary'}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}

function Header({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const navigate = useNavigate();
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      pb={2}
      gap={1.5}
      justifyContent={'space-between'}
    >
      <Box display={'flex'}>
        <PJContractHighlight contractQuery={contractQuery} />
      </Box>
      <Box display={'flex'} justifyContent={'end'} alignItems={'start'}>
        <IconButton
          onClick={() => {
            navigate('/payment-requests');
          }}
        >
          <CloseRoundedIcon
            sx={{
              width: '32px',
              height: '32px',
              color: '#25252D',
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}

function PJContractHighlight({
  contractQuery,
}: {
  contractQuery: QueryResult<ContractEntry>;
}) {
  const entry = contractQuery.data;

  const jobTitleId = getContractJobTitleId(entry);

  const jobTitleQuery = useQuery({
    queryKey: ['getJobTitleEntry', jobTitleId],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (contractQuery.isLoading) {
        return {} as JobTitleEntry;
      }

      return await fetchGetJobTitleEntry({
        pathParams: {
          organizationId: entry?.organizationId ?? '',
          jobTitleId: jobTitleId,
        },
      });
    },
  });

  if (!isBRPjContract(entry)) {
    return undefined;
  }

  if (contractQuery.isLoading || jobTitleQuery.isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={0.5}>
      <UserAvatar
        name={contractQuery.data?.name ?? ''}
        pictureUrl={contractQuery?.data?.profilePicture?.pictureUrl}
        textProps={{
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '28px',
          variant: 'h3',
        }}
        avatarProps={{
          ml: 0,
        }}
      />
      <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          {jobTitleQuery.data.name} • {entry.br.prestador.posicao.departamento}
        </Typography>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          |
        </Typography>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          {formatMoney(entry.br.pagamento.honorarios.toString())}
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          Início do contrato em {formatDateBR(entry.br.contrato.inicio)}
        </Typography>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          |
        </Typography>
        <Typography
          variant={'caption'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={'text.secondary'}
        >
          Matrícula {getWorkerId(entry)}
        </Typography>
      </Box>
    </Box>
  );
}

function ActionMenu({
  entry: { organizationId, companyId, id, version },
  setMutationCounter,
}: {
  entry: PjPaymentRequestEntry;
  setMutationCounter: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);

  const removeApprovalMutation = useMutation(async () => {
    await fetchRemoveApprovalPaymentRequest({
      pathParams: {
        organizationId,
        companyId,
        paymentRequestId: id,
      },
      body: {
        version: version,
      },
    });

    setMutationCounter((prev) => prev + 1);
  });

  return (
    <Box display="flex" justifyContent="flex-end">
      {!removeApprovalMutation.isIdle && (
        <LoadingDialog
          open={true}
          message={'Realizando ação, aguarde alguns segundos...'}
        />
      )}

      {openDialog && (
        <RemoveApprovalDialog
          open={openDialog}
          setOpen={setOpenDialog}
          action={async () => {
            await removeApprovalMutation.mutateAsync();
          }}
        />
      )}

      <IconButton
        size="small"
        onClick={(event) => {
          setOpen(true);
          event.stopPropagation();
        }}
        ref={menuRef}
        sx={{
          borderRadius: '8px',
          padding: '4px',
        }}
      >
        <MoreVertIcon
          fontSize="inherit"
          sx={{
            width: '16px',
            height: '16px',
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={menuRef.current}
        onClick={(event) => event.stopPropagation()}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
        sx={{
          m: 1,
        }}
        data-testid="remover-aprovação-popover"
      >
        <Box display={'flex'} flexDirection={'row'} p={1} gap={2}>
          <Button variant={'text'} onClick={() => setOpenDialog(true)}>
            <Typography fontSize={'14px'} py={1} variant={'body2'}>
              Remover aprovação
            </Typography>
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
