import { MouseEvent, useEffect, useMemo, useState } from 'react';

import { IconChevronDown, IconReceipt2 } from '@tabler/icons-react';

import { Box, Button, Drawer, Tab, Tabs, Typography } from '@mui/material';

import {
  PayrollStatus,
  PayrollSummary,
  PayrollTypes,
  useGetLegalEntities,
} from '@octopus/api';
import { DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { PageContainer } from '../../modules/components/page/PageContainer';
import { PageTabs } from '../../modules/components/page/PageTabs';
import { PageTitle } from '../../modules/components/page/PageTitle';
import EnableMoreActionsPopover from '../../modules/components/payrolls/moreActionsPopover';
import { PayslipDetailsDrawer } from '../../modules/components/payrolls/PayslipDetails';
import { PeriodFormat } from '../../modules/types';

import { SubmissionToast } from './[period]/[type]/SubmissionToast';
import { RpaDetails } from './rpa/RpaDetails';
import { SelectPeriodDialog } from './rpa/RpaDialogs';
import { RpaTable, useFilters } from './rpa/RpaTable';

type LegalEntityEntry = { id: string; name: string };
const rpaGroupsConfig = {
  open: { color: 'primary', label: 'Abertos' },
  approved: {
    color: 'primary',
    label: 'Aprovados',
  },
  closed: { color: 'success', label: 'Fechados' },
};

export default function RPAPage({
  organizationId,
  companyId,
}: {
  organizationId: string | undefined;
  companyId: string | undefined;
}) {
  const RPA_TYPE = 'rpa';
  const [tab, setTab] = useState<keyof typeof rpaGroupsConfig>('open');
  const [countByStatus, setCountByStatus] = useState<{
    [key: string]: number;
  }>({
    open: 0,
    approved: 0,
    closed: 0,
    payslipApprovedNotSent: 0,
  });
  const [enableMoreActionsPopoverOpen, setEnableMoreActionsPopoverOpen] =
    useState(false);
  const [showSelectPeriodDialog, setShowSelectPeriodDialog] = useState(false);
  const [selectPeriodDialogMode, setSelectPeriodDialogMode] = useState<
    'create' | 'edit' | 'approve' | 'send'
  >('create');
  const [popoverAnchorEl, setPopOverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const moreActionsState: {
    enableMoreActionsPopoverOpen: boolean;
    setEnableMoreActionsPopoverOpen: (value: boolean) => void;
    popoverAnchorEl: HTMLButtonElement | null;
    setPopOverAnchorEl: (value: HTMLButtonElement | null) => void;
  } = {
    enableMoreActionsPopoverOpen,
    setEnableMoreActionsPopoverOpen,
    popoverAnchorEl,
    setPopOverAnchorEl,
  };

  const moreActions = [
    {
      label: 'Editar pagamentos em aberto',
      disabled: false,
      onClick: () => {
        setShowSelectPeriodDialog(true);
        setSelectPeriodDialogMode('edit');
      },
    },
    {
      label: 'Aprovar pagamentos em aberto',
      count: countByStatus.open,
      disabled: countByStatus.open === 0,
      onClick: () => {
        setShowSelectPeriodDialog(true);
        setSelectPeriodDialogMode('approve');
      },
    },
    {
      label: 'Enviar RPAs não enviados',
      count: countByStatus.payslipApprovedNotSent,
      disabled: countByStatus.payslipApprovedNotSent === 0,
      onClick: () => {
        setShowSelectPeriodDialog(true);
        setSelectPeriodDialogMode('send');
      },
    },
  ];
  const [legalEntities, setLegalEntities] = useState<LegalEntityEntry[]>([]);

  const filters = useFilters({ legalEntities });
  const dataGridProps = useDataGrid({ filters });

  const legalEntitiesQuery = useGetLegalEntities(
    {
      pathParams: {
        organizationId,
        companyId,
      },
    },
    {
      enabled: !!organizationId && !!companyId,
    },
  );

  useEffect(() => {
    if (!legalEntitiesQuery.isError && legalEntitiesQuery.data) {
      setLegalEntities(
        legalEntitiesQuery.data.data.map((entity) => ({
          id: entity.legalEntityId,
          name: entity.br.nomeFantasia,
        })),
      );
    }
  }, [legalEntitiesQuery.isError, legalEntitiesQuery.data]);

  if (!organizationId || !companyId) {
    return (
      <Typography variant="h1">
        Selecione uma organização e companhia
      </Typography>
    );
  }

  return (
    <PageContainer>
      <PageTitle
        dataTestId="autonomo-page-header"
        title="Pagamentos a autônomos"
        icon={IconReceipt2}
      />

      <DataGridToolbar
        filters={filters}
        searchProps={dataGridProps.searchProps}
        filteringProps={dataGridProps.filteringProps}
        searchPlaceholder="Procurar"
      >
        <Box
          sx={{
            alignContent: 'end',
            flex: 'end',
            display: 'flex',
            gap: 1,
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <Button
            color={'primaryAlt'}
            sx={{
              display: 'flex',
              width: 160,
              height: 40,
              p: 1,
              m: 0,
            }}
            onClick={() => {
              setSelectPeriodDialogMode('create');
              setShowSelectPeriodDialog(true);
            }}
          >
            <Typography variant={'body2'} color="secondary" fontWeight={400}>
              Criar Pagamentos
            </Typography>
          </Button>
          <Button
            color="secondary"
            sx={{
              display: 'flex',
              width: 130,
              height: 40,
              p: 1,
              m: 0,
              gap: 1,
            }}
            onClick={(event: MouseEvent<HTMLButtonElement>) => {
              moreActionsState.setPopOverAnchorEl(event.currentTarget);
              moreActionsState.setEnableMoreActionsPopoverOpen(true);
            }}
          >
            <Typography variant={'body2'} color="primaryAlt" fontWeight={400}>
              Mais ações
            </Typography>
            <IconChevronDown height={'20px'} width={'20px'} />
          </Button>
        </Box>
        <EnableMoreActionsPopover
          moreActionsProps={moreActionsState}
          actions={moreActions}
        />
        <SelectPeriodDialog
          open={showSelectPeriodDialog}
          setOpen={setShowSelectPeriodDialog}
          organizationId={organizationId}
          companyId={companyId}
          mode={selectPeriodDialogMode}
        />
      </DataGridToolbar>

      <PageTabs>
        <Tabs
          value={tab}
          onChange={(_, newTab) => setTab(newTab)}
          textColor="inherit"
          TabIndicatorProps={{
            sx: {
              backgroundColor: `${rpaGroupsConfig[tab].color}.main`,
            },
          }}
          data-testid="rpa-period-tabs"
        >
          {Object.entries(rpaGroupsConfig).map(([groupName, groupConfig]) => (
            <Tab
              key={groupName}
              value={groupName}
              icon={
                <PeriodTabLabel
                  isSelected={groupName === tab}
                  config={groupConfig}
                  count={
                    countByStatus[groupName as keyof typeof rpaGroupsConfig]
                  }
                />
              }
              data-testid={`rpas-tab-${groupName}`}
            />
          ))}
        </Tabs>
      </PageTabs>

      <Payrolls
        organizationId={organizationId}
        companyId={companyId}
        type={RPA_TYPE}
        selectedTab={tab}
        dataGridProps={dataGridProps}
        setCountByStatus={setCountByStatus}
      />
      <SubmissionToast successMessage="Pagamentos criados com sucesso" />
    </PageContainer>
  );
}

function Payrolls({
  organizationId,
  companyId,
  type,
  selectedTab,
  dataGridProps,
  setCountByStatus,
}: {
  organizationId: string;
  companyId: string;
  type: PayrollTypes;
  selectedTab: PayrollStatus;
  dataGridProps: ReturnType<typeof useDataGrid>;
  setCountByStatus: React.Dispatch<
    React.SetStateAction<{
      [key: string]: number;
    }>
  >;
}) {
  const [selectedPayrollId, setSelectedPayrollId] = useState<
    string | undefined
  >();
  const [selectedContractId, setSelectedContractId] = useState<
    string | undefined
  >();

  const [currentPayrollIndex, setCurrentPayrollIndex] = useState<
    number | undefined
  >();
  const [payrolls, setPayrolls] = useState<Array<PayrollSummary> | undefined>();

  const [compareTo, setCompareTo] = useState<PeriodFormat | undefined>(
    undefined,
  );
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);
  const [showPayslipDrawer, setShowPayslipDrawer] = useState(false);
  const closePayslipDrawer = () => setShowPayslipDrawer(false);

  const selectPayroll = (
    payrollId: string,
    contractId: string,
    rows: Array<PayrollSummary>,
    selectedTab: PayrollStatus,
  ) => {
    if (selectedTab === 'open') {
      setSelectedPayrollId(payrollId);
      setSelectedContractId(contractId);

      const currentIndex = rows.findIndex((row) => row.payrollId === payrollId);

      setCurrentPayrollIndex(currentIndex);
      setPayrolls(rows);

      setShowDrawer(true);
    } else {
      setSelectedPayrollId(payrollId);
      setSelectedContractId(contractId);
      const currentIndex = rows.findIndex((row) => row.payrollId === payrollId);
      setCurrentPayrollIndex(currentIndex);
      setShowPayslipDrawer(true);
    }
  };

  useEffect(() => {
    setCompareTo(undefined);
  }, [organizationId, companyId, type]);

  const workerNavigation = useMemo(
    () => ({
      canGoBackwards:
        payrolls != null &&
        currentPayrollIndex > 0 &&
        currentPayrollIndex <= payrolls.length - 1,
      canGoForward:
        payrolls != null &&
        currentPayrollIndex >= 0 &&
        currentPayrollIndex < payrolls.length - 1,
      goBackwards: () => {
        if (currentPayrollIndex <= 0) {
          return;
        }
        const newIndex = currentPayrollIndex - 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
      goForward: () => {
        if (currentPayrollIndex >= payrolls.length - 1) {
          return;
        }
        const newIndex = currentPayrollIndex + 1;
        const payroll = payrolls[newIndex];
        setCurrentPayrollIndex(newIndex);
        setSelectedContractId(payroll.contractId);
        setSelectedPayrollId(payroll.payrollId);
      },
    }),
    [payrolls, currentPayrollIndex],
  );

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <RpaTable
        organizationId={organizationId}
        companyId={companyId}
        type={type}
        compareTo={compareTo}
        onWorkerClick={selectPayroll}
        selectedStatus={selectedTab}
        dataGridProps={dataGridProps}
        setCountByStatus={setCountByStatus}
      />

      <Drawer
        anchor={'right'}
        open={showDrawer}
        onClose={closeDrawer}
        elevation={2}
      >
        {selectedPayrollId && selectedContractId && (
          <RpaDetails
            organizationId={organizationId}
            companyId={companyId}
            period={null}
            type={type}
            payrollId={selectedPayrollId}
            contractId={selectedContractId}
            compareToPeriod={compareTo}
            workerNavigation={workerNavigation}
            onExit={closeDrawer}
          />
        )}
      </Drawer>

      <PayslipDetailsDrawer
        showPayslipDrawer={showPayslipDrawer}
        closePayslipDrawer={closePayslipDrawer}
        organizationId={organizationId}
        companyId={companyId}
        payrollId={selectedPayrollId}
      />
    </Box>
  );
}

type PeriodTabLabelProps = {
  isSelected: boolean;
  config: (typeof rpaGroupsConfig)[keyof typeof rpaGroupsConfig];
  count: number;
};

function PeriodTabLabel({
  isSelected,
  config: { label, color },
  count,
}: PeriodTabLabelProps) {
  const fontWeight = isSelected ? 700 : 500;
  const textColor = isSelected ? `${color}.main` : 'text.secondary';
  const bgColor = isSelected ? `background.${color}` : 'background.default';
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
      <Typography color={textColor} variant="body1" fontWeight={fontWeight}>
        {label}
      </Typography>
      <Typography
        bgcolor={bgColor}
        color={textColor}
        py={0.25}
        px={1}
        borderRadius={2}
        variant="caption"
        fontWeight={fontWeight}
      >
        {count}
      </Typography>
    </Box>
  );
}
