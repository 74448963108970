import { ReactElement } from 'react';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

import { BouncingBackground } from '../../modules/components/BouncingBackground';
import { OrganizationAvatar } from '../../modules/components/OrganizationAvatar';

export type OrganizationInfoCardProps = {
  name: string;
  logo: string | undefined;
  children?: ReactElement;
};

export function OrganizationInfoCard({
  name,
  logo,
  children,
}: OrganizationInfoCardProps) {
  const theme = useTheme();
  return (
    <BouncingBackground color={theme.palette.text.secondary}>
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pt={10}
        gap={1}
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            pl: 4.25,
          },
          [theme.breakpoints.down('sm')]: {
            pl: 3.5,
          },
        })}
        data-testid="person-info-card"
      >
        <OrganizationAvatar
          name={name}
          logo={logo}
          size="100px"
          AvatarTextProps={{ fontSize: 40 }}
        />
        <Typography variant="h2" fontWeight="700" mt={3} mb={0.5}>
          {name}
        </Typography>
        {children}
      </Box>
    </BouncingBackground>
  );
}

export function OrganizationInfoCardSkeleton() {
  return (
    <BouncingBackground color="#808080">
      <Box
        display="flex"
        flexDirection="column"
        pr={5}
        pl={4.25}
        pt={10}
        gap={1}
      >
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: '0%',
            minHeight: '100px',
            width: '100px',
            border: '1.5px solid #FFFFFF',
            boxSizing: 'border-box',
          }}
        />
        <Skeleton
          variant="rounded"
          width="100%"
          height="36px"
          sx={{
            mt: 3,
          }}
        />
        <Skeleton variant="rounded" width="100%" height="36px" />
      </Box>
    </BouncingBackground>
  );
}
