import React, { useState } from 'react';

import { IconArchive, IconFolderUp } from '@tabler/icons-react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import {
  CompanyList,
  JobTitleEntry,
  JobTitleInput,
  useActivateJobTitleEntry,
  useDeactivateJobTitleEntry,
  usePutJobTitleEntry,
} from '@octopus/api';
import { AppError } from '@octopus/i18n';

import { mapApiErrors, translateAPIErrors } from '../../../../utils';
import { JobTitleForm, sanitizeJobTitleInput } from '../JobTitleForm';

export type JobTitleEditProps = {
  jobTitle: JobTitleEntry;
  companies: CompanyList | undefined;
  onEdit: (updatedEntry: JobTitleEntry) => void;
  onArchive: (jobTitleId: string) => void;
  onRestore: (jobTitleId: string) => void;
  stopEditing: () => void;
};

export function JobTitleEdit({
  jobTitle,
  companies,
  onEdit,
  onArchive,
  onRestore,
  stopEditing,
}: JobTitleEditProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  return (
    <Box>
      <Edit
        jobTitle={jobTitle}
        companies={companies}
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        onEdit={onEdit}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Archive
        jobTitle={jobTitle}
        showModal={showArchiveModal}
        setShowModal={setShowArchiveModal}
        onArchive={() => {
          stopEditing();
          onArchive(jobTitle.jobTitleId);
        }}
      />
      <Restore
        jobTitle={jobTitle}
        showModal={showRestoreModal}
        setShowModal={setShowRestoreModal}
        onRestore={() => {
          stopEditing();
          onRestore(jobTitle.jobTitleId);
        }}
      />
      <Box
        py={1}
        px={3}
        gap={3}
        boxSizing="border-box"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={(theme) => ({
          background: 'rgba(247, 247, 247, 0.90)',
          borderTop: `1px solid ${theme.palette.strokes.light}`,
          backdropFilter: 'blur(4px)',
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: '700px',
        })}
      >
        {jobTitle.active ? (
          <IconButton
            onClick={() => setShowArchiveModal(true)}
            sx={{
              borderRadius: '8px',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer' }}
            >
              <IconArchive className="error main" size={20} />
              <Typography color="error.main" variant="body2" fontWeight="600">
                Desativar
              </Typography>
            </Box>
          </IconButton>
        ) : (
          <IconButton
            onClick={() => setShowRestoreModal(true)}
            sx={{
              borderRadius: '8px',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{ cursor: 'pointer' }}
            >
              <IconFolderUp className="success main" size={20} />
              <Typography color="success.main" variant="body2" fontWeight="600">
                Reativar
              </Typography>
            </Box>
          </IconButton>
        )}
        <Box display="flex" gap={1}>
          <Button
            color="secondary"
            size="large"
            sx={{ width: '120px' }}
            onClick={stopEditing}
          >
            Cancelar
          </Button>
          <LoadingButton
            color="primaryAlt"
            size="large"
            variant="contained"
            sx={{ width: '120px' }}
            loading={isLoading}
            onClick={() => setShowEditModal(true)}
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

function Edit({
  jobTitle,
  companies,
  showModal,
  setShowModal,
  onEdit,
  isLoading,
  setIsLoading,
}: {
  jobTitle: JobTitleEntry;
  companies: CompanyList | undefined;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onEdit: (updatedJobTitle: JobTitleEntry) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}) {
  const [failure, setFailure] = React.useState<string | undefined>(undefined);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [form, setForm] = useState<JobTitleInput>({
    code: jobTitle.code,
    name: jobTitle.name,
    contractTypes:
      jobTitle.contractTypes ??
      (jobTitle.occupationCode !== undefined ? ['br:clt', 'br:pj'] : ['br:pj']),
    enabledForCompanies:
      jobTitle.enabledForCompanies ??
      companies?.data?.map(({ companyId }) => companyId) ??
      [],
    occupationCode: jobTitle.occupationCode,
    description: jobTitle.description,
    br: {
      cargoDeConfianca: jobTitle.br?.cargoDeConfianca ?? false,
      cboDoCargoDeConfianca: jobTitle.br?.cboDoCargoDeConfianca,
      descricaoDoCargoDeConfianca: jobTitle.br?.descricaoDoCargoDeConfianca,
    },
  });

  const { mutate } = usePutJobTitleEntry();

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setShowModal(false);
    setFailure(undefined);
    setErrors({});
    mutate(
      {
        pathParams: {
          organizationId: jobTitle.organizationId,
          jobTitleId: jobTitle.jobTitleId,
        },
        body: {
          ...sanitizeJobTitleInput(form, companies),
          version: jobTitle.version,
        },
      },
      {
        onSuccess: onEdit,
        onError: (err: unknown) => {
          setIsLoading(false);
          const error = err as AppError;
          if (error.statusCode === 409) {
            setErrors({
              name: 'Já existe um cargo com este nome.',
            });
          } else if (error.statusCode === 412) {
            setFailure(translateAPIErrors(error as unknown as AppError)[0]);
          } else if (error.statusCode === 400) {
            setErrors(mapApiErrors(error as unknown as AppError));
          } else {
            setFailure(
              'Falha ao atualizar cargo, por favor tente novamente mais tarde.\n' +
                'Se o problema persistir, entre em contato com o suporte da Tako.',
            );
          }
        },
      },
    );
  };

  return (
    <>
      <JobTitleForm
        companies={companies}
        form={form}
        setForm={setForm}
        failure={failure}
        errors={errors}
        clearError={(key) => {
          setErrors((current) => {
            const { [key]: _, ...rest } = current;
            return rest;
          });
        }}
        disabled={isLoading}
      />
      <EditChangeConfirmationModal
        form={form}
        open={showModal}
        onConfirm={handleSubmit}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
}

function EditChangeConfirmationModal({
  form,
  open,
  onConfirm,
  onCancel,
}: {
  form: JobTitleInput;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <Dialog open={open} onClose={onCancel} fullWidth>
      <DialogTitle>
        <Typography variant="h5" fontWeight={700}>
          Alterações de dados de cargo
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 2, pt: 1 }}>
          <Typography variant="body1" fontWeight="600">
            Ao confirmar a alteração, o que acontece em seguida:
          </Typography>
        </Box>
        <List
          sx={{
            py: 0,
            px: 2,
            listStyleType: 'disc',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {form.contractTypes.includes('br:clt') ? (
            <>
              <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
                <Box>
                  <Typography variant="body1">Envios para o eSocial</Typography>
                  <Typography variant="caption">
                    As alterações serão efetivadas no sistema e depois enviadas
                    para o eSocial.
                  </Typography>
                </Box>
              </ListItem>
              <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
                <Box>
                  <Typography variant="body1">
                    Aplicação das alterações
                  </Typography>
                  <Typography variant="caption">
                    A alteraçào do cargo afeta todos os colaboradores vinculados
                    a ele.
                  </Typography>
                </Box>
              </ListItem>
            </>
          ) : (
            <ListItem disableGutters sx={{ py: 0, display: 'list-item' }}>
              <Box>
                <Typography variant="body1">Impacto em relatórios</Typography>
                <Typography variant="caption">
                  Essas alterações impactam como os relatórios serão exibidos.
                </Typography>
              </Box>
            </ListItem>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancelar
        </Button>
        <Button color="primaryAlt" size="large" onClick={onConfirm}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Archive({
  jobTitle,
  showModal,
  setShowModal,
  onArchive,
}: {
  jobTitle: JobTitleEntry;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onArchive: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useDeactivateJobTitleEntry();

  const onConfirm = () => {
    setIsLoading(true);
    mutate(
      {
        pathParams: {
          organizationId: jobTitle.organizationId,
          jobTitleId: jobTitle.jobTitleId,
        },
        body: {
          version: jobTitle.version,
        },
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          setShowModal(false);
          onArchive();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <ActivationChangeConfirmationModal
      title="Desativar cargo"
      message={
        'O cargo listado abaixo será desativado. Você pode encontrá-lo na ' +
        'tela de Cargos, em "Desativados".'
      }
      name={jobTitle.name}
      open={showModal}
      isLoading={isLoading}
      onConfirm={onConfirm}
      onCancel={() => setShowModal(false)}
    />
  );
}

function Restore({
  jobTitle,
  showModal,
  setShowModal,
  onRestore,
}: {
  jobTitle: JobTitleEntry;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  onRestore: () => void;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = useActivateJobTitleEntry();

  const onConfirm = () => {
    setIsLoading(true);
    mutate(
      {
        pathParams: {
          organizationId: jobTitle.organizationId,
          jobTitleId: jobTitle.jobTitleId,
        },
        body: {
          version: jobTitle.version,
        },
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          setShowModal(false);
          onRestore();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <ActivationChangeConfirmationModal
      title="Reativar cargo"
      message={
        'O cargo listado abaixo será reativado. Você pode encontrá-lo na ' +
        'tela de Cargos, em "Ativos".'
      }
      name={jobTitle.name}
      open={showModal}
      isLoading={isLoading}
      onConfirm={onConfirm}
      onCancel={() => setShowModal(false)}
    />
  );
}

function ActivationChangeConfirmationModal({
  title,
  message,
  name,
  open,
  isLoading,
  onConfirm,
  onCancel,
}: {
  title: string;
  message: string;
  name: string;
  open: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 3 }}>
          <Typography variant="body2">{message}</Typography>
        </Box>
        <Box display="flex" gap={1.5}>
          <Typography variant="body2" color="text.secondary">
            Cargo
          </Typography>
          <Typography variant="body2">{name}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" size="large" onClick={onCancel}>
          Cancelar
        </Button>
        <LoadingButton
          color="primaryAlt"
          size="large"
          onClick={onConfirm}
          loading={isLoading}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
