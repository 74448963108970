import { cpf } from 'cpf-cnpj-validator';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
  BREstados,
  BRPaises,
  BRTipoLogradouro,
  ContractBRCltAddress,
  ContractBRCltGrauInstrucao,
  ContractBRCltRacaCor,
  ContractBRCltSexo,
  ContractBRCltTipoDependente,
} from '@octopus/api';
import { Paises, getEstadoByMunicipioCode } from '@octopus/esocial/mapper';
import {
  formatCEP,
  formatCPF,
  formatPhoneBR,
  getOnlyDigits,
} from '@octopus/formatters';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  Dependent,
  extractParentescoType,
} from '../../../dependentes/form/types';
import { CustomFieldsHelper } from '../../formStepInputs/customFields';
import { AdmissionFormState } from '../../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  const pessoa = admissionDraftEntry.person?.br?.pessoa;
  const nascimento = admissionDraftEntry.person?.br?.nascimento;
  const contato = admissionDraftEntry.person?.br?.contato;
  const dependentes = admissionDraftEntry.person?.br?.dependentes;
  const endereco = admissionDraftEntry.person?.br
    ?.endereco as ContractBRCltAddress;

  const deficiencia = admissionDraftEntry.person?.br?.deficiencia;
  const deficiencias = [];
  if (deficiencia) {
    if (deficiencia.defIntelectual) {
      deficiencias.push('defIntelectual');
    }
    if (deficiencia.defAuditiva) {
      deficiencias.push('defAuditiva');
    }
    if (deficiencia.defFisica) {
      deficiencias.push('defFisica');
    }
    if (deficiencia.defMental) {
      deficiencias.push('defMental');
    }
    if (deficiencia.defVisual) {
      deficiencias.push('defVisual');
    }
  }

  return {
    nome: pessoa?.nmTrab || admissionDraftEntry.userData?.name,
    nomeMae: pessoa?.nmMae,
    nomeSocial: pessoa?.nmSoc,
    cpf: formatCPF(pessoa?.cpfTrab),
    sexo: pessoa?.sexo,
    racaCor: pessoa?.racaCor,
    grauInstr: pessoa?.grauInstr,
    estadoCivil: pessoa?.estCiv,
    dtNascto: nascimento?.dtNascto,
    nacionalidade: nascimento?.paisNac,
    paisNascto: nascimento?.paisNascto,
    tpLograd: endereco?.tpLograd,
    dscLograd: endereco?.dscLograd,
    nrLograd: endereco?.nrLograd,
    complemento: endereco?.complemento,
    bairro: endereco?.bairro,
    cep: formatCEP(endereco?.cep),
    uf: {
      defaultValue: endereco?.uf,
      defaultCodMunic: endereco?.codMunic,
    },
    countryCode: Paises.findByName('Brasil'),
    fonePrinc: formatPhoneBR(contato?.fonePrinc),

    pessoaComDeficiencia: {
      descDeficiencia: deficiencia?.observacao,
      deficiencias: deficiencias.length === 0 ? null : deficiencias,
    },

    dependentes: dependentes?.map((dep) => {
      const calculos = [];
      if (dep.depIRRF) {
        calculos.push('calcIRRF');
      }
      if (dep.depSF) {
        calculos.push('calcSF');
      }

      return {
        id: dep.id,
        nome: dep.nmDep,
        parentesco: {
          type: dep.tpDep,
          descrDep: dep.descrDep,
        },
        descrDep: dep.descrDep,
        dtNascto: dep.dtNascto,
        cpf: formatCPF(dep.cpfDep),
        sexo: dep.sexoDep,
        incTrab: `${dep.incTrab}`,
        calculos,
      };
    }),

    ...CustomFieldsHelper.entryToFormState(admissionDraftEntry),
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
  body,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
  body: AdmissionDraftUpdateInput;
}): AdmissionDraftUpdateInput => {
  switch (currentStepName) {
    case admissionDraftFormSteps.dados_pessoais: {
      const nmSoc = formState.hasNomeSocial?.nomeSocial;
      const nmSocHidden = formState.hasNomeSocial?.nomeSocialHidden;

      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(nmSoc && { nmSoc }),
            ...(nmSocHidden && { nmSoc: null }),
            ...(formState.nomeMae && { nmMae: formState.nomeMae }),
            cpfTrab: cpf.strip(formState.cpf),
            sexo: formState.sexo as ContractBRCltSexo,
            racaCor: Number(formState.racaCor) as ContractBRCltRacaCor,
            grauInstr: Number(
              formState.grauInstr ?? '1',
            ) as ContractBRCltGrauInstrucao,
            estCiv: Number(formState.estadoCivil) as ContractBRCltRacaCor,
          },
          nascimento: {
            dtNascto: formState.dtNascto,
            paisNac: Number(formState.nacionalidade) as BRPaises,
            paisNascto: Number(formState.paisNascto) as BRPaises,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.formacao_academica: {
      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(formState.nomeSocial && { nmSoc: formState.nomeSocial }),
            cpfTrab: cpf.strip(formState.cpf),
            sexo: formState.sexo as ContractBRCltSexo,
            racaCor: Number(formState.racaCor) as ContractBRCltRacaCor,
            grauInstr: Number(
              formState.grauInstr,
            ) as ContractBRCltGrauInstrucao,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.endereco_e_contatos: {
      const codMunic = formState.uf.codMunic;
      const uf = getEstadoByMunicipioCode(codMunic);

      body.person = {
        countryCode: 'BR',
        br: {
          endereco: {
            tipo: 'brasil',
            tpLograd: formState.tpLograd as BRTipoLogradouro,
            dscLograd: formState.dscLograd,
            nrLograd: formState.nrLograd,
            ...(formState.complemento && {
              complemento: formState.complemento,
            }),
            bairro: formState.bairro,
            cep: getOnlyDigits(formState.cep?.toString()),
            codMunic: Number(codMunic),
            uf: uf as BREstados,
          },
          contato: {
            ...(formState.fonePrinc && {
              fonePrinc: getOnlyDigits(formState.fonePrinc.toString()),
            }),
            emailPrinc: formState.user_email,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.deficiencias: {
      const { deficiencias, deficiencia_hidden } =
        formState.pessoaComDeficiencia ?? {};
      let deficienciasSet = new Set();
      if (!deficiencia_hidden && deficiencias != null) {
        deficienciasSet = new Set(
          Array.isArray(deficiencias) ? deficiencias : [deficiencias],
        );
      }

      body.person = {
        countryCode: 'BR',
        br: {
          deficiencia: deficiencia_hidden
            ? {
                defFisica: false,
                defVisual: false,
                defAuditiva: false,
                defMental: false,
                defIntelectual: false,
                reabReadap: false,
              }
            : {
                observacao: formState.pessoaComDeficiencia?.descDeficiencia,

                defFisica: deficienciasSet.has('defFisica'),
                defVisual: deficienciasSet.has('defVisual'),
                defAuditiva: deficienciasSet.has('defAuditiva'),
                defMental: deficienciasSet.has('defMental'),
                defIntelectual: deficienciasSet.has('defIntelectual'),
                reabReadap: false,
              },
        },
      };
      break;
    }
    case admissionDraftFormSteps.dependentes: {
      body.person = {
        countryCode: 'BR',
        br: {
          dependentes: formState.dependentes?.map((dependent: Dependent) => {
            const parentesco = extractParentescoType(dependent);

            return {
              nmDep: dependent.nome,
              tpDep: Number(parentesco) as ContractBRCltTipoDependente,
              dtNascto: dependent.dtNascto,
              cpfDep: cpf.strip(dependent.cpf),
              sexoDep: dependent.sexo as ContractBRCltSexo,
              incTrab: dependent.incTrab === 'true',
              depIRRF: dependent.calculos?.indexOf('calcIRRF') >= 0,
              depSF: dependent.calculos?.indexOf('calcSF') >= 0,
              descrDep: dependent.parentesco?.descrDep,
              id: dependent.id,
            };
          }),
        },
      };
      break;
    }
    case admissionDraftFormSteps.custom_fields_worker: {
      const customFields = CustomFieldsHelper.formStateToEntry(formState);
      body.customFields = customFields;
      break;
    }
    default:
      return null;
  }

  return body;
};

export const common = { getUpdateInputFromFormState, getFormStateFromEntry };
